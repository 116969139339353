import React, { useState, useEffect } from "react";
import { Row, Col, Card, Typography, Tabs, Button, Spin, Table, notification, Modal, Input } from "antd";
import { useHistory, useLocation,useParams } from "react-router-dom";
import { AdminService } from "../../IssuerSuperAdmin/Admin/Admin.service";
import { SharedService } from "../Shared.service";
import { User } from "../interfaces";
import { IssuerSuperAdminService } from "../../IssuerSuperAdmin/IssuerSuperAdmin.service";
import { AuthService } from "../Auth.service";
import moment from "moment";
import { PaymentInfoService } from "./PaymentInfoRequests.service";
import { PaymentHandlerService } from "../../IssuerSuperAdmin/ManagePaymentMethod/PaymentHandlers/PaymentHandlerService";
import { MetamaskService } from "../Metamask.service";
import EscrowFacet from "../SecurityToken/Facets/EscrowFacet";
import TxFeeDelegationModal from "../TxFeeDelegationModal";

export type PaymentType = 'USBankDetail' | 'escrowCrypto' | 'mailACheck'
const { Title } = Typography;
const { TabPane } = Tabs;

const authService = new AuthService();
const issuerSuperAdminService = new IssuerSuperAdminService();
const useUserContext = () => authService.useUserContext();
const paymentService = new PaymentInfoService();
const escrowFacet = new EscrowFacet();

const useSelectedWalletContext = () => new MetamaskService().useSelectedWalletContext();


const PaymentInfoRequests = () => {
    
    const { userInfo } = useUserContext();
    const history = useHistory();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { selectedWallet, networkId } = useSelectedWalletContext();
    const [loading, setLoading] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [dataSource, setDataSource] = useState<any>([]);
    const [paymentInfoDocs, setPaymentInfoDocs] = useState<any>([]);
    const [rejectReason, setRejectReason] = useState("");
    const [showRejectPopup, setShowRejectPopup] = useState(false);
    const [selectedPaymentRecord, setSelectedPaymentRecord] = useState<any>();
    const [repaint, setRepaint] = useState(false);
    const location = useLocation();
    const {paymentType} = useParams<{paymentType:string}>();
    const [isDelegationModalVisible, setIsDelegationModalVisible] = useState(false);
    const [selectedData, setSelectedData] = useState(null);

    const paymentTypeMap = {
        'crypto-escrow': 'escrowCrypto', 
        'mail-a-cheque':'mailACheck',
        'us-bank': 'USBankDetail'
    }
   
    const paymentHandlerService = new PaymentHandlerService(paymentType,paymentService)
    const paymentTypeKey = paymentHandlerService.getMetaData()?.key

    const statusByRoles = {
        issuercomplianceadmin: 'llpCA',
        mastercompanycomplianceadmin: 'mca',
        platformissueradmin:'psa'
    }

    useEffect(()=>{
        if(!paymentType || paymentType === undefined){
            window.history.back()
            return
        }
       },[])
       
    useEffect(() => {
        (async () => {
            setShowDetails(false);
            setDataSource([])
            setLoading(true);
            const bankData: any = []
            let paymentInfo 
            if(userInfo?.role == "master company compliance admin" || userInfo?.role == "platform issuer admin"){
                paymentInfo = await paymentHandlerService.getPaymentDetailsByPaymentTypeForMCAOrPSA(paymentTypeKey)
                if (paymentInfo.success ) {
                    if(userInfo?.role == "master company compliance admin"){
                        const BankDataRes = paymentInfo.data?.filter(data => ['pending','approved','rejected'].includes(data.mca) && data.llpCA == 'approved')
                        setDataSource(BankDataRes?.sort((x,y) => x.creationTS ))
                    }else{
                        const BankDataRes = paymentInfo.data?.filter(data => ['pending','approved','rejected'].includes(data.psa) && data.mca =='approved')
                        setDataSource(BankDataRes?.sort((x,y) => x.creationTS ))
                    }
                }
            }else{
                paymentInfo= await paymentHandlerService.getPaymentDetailsByPaymentTypeAndCompanyId(userInfo?.company?.id,paymentTypeKey)
                bankData.push(paymentInfo.data)
                if (paymentInfo.success && paymentInfo.data?._id) {
                    setDataSource(bankData)
                }
            }
            // console.log('info ', paymentInfo)

            setLoading(false);

        })();
    }, [repaint,paymentType]);

    const isAllApproved = (record) => {
        return record.llpCA === "approved" && record.mca === "approved" && record.psa === "approved";
    };

    const columns = [
        ...(userInfo?.role === "master company compliance admin" || userInfo?.role === "platform issuer admin" ? [{
            title: "Company Name",
            key: "companyName",
            dataIndex: "companyDetails",
            render: (companyDetails) => companyDetails?.name || 'N/A'
        }] : []),
        ...(paymentType ? paymentHandlerService.getMetaData()?.columns.map(col => ({title:col.label,key:col.name,dataIndex:col.name}))
        .filter((col:any) => paymentHandlerService.getMetaData()?.visibleCols.includes(col.key)) : []),
        {
            title: "Action",
            key: "action",
            render: (_, record, index) => {
                return (
                    <div style={{ display: 'flex', gap: '10px' }}>
                    <Button type="link" onClick={() => paymentInfoDetails(record?._id)}>Details</Button>
                    {userInfo?.role === "issuer compliance admin" && isAllApproved(record) && (
                        <Button type="link" onClick={() => showAddPriceFeedsModal(record)}>Add Price Feeds</Button>
                    )}
                </div>
                );
            },
        },
    ];

    const paymentInfoDetails = (id: number) => {
        var selectedRecord = dataSource.find(x => x._id === id);
        if (selectedRecord) {
          setPaymentInfoDocs(selectedRecord.escrowDocs);
          setSelectedPaymentRecord(selectedRecord)
          setShowDetails(true);
        }
    
    }

    const approveOrReject = async(status) =>{
        try {
          // console.log(data)
          let payload = {status,rejectionMessage:''}
          if(status == 'rejected'){

            if(rejectReason == ''){
                notification.info({message: 'Info', description: 'Provide valid rejection message'});
                return;
              }

            payload.rejectionMessage = rejectReason;
            setShowRejectPopup(false)
          }
          console.log(payload)
          const role = userInfo?.role.replaceAll(' ','');
          
          let companyId = userInfo?.company?.id;
          //incase of these below roles use the companyId of the previous api 
          if(userInfo?.role == 'master company compliance admin' || userInfo?.role == 'platform issuer admin'){
            companyId = selectedPaymentRecord?.companyId;
          }

          const res = await paymentHandlerService.approveOrRejectPaymentInfo(companyId,payload,role,paymentTypeKey)
          console.log(res)
          if(res.success){
            notification.success({message: 'Success', description: `Payment Info ${status} successfully`});
            setRepaint(prev => !prev)
            setShowDetails(false)
          }else{
            notification.error({message: 'Error', description: res.error.message});
          }

        } catch (error) {
          console.log(error);
          notification.error({message:'error', description:error})
        }
      }

      const showAddPriceFeedsModal = (record) => {
        setSelectedData(record);
        setIsDelegationModalVisible(true);
    };

    const addPriceFeedS = async (record,delegate) => {
        const tokenAddress = record.walletAddress;
        const owner = selectedWallet || ''; // connected meta
        const depositTokens = record.cryptoType.map(token => token.tokenAddress);
        const aggregators = record.cryptoType.map(token => token.aggregatorAddress);
        try {
            console.log('Transaction Response:');
          const response = await escrowFacet.addPriceFeed(tokenAddress, owner, depositTokens, aggregators,{delegate: delegate});
          console.log('Transaction Response:', response);
          notification.success({
            message: 'Success',
            description: 'Price feeds added successfully.',
          });
        } catch (error) {
          notification.error({
            message: 'Error',
            description: 'Failed to add price feeds. Please try again.',
          });
        }
      };


    const handleDelegationModalSubmit = async ({ delegate }) => {
        console.log(delegate,"delegate");
        console.log(selectedData,"selectedData");
        setIsDelegationModalVisible(false);
        await addPriceFeedS(selectedData,delegate);
    };

    return (
        <>
            <br />
            <br />
            <Row justify="center">
                <Col span={23}>
                    {loading && (
                        <div style={{ textAlign: "center" }}>
                            <Spin size="large" />
                        </div>
                    )}
                    {!loading && !showDetails && (
                        <Card>
                            <Title level={1} style={{ textAlign: "center" }}>
                                {paymentHandlerService.getMetaData()?.title}
                            </Title>
                            <Row>
                                <Col
                                    span={3}
                                    offset={21}
                                    style={{ display: "flex", justifyContent: "end" }}
                                ></Col>
                            </Row>
                            <Table dataSource={dataSource} columns={columns} />
                        </Card>
                    )}

                    {showDetails && <Card title={paymentHandlerService.getMetaData()?.title + ' Information'}>

                        <div className="details">
                            <div className="container mt-5">
                                {   paymentHandlerService.getMetaData()?.columns.map(col =>
                                    (<div className="row mb-2" key={col.name}>
                                        <div className="col-sm-4 text-right font-weight-bold">{col.label}:</div>
                                        <div className="col-sm-8">{selectedPaymentRecord[col.name]}</div>
                                        
                                    </div>)
                                )
                                }
                        {selectedPaymentRecord?.cryptoType && (
                            <div className="row mb-2">
                            <div className="col-sm-4 text-right font-weight-bold">Crypto Types:</div>
                            <div className="col-sm-8">
                                <strong>
                                    {selectedPaymentRecord?.cryptoType 
                                        ? selectedPaymentRecord?.cryptoType.map(crypto => crypto?.tokenName).join(", ")
                                        : "N/A"}
                                </strong>
                            </div>
                        </div>
                        )}
                            </div>
                        </div>

                        <div className="controls" style={{display:'flex', justifyContent:'space-between',marginTop:'50px'}}>
                            <Button htmlType='submit' size='large' type='primary' onClick={()=> setShowDetails(false)} disabled={false} loading={loading}>
                                Back
                            </Button>
                            
                            { userInfo && selectedPaymentRecord[statusByRoles[userInfo?.role.replaceAll(' ','')]] === 'pending' &&<div className="controls-action">
                                <Button htmlType='submit' style={{marginRight:'10px'}} onClick={()=>approveOrReject('approved')} size='large' type='primary' disabled={false} loading={loading}>
                                    Approve
                                </Button>
                                <Button htmlType='submit' size='large' type='primary' onClick={()=>{setShowRejectPopup(true);}} disabled={false} loading={loading}>
                                    Reject
                                </Button>
                            </div>}
                        </div>
                    </Card>}
                </Col>
            </Row>


            <Modal
                title="Reject Bank Info"
                visible={showRejectPopup}
                onCancel={() => setShowRejectPopup(false)}
                footer={[
                    <Button key="back" onClick={() => { setShowRejectPopup(false); }}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={() => approveOrReject('rejected')}>
                        Submit
                    </Button>,
                ]}
            >
                <Input
                    placeholder="Reason for rejection"
                    value={rejectReason}
                    onChange={(e) => setRejectReason(e.target.value)}
                />
            </Modal>

            <TxFeeDelegationModal
                isVisible={isDelegationModalVisible}
                onSubmit={({delegate}) => {
                    setIsDelegationModalVisible(false);
                    handleDelegationModalSubmit({delegate}).then()
                  }}
                onCancel={() => setIsDelegationModalVisible(false)}
      />
        </>
    );
};

export default PaymentInfoRequests;
