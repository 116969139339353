import React, { useEffect, useState } from 'react';
import { AuthService } from '../Shared/Auth.service';
import {
  Steps,
} from 'antd';
import {useHistory} from 'react-router-dom';
const stepsContent = {
  minHeight: "200px",
  marginTop: "16px",
  paddingTop: "80px",
  textAlign: 'center',
  backgroundColor: "#fafafa",
  border: "1px dashed #e9e9e9",
  borderRradius: "2px"
}

const { Step } = Steps;
const authService = new AuthService();
const useUserContext = () => authService.useUserContext()

export default ({selectedTokenSymbol}) => {
  const { userInfo, setUserInfo } = useUserContext();
  const [current, setCurrent] = useState(0);
  const history =useHistory();
  const [isBasicInfo, setBasicInfo] = useState(userInfo?.status?.emailVerified);
  const [isTAndC, setTAndC] = useState(selectedTokenSymbol?userInfo?.tokens[selectedTokenSymbol]?.tAndCSigned : false);
  const [isKYC, setKYC] = useState(selectedTokenSymbol?userInfo?.tokens[selectedTokenSymbol]?.KYCSubmittedCompany : false);
  const [isAccreditation, setAccreditation] = useState(selectedTokenSymbol?userInfo?.tokens[selectedTokenSymbol]?.aiSubmittedCompany : false);
  const [isOfferingDoc, setOfferingDocs] = useState(selectedTokenSymbol?userInfo?.tokens[selectedTokenSymbol]?.offeringDocsSigned : false);
  const [isPayment, setPayments] = useState(false);
  const [isConfirmation, setConfirmation] = useState(false);

  const [basicInfoTitle, setBasicInfoTitle] = useState("In Progress");
  const [tAndCTitle, setTAndCTitle] = useState("Waiting");
  const [kYCTitle, setKYCTitle] = useState("Waiting");
  const [accreditationTitle, setAccreditationTitle] = useState("Waiting");
  const [offeringDocTitle, setOfferingDocsTitle] = useState("Waiting");
  const [paymentTitle, setPaymentsTitle] = useState("Waiting");
  const [confirmationTitle, setConfirmationTitle] = useState("Waiting");

  useEffect(() => {
    (async () => {
      if (isBasicInfo) {
        setBasicInfoTitle('Completed');
        setTAndCTitle('In Progress');
        setKYCTitle('Waiting');
        setAccreditationTitle('Waiting');
        setOfferingDocsTitle('Waiting');
        setPaymentsTitle('Waiting');
        setConfirmationTitle('Waiting');
        setCurrent(1);
        if (isTAndC) {
          setBasicInfoTitle('Completed');
          setTAndCTitle('Completed');
          setAccreditationTitle('In Progress');
          setKYCTitle('Waiting');
          setOfferingDocsTitle('Waiting');
          setPaymentsTitle('Waiting');
          setConfirmationTitle('Waiting');
          setCurrent(2);
          if (isAccreditation?.length > 0 || userInfo.country.toLowerCase()!=="united states of america") {
            setBasicInfoTitle('Completed');
            setTAndCTitle('Completed');
            setAccreditationTitle('Completed');
            setKYCTitle('In Progress');
            setOfferingDocsTitle('Waiting');
            setPaymentsTitle('Waiting');
            setConfirmationTitle('Waiting');
            setCurrent(3);
            if (isKYC?.length > 0) {
              setBasicInfoTitle('Completed');
              setTAndCTitle('Completed');
              setAccreditationTitle('Completed');
              setKYCTitle('Completed');
              setOfferingDocsTitle('In Progress');
              setPaymentsTitle('Waiting');
              setConfirmationTitle('Waiting');
              setCurrent(4);
              if (isOfferingDoc) {
                setBasicInfoTitle('Completed');
                setTAndCTitle('Completed');
                setKYCTitle('Completed');
                setAccreditationTitle('Completed');
                setOfferingDocsTitle('Completed');
                setPaymentsTitle('Waiting');
                setConfirmationTitle('Waiting');
                setCurrent(4);
                /*
                if (isPayment) {
                  setBasicInfoTitle('Completed');
                  setTAndCTitle('Completed');
                  setKYCTitle('Completed');
                  setAccreditationTitle('Completed');
                  setOfferingDocsTitle('Completed');
                  setPaymentsTitle('Completed');
                  setConfirmationTitle('In Progress');
                  setCurrent(6);
                  if (isConfirmation) {
                    setBasicInfoTitle('Completed');
                    setTAndCTitle('Completed');
                    setKYCTitle('Completed');
                    setAccreditationTitle('Completed');
                    setOfferingDocsTitle('Completed');
                    setPaymentsTitle('Completed');
                    setConfirmationTitle('Completed');
                    setCurrent(6);
                  } else {
                    setBasicInfoTitle('Completed');
                    setTAndCTitle('Completed');
                    setKYCTitle('Completed');
                    setAccreditationTitle('Completed');
                    setOfferingDocsTitle('Completed');
                    setPaymentsTitle('In Progress');
                    setConfirmationTitle('Waiting');
                    setCurrent(5);
                  }
                } else {
                  setBasicInfoTitle('Completed');
                  setTAndCTitle('Completed');
                  setKYCTitle('Completed');
                  setAccreditationTitle('Completed');
                  setOfferingDocsTitle('Completed');
                  setPaymentsTitle('In Progress');
                  setConfirmationTitle('Waiting');
                  setCurrent(5);
                }
                */
              } else {
                setBasicInfoTitle('Completed');
                setTAndCTitle('Completed');
                setKYCTitle('Completed');
                setAccreditationTitle('Completed');
                setOfferingDocsTitle('In Progress');
                setPaymentsTitle('Waiting');
                setConfirmationTitle('Waiting');
                setCurrent(4);
              }
            } else {
              setBasicInfoTitle('Completed');
              setTAndCTitle('Completed');
              setAccreditationTitle('Confirm');
              setKYCTitle('In Progress');
              setOfferingDocsTitle('Waiting');
              setPaymentsTitle('Waiting');
              setConfirmationTitle('Waiting');
              setCurrent(3);
            }
          } else {
            setBasicInfoTitle('Completed');
            setTAndCTitle('Completed');
            setAccreditationTitle('In Progress');
            setKYCTitle('Waiting');
            setOfferingDocsTitle('Waiting');
            setPaymentsTitle('Waiting');
            setConfirmationTitle('Waiting');
            setCurrent(2);
          }
        } else {
          setBasicInfoTitle('Completed');
          setTAndCTitle('In Progress');
          setKYCTitle('Waiting');
          setAccreditationTitle('Waiting');
          setOfferingDocsTitle('Waiting');
          setPaymentsTitle('Waiting');
          setConfirmationTitle('Waiting');
          setCurrent(1);
        }
      } else {
        setBasicInfoTitle('In Progress');
        setTAndCTitle('Waiting');
        setKYCTitle('Waiting');
        setAccreditationTitle('Waiting');
        setOfferingDocsTitle('Waiting');
        setPaymentsTitle('Waiting');
        setConfirmationTitle('Waiting');
        setCurrent(0);
      }
    }
    )();
  }, [userInfo, selectedTokenSymbol]);

  const steps = [
    {
      title: "Your Request Is In Review By Platform Issuer Admin.",
      status: 'info'
    },
    {
      content: "You Have To Accept Terms & Condition To Invest.",
      status: 'info'
    },
    {
      content: "You Have to Complete Your AI Verification , Your Request will be Reviewed By Issuer Compiliance Admin.",
      status: 'info'
    },
    {
      content: "You Have to Complete Your KYC Verification , Your Request will be Reviewed By Issuer Compiliance Admin.",
      status: 'info'
    },
    {
      content: offeringDocTitle == 'In Progress' ? "You Have To Sign Offering Documents To Invest " : "Your Can Invest Now .",
      status: offeringDocTitle == 'In Progress' ? 'info' : 'success'
    }
  ];


  return (
    <>
      {/* <Steps
        size="small"
        current={current}
        onChange={()=> history.push('/investor/home')}
      >
        <Step title={basicInfoTitle}
          description="Self verification" />

        <Step title={tAndCTitle}
          description="Acceptance of Terms And Condition." />

        { userInfo?.country.toLowerCase() ==="united states of america" && (!userInfo?.accreditedInvestor || userInfo?.accreditedInvestor=="true") && <Step title={accreditationTitle}
          description="Accredition" />}

        <Step title={kYCTitle}
          description="KYC" />

        {/* <Step title={offeringDocTitle}
          description="Offering Docs" /> */}

        {/*
          <Step title={paymentTitle}
          description="Payments" />

        <Step title={confirmationTitle}
          description="Confirmation" />

      </Steps> */}
    </>
  );
}