import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Card, Typography, Tabs, Button, Spin, Table } from "antd";
import { UserAddOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { AdminService } from "../Admin.service";
import { User } from "../../../Shared/interfaces";
import moment from "moment";
import { SharedService } from "../../../Shared/Shared.service";
import './AdminRoles.scss';

const { Title } = Typography;
const { TabPane } = Tabs;

const adminService = new AdminService();
const sharedService = new SharedService();

export default function AdminRoles() {
  const history = useHistory();
  const [admins, setAdmins] = useState<User[]>();
  const [adminRoles, setAdminRoles] = useState<any[]>();
  const [loading, setLoading] = useState<boolean>(true);


  const fetchData = useCallback(async () => {
    setLoading(true);
      const response = await adminService.getAdmins();

      const _admins: User[] = response.data;

      const _adminRoles = sharedService.adminRolesOptions2.map((roleOpt) => {
        const result = sharedService.clone(roleOpt);
        result["total"] = _admins.filter((admin) =>
          admin.roles.includes(roleOpt.value as any)
        ).length;

        return result;
      });

      console.log(_adminRoles);

      setAdmins(_admins);
      setAdminRoles(_adminRoles);
      setLoading(false);
    }, []);

    useEffect(() => {
      fetchData();
    }, [fetchData]);


    const handleAssignAdminRole = () => {
      history.push("/issuer-super-admin/admin/assign-admin-role");
      fetchData(); // Refresh data after assigning a role
    };
  
    const handleAddAdmin = () => {
      history.push("/issuer-super-admin/admin/add-admin");
      fetchData(); // Refresh data after adding an admin
    };  

  const adminsColumns = [
    {
      title: "NAME",
      dataIndex: "firstName",
    },
    {
      title: "EMAIL ADDRESS",
      dataIndex: "email",
    },
    {
      title: "ADMIN ROLE",
      dataIndex: "roles",
      render: (roles: string[], record: User) =>
        sharedService.adminRolesOptions2
          .filter((opt) => roles.includes(opt.value))
          .map((opt) => opt.name)
          .join("| "),
    },
    {
      title: "TEAM",
      dataIndex: "team",
      render: (value: string, record: User) =>
        (record.roles.includes("employee") && "Employee") ||
        (record.roles.includes("contractor_consultant_advisor") &&
          "Contractor") || <div style={{ textAlign: "center" }}>-</div>,
    },
    {
      title: "DEPARTMENT",
      dataIndex: "displayableTeamDepartment",
      render: (value: string, record: User) =>
        value || <div style={{ textAlign: "center" }}>-</div>,
    },
    {
      title: "TEAM ROLE",
      dataIndex: "displayableTeamRole",
      render: (value: string, record: User) =>
        value || <div style={{ textAlign: "center" }}>-</div>,
    },
    {
      title: "AFFILIATE",
      dataIndex: "isAffiliate",
      render: (value: User["isAffiliate"]) =>
        value ? "Affiliate" : "Not Affiliate",
    },
    {
      title: "CREATED ON",
      dataIndex: "creationTS",
      render: (value: User["creationTS"]) => moment(value).format("LLL"),
    },
    {
      title: "ACTION",
      render: (value, record: User) => {
        return (
          <Button
            type="primary"
            onClick={() =>
              history.push(
                `/issuer-super-admin/admin/team-managment/edit/${record._id}`
              )
            }
          >
            Edit
          </Button>
        );
      },
    },
  ];

  const adminRolesColums = [
    {
      title: "ADMIN ROLE",
      dataIndex: "name",
    },
    {
      title: "NUMBER OF ADMINS",
      dataIndex: "total",
    },
  ];

  return (
    <>
      <br />
      <br />
      <Row justify="center">
        <Col span={23}>
          {!admins && (
            <div style={{ textAlign: "center" }}>
              <Spin size="large" />
            </div>
          )}
          {admins && (
            <Card>
              <Title level={1} style={{ textAlign: "center" }}>
                Admin Roles
              </Title>

              <Tabs
                defaultActiveKey="1"
                style={{ textAlign: "center" }}
                type="card"
                size="large"
                // onChange={changeTab}
                tabBarExtraContent={
                  <>
                    {/*<Button
                      style={{ marginRight: "10px" }}
                      onClick={() =>
                        history.push(
                          "/issuer-super-admin/admin/add-authorizers"
                        )
                      }
                    >
                      Add Authorizers
                    </Button>
                    <Button
                      style={{ marginRight: "10px" }}
                      onClick={() =>
                        history.push(
                          "/issuer-super-admin/admin/assign-authorizers"
                        )
                      }
                    >
                      Assign Authorizers
                    </Button>*/}
                    <Button
                      style={{ marginRight: "10px" }}
                      onClick={handleAssignAdminRole}
                    >
                      Assign Admin Role
                    </Button>
                    <Button
                      onClick={handleAddAdmin}>
                    
                      <UserAddOutlined /> Add Admin
                    </Button>
                  </>
                }
              >
                <TabPane tab="Admin Roles" key="1">
                  <Table
                    columns={adminRolesColums}
                    dataSource={adminRoles}
                    rowKey="value"
                    pagination={false}
                  ></Table>
                </TabPane>

                <TabPane tab="Admins" key="2">
                  <Table
                    scroll={{ x: true }}
                    columns={adminsColumns}
                    dataSource={admins}
                    rowKey="_id"
                    pagination={false}
                    id="admin-details-table"
                  />
                </TabPane>
              </Tabs>
            </Card>
          )}
        </Col>
      </Row>
    </>
  );
}
