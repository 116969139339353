import React, { useEffect, useState } from "react";
import "../AddShareholders/AddShareholders.scss";
import { environment } from "../../../environments/environment";
import { Card, Col, Modal, Row, Table, Tabs, notification } from "antd";
import Title from "antd/lib/typography/Title";
import { PlaySquareOutlined, ProjectOutlined } from "@ant-design/icons";
import { SharedService } from "../../Shared/Shared.service";
import { AuthService } from "../../Shared/Auth.service";
const {TabPane}=Tabs;



const sharedService = new SharedService();
const useUserContext = () => new AuthService().useUserContext();

function AddShareholders() {
  const APIURL = environment.APIURL;



  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [investedAmount, setInvestedAmount] = useState('0');
  const [tokenCount, setTokenCount] = useState("");
  const [pricePerToken, setPricePerToken] = useState('1');
  const [walletAddress, setWalletAddress] = useState("");
  const [showModal, setshowModal] = useState(false);
  const [companyId, setcompanyId] = useState<any>("");
  const [caId, setCAid] = useState<any>("");
  const [shareholderStatus, setshareholderStatus] = useState("approved");
  const [shareholderList, setShareholderList] = useState([]);
  const [shareholderApprovedRejectedList, setShareholderApprovedRejectedList] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedShareholders, setSelectedShareholders] = useState([]);
  const [loading, setLoading] = useState(false);
  const { userInfo, setUserInfo } = useUserContext();
  const [tokenDetails, setTokenDetails] = useState<any>(null);


    useEffect(() => {
        setcompanyId(userInfo?.company?.id);
        // setCAid(userInfo?.caId)
        getShareholderApi(userInfo?.company?.id);
  }, []);

  const getShareholderApi = async (id) => {
    setLoading(true)
    const response = await sharedService.getShareholderApi(id);
    let shData = response.data.shareHolderDetails;
    console.warn(shData)
    if (response.success && shData) {
      console.log("getShareholderApi Response: ", response);
      setPricePerToken(
        shData[0]?.tokenDetails?.pricePerToken ?? 1
      );
      setTokenDetails(shData[0]?.tokenDetails)
      setshareholderStatus( shData[0]?.status);
      setShareholderList( shData.filter(s => !['approved'].includes(s.status)) ); //show rejected or pending
      setShareholderApprovedRejectedList(shData.filter(s => ['approved','rejected'].includes(s.status))) //show approved and rejected only
    }
    setLoading(false)
  };

  const openModal = () => {
    setshowModal(!showModal);
  };

  const columns = [
    {
      title: "Name",
      dataIndex:"name",
      key:"name"
    },
    {
      title: "Email",
      dataIndex: "email",
      key:"email"
    },
    {
      title: "Invested Amoount",
      dataIndex: "investedAmount",
      key:"investedAmount"
    },
    {
      title: "Token Count",
      dataIndex: "tokenCount",
      key:"tokenCount"
    },
    {
      title: "Wallet Address",
      dataIndex: "wallet",
      key:"wallet"
    },
  ];

  const addShareholderDetails = async () => {
    if (
      name != "" &&
      email != "" &&
      walletAddress != "" &&
      investedAmount != ""
    ) {
      var upcomingShareholderList = 
        {
          name: name,
          email: email,
          wallet: walletAddress,
          tokenCount: (
            parseInt(investedAmount) / parseInt(pricePerToken) 
          ).toFixed(2),
          investedAmount: investedAmount,
        }
      // var newShareholderList = upcomingShareholderList.concat(shareholderList);
      const response = await sharedService.addShareholderDetails(
        companyId,
        upcomingShareholderList
      );
      if (response.data) {
        getShareholderApi(companyId);
        setshowModal(!showModal);
        console.log("addShareholderDetails Response: ", response);
        if (response.success == true) {
          notification.success(
            {message: 'Success',
            description: "Successfully added shareholder details"}
            );
        }
        if (response.success == false) {
          notification.error({message:response.error.message});
        }
      }
    } else {
      notification.error({message:"Error: Please fill all the mandatory fields"});
    }
  };

  const sendForApproval = async () => {
    try {
      console.log(selectedShareholders)
      if(selectedShareholders.length <= 0){
        notification.info({message:"Please select record first!"});
        return;
      }
      setLoading(true)
      const approvalres = await Promise.all(selectedShareholders.map(async (sh: any) => {
        return await sharedService.sendForApproval(companyId, sh._id);
      }))

      if (approvalres){
        console.log("approval res ",approvalres)
        notification.success({message:"Successfully sent the details for approval"});
        
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
        
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  };
  const deleteShareholder = async () => {
    try {
      console.log(selectedShareholders)
      if(selectedShareholders.length <= 0){
        notification.info({message:"Please select record first!"});
        return;
      }
      setLoading(true)
      const deleteRes = await Promise.all(selectedShareholders.map(async (sh: any) => {
        return await sharedService.deleteShareholder(companyId, sh._id);
      }))

      if (deleteRes){
        notification.success({message:"Records deleted successfully!"});
        getShareholderApi(companyId);
      }
        
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedShareholders(selectedRows)
    },
    getCheckboxProps: (record) => {
      return {
        disabled: record.status === 'pending' && record.llpCA !=='rejected'
      };
    }
  };

  return (
    <>
      <br></br>
      <Row justify="center">
        <Col span={22} style={{ textAlign: "center" }}>
          <Card>
            <Title
              level={2}
              style={{
                textAlign: "left",
                color: "#1890ff",
                // fontSize: '30px',
                fontWeight: "bold",
              }}
            >
              Shareholder Information
            </Title>
            <Tabs defaultActiveKey="request" centered >
            <TabPane tab={<span><PlaySquareOutlined/>Pending Shareholder's</span>} key="request">
            <div style={{ display: "grid" }}>
              <Table
                columns={columns}
                dataSource={shareholderList}
                rowKey={(record:any) => record._id}
                pagination={false}
                rowSelection={rowSelection}
                loading={loading}
              />
              <div style={{ marginLeft: "auto" }}>
              <button
                    type="submit"
                    disabled={!tokenDetails || tokenDetails.status !=='verified' || (tokenDetails.hasOwnProperty('deployed') && tokenDetails.deployed===true) }
                    className="btn btn-primary mb-2 addShareholders__submit"
                    onClick={deleteShareholder}
                  >
                    Delete
                  </button>

                
                  <button
                    type="submit"
                    className="btn btn-primary mb-2 addShareholders__submit"
                    disabled={!tokenDetails || tokenDetails.status !=='verified' || (tokenDetails.hasOwnProperty('deployed') && tokenDetails.deployed===true)}
                    onClick={sendForApproval}
                  >
                    Send for Approval
                  </button>
                

                <button
                  type="submit"
                  disabled={!tokenDetails || tokenDetails.status !=='verified' || (tokenDetails.hasOwnProperty('deployed') && tokenDetails.deployed===true) }
                  className="btn btn-primary mb-2 addShareholders__submit"
                  onClick={openModal}
                >
                  Add Shareholder Info
                </button>
              </div>
            </div>
            </TabPane>
            <TabPane tab={<span><ProjectOutlined/> Approved/Rejected Shareholder's</span>}>
            <Table
                columns={columns}
                dataSource={shareholderApprovedRejectedList}
                rowKey="_id"
                pagination={false}
              />
            </TabPane>
          </Tabs>
          </Card>
        </Col>
      </Row>
      
      <Modal
        title={`Add Shareholder Information`}
        okText="ADD"
        cancelText="CANCEL"
        visible={showModal}
        onOk={() => {
          addShareholderDetails();
        }}
        onCancel={() => {
          openModal();
        }}
      >
        <form className="addShareholders">
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">Name*</label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  setName(e.target.value);
                }}
                value={name}
                placeholder="Name"
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">Email*</label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                value={email}
                placeholder="Email"
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">Invested Amount*</label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  setInvestedAmount(e.target.value);
                }}
                value={investedAmount}
                placeholder="Invested amount"
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">Token Count*</label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  setTokenCount(e.target.value);
                }}
                value={(
                  parseInt(investedAmount) / parseInt(pricePerToken)
                ).toFixed(2)}
                placeholder="Token count"
                disabled
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">Wallet*</label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  setWalletAddress(e.target.value);
                }}
                value={walletAddress}
                placeholder="Wallet"
              />
            </div>
          </div>
        </form>
      </Modal>
      
    </>
  );
}

export default AddShareholders;
