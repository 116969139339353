import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Typography, Table, Button, Spin, Descriptions,notification, Modal } from 'antd';
import { IssuerSuperAdminService } from '../IssuerSuperAdmin.service';
import { AuthService } from '../../Shared/Auth.service';
import moment from 'moment';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './memberInfo.css';

const issuerSuperAdminService = new IssuerSuperAdminService();
const authService = new AuthService();
const useUserContext = () => authService.useUserContext();

const MemberDetails = ()=>{
   const { userInfo } = useUserContext();
   const [membersList, setMembersList] = useState([]);
   const [loading, setLoading] = useState(false);
   const [record, setRecord] = useState();
   const [modal, showModal] = useState(false);
   const [memberType, setMemberType] = useState('individual');
   const [initialMembers, setInitialMembers] = useState([]);
   const [addMembers, setAddMembers] = useState([]);
   const [refresh, setRefresh] = useState(false);
   const [initialCompany, setInitialCompany] = useState([]);
   const [addCompanies, setAddCompanies] = useState([]);
   const [newCompanyForm, showNewCompanyForm] = useState(false);

   useEffect(()=>{
      (async () => {
         setLoading(true);
         if(userInfo){
            let membersDBList = await issuerSuperAdminService.getMembersDetailsList(userInfo._id);
            setMembersList(membersDBList.data);
            // let intialMembersList = await issuerSuperAdminService.getInitialMembers();
            // setInitialMembers(intialMembersList.data);

            // let intialCompanyList = await issuerSuperAdminService.getInitialCompany();
            // setInitialCompany(intialCompanyList.data);
            setLoading(false);
         }
      })();
   },[userInfo])

   const hideModal=()=>{
      showModal(false);
      setRecord();
   }
   const hideNewCompanyForm=()=>{
      showNewCompanyForm(false);
   }

   const addNewMembers = (user)=>{
      console.log(user);
      let flag = true;
      let newMembers = [...addMembers];
      newMembers = newMembers.filter((member)=>{
         if(member._id == user._id){
            flag = false;
            return false;
         }else{
            return true;
         }
      })
      if(flag==false){
         setAddMembers(newMembers);
      }else{
         setAddMembers([...addMembers, user]);
      }
   }

   const addNewCompany = (company)=>{
      console.log(company);
      let flag = true;
      let newCompany=[...addCompanies];
      newCompany = newCompany.filter((member)=>{
         if(member._id == company._id){
            flag = false;
            return false;
         }else{
            return true;
         }
      })
      if(flag==false){
         setAddCompanies(newCompany);
      }else{
         setAddCompanies([...addCompanies, company]);
      }
   }

   const submitMembers = async()=>{
      if(!addMembers || addMembers.length<1){
         return false;
      }else{
         setLoading(true);
         let newMembers=new Array();
         addMembers.forEach((member)=>{
            let updatedMember = {};
            updatedMember.firstName = member.firstName;
            updatedMember.lastName = member.lastName;
            updatedMember.email = member.email;
            updatedMember.memberType = "individual";
            updatedMember.country = member.country;
            updatedMember.state = member.state?member.state:'';
            updatedMember.department = member.department.name;
            updatedMember.role = member.role;
            updatedMember.phone = member.phone?member.phone:'';
            updatedMember.locale = member.locale;
            updatedMember.creationTs = new Date().getTime();
            newMembers.push(updatedMember)
         })

         const response = await issuerSuperAdminService.saveIntialMembers(newMembers);

         if (response.success) {
            notification.success({message: 'Success', description: 'Members added successfully'});
            setAddMembers([]);           
         } else {
            notification.error({message: 'Error', description: response.error.message});            
         }
         setRefresh(true);
         setLoading(false);
      }
   }

   const submitInitialCompany=async()=>{
      if(!addCompanies || addCompanies .length<1){
         return false;
      }else{
         setLoading(true);
         let newCompany=new Array();
         addCompanies.forEach((company)=>{
            let updatedCompany = {};
            updatedCompany.companyName = company.companyName;
            updatedCompany.email = company.email;
            updatedCompany.memberType = "company";
            updatedCompany.country = company.country;
            updatedCompany.state =company.state;
            updatedCompany.city = company.city;
            updatedCompany.address = company.address;
            updatedCompany.dateOfIncorporation = company.dateOfIncorporation;
            updatedCompany.govTaxIdNumber = company.govTaxIdNumber;
            updatedCompany.locale = company.locale;
            updatedCompany.creationTs = new Date().getTime();
            newCompany.push(updatedCompany)
         })

         const response = await issuerSuperAdminService.saveIntialCompany(newCompany);

         if (response.success) {
            notification.success({message: 'Success', description: 'Companies added successfully'});
            setAddCompanies([]);           
         } else {
            notification.error({message: 'Error', description: response.error.message});            
         }
         setRefresh(true);
         setLoading(false);
      }
   }

   const addCompany=()=>{

   }
   
   return(
      <>
      {loading &&  
         <div style={{textAlign:'center'}}>
           <br/>
           <Spin size='large'/>
         </div>
       }
      {!loading && <div className="col-12 member-details-wrapper">
         <div className="col-12 member-details">
            <h2 className="heading">Member Details</h2>
            <table className='member-table'>
               <thead>
                  <tr>
                     <th>Company Name</th>
                     <th>Member Type</th>
                     <th>Country</th>
                     <th>State</th>
                     <th>Created On</th>
                     <th>Details</th>
                  </tr>
               </thead>
               <tbody>
                  {membersList && membersList.map((member)=>(
                     <tr key={member._id + Math.random()}>
                        {member.memberType == 'individual' ? 
                           (<td>{member.firstName + ' ' + member.lastName}</td>):
                           (<td>{member.companyName}</td>)}
                        <td>{member.memberType}</td>
                        <td>{member.country}</td>
                        <td>{member.state}</td>
                        <td>{moment(member.creationTS).format('LLL')}</td>
                        <td>
                           <button type="button" className="ant-btn ant-btn-link">
                              <span onClick={()=>{setRecord(member);showModal(true)}}>View</span>
                           </button>
                        </td>
                     </tr>
                  ))}
               </tbody>
            </table>
         </div>

         {record && (
         <Modal width={700} 
            visible={!!modal}
            onCancel={hideModal}
            id="view-details"
            footer={[
            <Button key="close" onClick={hideModal}>
               Close
            </Button>
            ]}>
            <Spin spinning={false} size='large'>
               <div className='modal-details'>
                  <div style={{backgroundColor:'#142A44',padding:'15px'}}>
                        <sub style={{color:'white',fontWeight:'bold',fontSize:'35px',fontFamily:'Arial, Helvetica, sans-serif'}}>Member Details</sub>
                  </div>
                  <br/>
                  <Row>
                     <Col span={12}>
                     <div>
                        <sub style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>
                           {record.memberType == 'individual' ? 'Memeber Name' : 'Company Name'}</sub>
                        <br/>
                        <sub style={{color:'#050505',fontSize:'20px',textAlign:'left'}}>{record.memberType == 'individual' ? (record.firstName+' '+record.lastName) : record.companyName}</sub>
                     </div>
                  </Col>
                  <Col span={12}>
                     <div>
                        <sub style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>Country</sub>
                        <br/>
                        <sub style={{color:'#050505',fontSize:'20px',textAlign:'left'}}>{record.country}</sub>
                     </div>
                  </Col>
                  </Row>
                  <br/>
                  <Row>
                  <Col span={12}>
                     <div>
                        <sub style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>State</sub>
                        <br/>
                        <sub style={{color:'#050505',fontSize:'20px',textAlign:'left'}}>{record.state}</sub>
                     </div>
                  </Col>
                     <Col span={12}>
                     <div>
                        <sub style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>{record.memberType == 'individual' ? 'Department' : 'Address'}</sub>
                        <br/>
                        <sub style={{color:'#050505',fontSize:'20px',textAlign:'left'}}>{record.memberType == 'individual'? record.department : record.address}</sub>
                     </div>
                  </Col>
                  </Row>
                  <br/>
                  <Row>
                  <Col span={12}>
                     <div>
                        <sub style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>{record.memberType == 'individual'?'Role':'Zip Code'}</sub>
                        <br/>
                        <sub style={{color:'#050505',fontSize:'20px',textAlign:'left'}}>{record.memberType == 'individual'? record.role: record.zip}</sub>
                     </div>
                  </Col>
                     <Col span={12}>
                     <div>
                        <sub style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>Email Address</sub>
                        <br/>
                        <sub style={{color:'#050505',fontSize:'20px',textAlign:'left'}}>{record.email}</sub>
                     </div>
                  </Col>
                  </Row>
                  <br/>
                  <Row>
                  {record.memberType=='individual' ? (<Col span={12}>
                     <div>
                        <sub style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>Phone Number</sub>
                        <br/>
                        {/* <sub style={{color:'#050505',fontSize:'20px',textAlign:'left'}}>{record.phone}</sub> */}
                        <PhoneInput style={{color:'#050505',fontSize:'20px',textAlign:'left', marginTop: '20px'}} country={"us"} value={record.phone} onChange={()=>{}}/>
                     </div>
                  </Col>):(
                     <>
                     <Col span={12}>
                           <div>
                              <sub style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>Date of Incorporation</sub>
                              <br/>
                              <sub style={{color:'#050505',fontSize:'20px',textAlign:'left'}}>{moment(record.dateOfIncorporation).format('LLL')}</sub>
                           </div>
                        </Col>
                        <Col span={12}>
                        <div>
                           <sub style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>Government Tax Id Number</sub>
                           <br/>
                           <sub style={{color:'#050505',fontSize:'20px',textAlign:'left'}}>{record.govTaxIdNumber}</sub>
                        </div>
                     </Col>
                     </>
                  )}
                  </Row>
                  <br/>
               </div>
            </Spin> 
         </Modal>)}
         <Modal width={700} 
            visible={!!newCompanyForm}
            onCancel={hideNewCompanyForm}
            id="new-company"
            footer={[
            <Button key="close" onClick={hideNewCompanyForm}>
               Close
            </Button>
            ]}>
            <Spin spinning={false} size='large'>
               <div className='modal-details'>
                  
               </div>
            </Spin> 
         </Modal>
      </div>}
      </>
   )
};

export default MemberDetails;