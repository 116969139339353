import { Skeleton } from 'antd'
import React from 'react'

const MarketPlaceSkelton = () => {
  return (
    <div className="container" style={{ marginTop: '150px', marginBottom: '30px' }}>
        <div className="row row-cols-auto g-4">
          {Array.from({length:4},(_,index) =>(
            <div key={index} className={`col-12 col-xl-4 col-xxl-3  c`}>
              <div className="card realestateCardWrapper">
                <div className="card-body p-2 d-flex flex-column justify-content-around">
                  <div className="header relative">
                    <div className="businessname text-primary">
                      <Skeleton active title={false} width={1} rows={1} />
                    </div>
                    <div className="businesslocation"></div>
                  </div>
                  <div className="">
                    <Skeleton.Image active={true} style={{ width: '287px', height: '260px' }}  />
                  </div>
                  <div className="businessinfo">
                    <Skeleton active />
                  </div>
                  <Skeleton.Button active style={{ width: '100%' }} />
                </div>
              </div>
            </div>  
                ))
          }    
        </div>
      </div>
  )
}

export default MarketPlaceSkelton