import React from 'react'
// import '../builder/builder.scss'
import poly1 from '../../../assets/images/Agents/poly1.png';
import poly2 from '../../../assets/images/Agents/poly2.png';
import poly3 from '../../../assets/images/Agents/poly3.png';
import poly4 from '../../../assets/images/Agents/poly4.png';
import { Menuiba,  } from '../Builder/Builder';

const builderCardsArr=[
    {
        img:poly1,
        header:'Exclusive Professional Onboarding',
        sub:'Initiate your digitalization journey with an exclusive onboarding experience',
        detail:`Initiate your digitalization journey with a bespoke onboarding experience. The distinct needs of real estate agents, provide an exclusive process that effortlessly integrates your listings into our sophisticated digital platform.`
    },
    {
        img:poly2,
        header:'KYC Verification',
        sub:'Integrity within our secure environment',
        detail:`Upholding the highest standards of security, the Know Your Customer (KYC) verification process is done. Real Estate Agents are invited to submit the requisite documentation for meticulous verification, establishing a foundation of trust and integrity within the secured environment.`
    },
    {
        img:poly3,
        header:'Blockchain Infrastructure',
        sub:'Fortified Blockchain Infrastructure for Agents',
        detail:`Let your listings be plunged with a fortified blockchain infrastructure, safeguarding their integrity in the digital landscape. Blockchain technology serves as the bedrock, ensuring indisputable records, transparent transactions, and an immutable ownership history creating a secure and trusted environment for Investors.`
    },
    {
        img:poly4,
        header:'Networking Elegance',
        sub:'Amplified Market Presence and Networking Elegance',
        detail:`Boost your listings with amplified market visibility, attracting a discerning audience of potential Investors. Engage in an elegant collaborative ecosystem provided, where Real estate agents can easily network with Investors, fellow professionals, and industry stakeholders to foster growth and strategic business expansion.`
    },
];

const Agent = () => {
    return (
        <>
        <Menuiba/>

        <div className="container builder-container">
         <div className="row builder-row ">
            { builderCardsArr.map((card,i )=>
                 <div key={i} className="col-12 col-md-3 builder-col">
                     <span className='marker'>{i+1}
                     <span className='marker-line'></span>
                     </span>
                     <div className="builder-card-warpper">
                         <img src={card.img} alt="Engage" />
                         <div className="builder-card-info text-center">
                             <h4 style={{color:'#27AE60'}} className='mt-3 mb-2 text-success'>{card.header}</h4>
                             <span className='mb-2 text-dark' style={{fontWeight:500}}>{card.sub}</span>
                             <p className='text-dark p-3' style={{fontSize:'13px'}}>
                                 {card.detail}
                             </p>
                         </div>
                     </div>
                 </div>
            )}
         </div>
        </div>

        {/* <JoinUs/> */}
        </>
       )
}

export default Agent