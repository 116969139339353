import React, { useEffect, useState } from 'react'
import {useParams} from 'react-router-dom'
import {AccountService} from '../../Account.service'
import {
  Form,
  Input,
  Button,
  Card,
  Select,
  InputNumber,
  Col,
  Row,
  message,
  Alert,
  Result
} from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { SharedService } from '../../../Shared/Shared.service';

const { Option } = Select;

const sharedService = new SharedService();
const accountService=new AccountService()

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 16,
      offset: 8
    }
  }
};

function Data()
{
  this.emailHash='';
}
export default ()=>{
  const {email}=useParams();
  const [registered,setRegistered]=useState(false)
  const [submitting, setSubmitting] = useState(false);
  const [countries, setCountries] = useState();
  const [form]=Form.useForm()

  const history = useHistory();
  var d=new Data()
      d.emailHash=email;
  useEffect(()=>{
    (async ()=>{
      console.log(d);
      const response=await accountService.getData(d);
      if(response.data)
      {
        console.log(response.data);
        form.setFieldsValue({
          email:response.data['0'].email,
          companyName:response.data['0'].companyName,
          firstName:response.data['0'].firstName,
          lastName:response.data['0'].lastName,
          contactNumber:response.data['0'].contactNumber,
          role:response.data['0'].role
        })
      }
    })()
  },[])
  
  useEffect(() => {
    (async () => {
      const response = await sharedService.getCountries();
      setCountries(response.data);
    })();
  }, []);
  

  const register = async (req) => {
    setSubmitting(true);
    const response = await accountService.registerUser(req);
    if (response.success) {
      setRegistered(true)
      form.resetFields()
    } 
    else 
    {
      message.error(response.error.message);
    }
    setSubmitting(false);
  }


  return (
    <>
    
    <Row justify="center" style={{margin:"60px 0"}}>
      <Col span={16} style={{textAlign:'center'}}>
          <Card>
            <div hidden={registered}>
          {registered && <Alert message="Succesfully Registered" type="success"></Alert>}
            <h2 style={{textAlign:'center'}}>Sign up!</h2>
            <Form
              {...formItemLayout}
              form={form}
              onFinish={register}
              style={{ margin: '2% 10% 0 10%', width: '60%' }}>
              <Form.Item 
                label='Role'
                name="role"
                rules={[
                  {
                    required: true,
                    message: 'Please input role!',
                    whitespace: true
                  }
                ]}>
                <Input style={{color:'green'}} disabled/>
              </Form.Item>
              <Form.Item 
                label='First name'
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: 'Please input your First Name!',
                    whitespace: true
                  }
                ]}>
                <Input style={{color:'green'}} disabled/>
              </Form.Item>
      
              <Form.Item 
                label='Last name'
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: 'Please input your Last Name!',
                    whitespace: true
                  }
                ]}>
                <Input style={{color:'green'}} disabled/>
              </Form.Item>
      
              <Form.Item
                label='E-mail'
                name="email"
                rules={[
                  {
                    type: 'email',
                    message: 'The input is not valid E-mail!'
                  },
                  {
                    required: true,
                    message: 'Please input your E-mail!'
                  }
                ]}>
                <Input style={{color:'green'}} disabled/>
              </Form.Item>
      
              <Form.Item 
                label='Password'
                name="password"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!'
                  },
                  {
                    validator: (rule, value) => {
                      const strongRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$\-_[\](){}<>|°¬+=~".,;:'#!%*?&])[A-Za-z\d@$\-_[\](){}<>|°¬+=~".,;:'#!%*?&]{8,}$/;
                      if(strongRegex.test(value)) return Promise.resolve();
                      return Promise.reject('The password must contains at least 1 number, 1 lowercase, 1 uppercase and 1 special character and 8 characters long');
                    }
                  }
                ]}>
                <Input.Password />
              </Form.Item>
      
              <Form.Item 
                label='Confirm Password'
                name="confirm"
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password!'
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('password') === value) return Promise.resolve();
                      return Promise.reject('The two passwords that you entered do not match!');
                    },
                  }),
                ]}>
                <Input.Password />
              </Form.Item>
      
              <Form.Item 
                label='Name of Master Company'
                name="companyName"
                rules={[
                  {
                    required: true,
                    message: 'Please input company name here',
                    whitespace: true
                  }
                ]}>
                <Input style={{color:'green'}} disabled/>
              </Form.Item>
      
              <Form.Item 
                label='Company Country'
                name="companyCountry"
                validateStatus={countries? '' : 'validating'}
                hasFeedback
                help= {countries? '' : "Loading Countries..."}
                rules={[
                  {
                    required: true,
                    message: 'Select Country'
                  }
                ]}>
                <Select showSearch placeholder="Select Country">
                  {countries?.map(country => 
                    <Option key={country._id} value={country._id}>{country.name['en']}</Option>
                  )}
                </Select>
              </Form.Item>
      
              <Form.Item 
                label='Contact Number'
                name="contactNumber"
                rules={[
                  {
                    required: true,
                    message: 'Please input number here',
                    whitespace: true
                  }
                ]}>
                <Input style={{color:'green'}} disabled/>
              </Form.Item>
      
              <Form.Item 
                label='State'
                name="state"
                rules={[
                  {
                    required: true,
                    message: 'Please input state!',
                    whitespace: true
                  },
                ]}>
                <Input />
              </Form.Item>
      
              <Form.Item 
              label='Enter zip code'
                name="zipCode"
                rules={[
                  {
                    required: true,
                    message: 'Please input zip code!'
                  },
                ]}>
                <InputNumber
                  min={0}
                  style={{ width: '80%' }}/>
              </Form.Item>
    
      
              <Form.Item {...tailFormItemLayout}>
                <Button type='primary' htmlType='submit' loading={submitting}>Register</Button>
                <br />
                <></><Link to='/account/login'>Login</Link>
              </Form.Item>
      
            </Form>
            </div>
            <div hidden={!registered}>
            <Result
                status="success"
                title="Successfully Registered !"
                extra={[
                  <Button type="primary" htmlType="button" onClick={()=>{history.push('/account/login')}}>
                    Login
                  </Button>
                ]}
                ></Result>
            </div>
          </Card>
          </Col>
          </Row>
          </>
  )

}