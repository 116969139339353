import React, { useEffect, useState } from 'react'
import { Row, Col, Card, Typography, Form, Input, Button, notification, Spin, Descriptions, Result,Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { IssuerSuperAdminService } from '../../../IssuerSuperAdmin.service';
import { AuthService } from '../../../../Shared/Auth.service';
import { PaymentHandlerService } from '../PaymentHandlerService';
import InvestModel from '../../../../Shared/InvestModel/InvestModel';
const {Title} = Typography;

const issuerSuperAdminService = new IssuerSuperAdminService();
const useUserContext = () => new AuthService().useUserContext()

export const achInputs = [
    {name:'bankName',label:'Bank Name',type:'default',required:true},
    {name:'bankAddress',label:'Bank Address',type:'default',required:true},
    {name:'accountHolderName',label:'Account Holder Name',type:'default',required:true},
    {name:'accountNumber',label:'Account Number',type:'default',required:true},
    {name:'routingNumber',label:'Routing Number',type:'default',required:true},
    {name:'swift',label:'BIC Code (if applicable)',type:'default',required:false},
    // {name:'bankPhone',label:'Bank Phone Number',type:'default',required:true},
    {name:'confirmationOfAuthorization',label:'Confirmation of Authorization',type:'default',required:true},
]


  const AchWireUsa = (props) => {
      const { onBack,remainingShareCount,token,offeringDetails} = props
      const [loading, setLoading] = useState(false);
      const [MailChequeForm] = Form.useForm();
      const [submitting, setSubmitting] = useState(false);
      const [paymentInfo, setPaymentInfo] = useState<any>();
      const [isEditionMode, setIsEditionMode] = useState(false);
      const [isApproved, setApproved]= useState(false)
      const { userInfo } = useUserContext();
      const [disableEdit, setDisableEdit]= useState(false)
      const [displayInvestModel, setDisplayInvestModel]= useState(false)
      
    const paymenthandlerservice = new PaymentHandlerService('USBankDetail',issuerSuperAdminService)
    const companyId = userInfo?.role === 'investor' ? token.issuerInfo[0].companyId : userInfo?.company?.id

    useEffect(() => {
      (async () => {
        setLoading(true);
        const paymentnfo = await paymenthandlerservice.loadData(companyId)
        console.log(paymentnfo)
        if (paymentnfo) {
          setPaymentInfo(paymentnfo.data);
          setIsEditionMode(true);

          if (paymentnfo.data?.status == 'approved' || paymentnfo.data?.status == 'pending') {
              setDisableEdit(true);
          } 
        }

        setLoading(false);
      })();
    }, [isApproved]);
      
    const savePaymentInfo = async(formValue) => {
      setSubmitting(true);
      const response = await paymenthandlerservice.savePaymentInfo(formValue,userInfo?.company?.id)
      if(response){
        setPaymentInfo(formValue);
        setIsEditionMode(true);
        setDisableEdit(false);
      }
      setSubmitting(false);
    }
    
    const sendForApproval = async() => {
        const response = await paymenthandlerservice.sendForApproval(userInfo?.company?.id)
        setApproved(prev => !prev)
    }
  
    const investNow = () => {
      if (userInfo) {
        setDisplayInvestModel(true);
      } else {
        // history.push("account/register/investor")
      }
    }

    const cancelInvestModel = (reload=true) => {
      setDisplayInvestModel(false);
      if(reload)
        setTimeout(()=> window.location.reload(), 500)
    }

    return (
      <>
        <br /><br />
        <Row justify="center">
          <Col span={24}>
            {loading &&
              <div style={{ textAlign: 'center' }}>
                <br />
                <Spin size='large' />
              </div>
            }
            
            {/* if user is companymanager or investor and payment has been approved */}
            {(!loading && 
              (userInfo?.role === 'issuer company manager' ||
              (paymentInfo?.status === 'approved' && 
              userInfo?.role === 'investor'))
            ) &&
              <Card>
                <div style={{display:"flex",justifyContent:"center",alignItems: "baseline"}}>
                  <Title level={2} style={{ textAlign: 'center' }}>ACH/Wire transfer Bank Account </Title> 
                  <Tooltip title="US location based Account" placement="right" >
                <QuestionCircleOutlined style={{ fontSize: '20px', color: '#1890ff', cursor: 'pointer',padding: "10px"}} />
                </Tooltip>
                </div>
                
                <br />

                <Form labelAlign="left" initialValues={isEditionMode ? paymentInfo : undefined} onFinish={savePaymentInfo}>

                  {achInputs.map((input, index) => (
                    <Form.Item
                      key={index}
                      label={input.label}
                      labelCol={{ xs: { span: 24 }, sm: { span: 10, push: 2 } }}
                      wrapperCol={{ xs: { span: 24 }, sm: { span: 12 } }}
                      rules={[
                        {
                          required: (input.required),
                          message: 'This field is required',
                          whitespace: true
                        }
                      ]}
                      name={input.name}>

                      {input.type == 'default' ?
                        <Input disabled={isEditionMode} />
                        :
                        <Input.TextArea disabled={isEditionMode} />
                      }


                    </Form.Item>
                  ))
                  }
                  {userInfo?.role !== "investor" &&
                    <Row justify='end'>
                      {isEditionMode && <Button size='large' type='primary' disabled={disableEdit} onClick={sendForApproval}>
                        {paymentInfo?.status == "approved"
                          ? "APPROVED"
                          : "SEND FOR APPROVAL"}
                      </Button>}
                      &nbsp;&nbsp;
                      {isEditionMode && <Button size='large' type='primary' onClick={() => setIsEditionMode(false)}>
                        EDIT
                      </Button>}
                      &nbsp;&nbsp;
                      {!isEditionMode && <Button htmlType='submit' size='large' type='primary' loading={false}>
                        SAVE
                      </Button>}
                    </Row>}

                  {userInfo?.role === "investor" &&
                  <>
                    <Row justify='space-between'>
                      <Button onClick={() => onBack()} size='large' type='primary' loading={false}>
                        Back
                      </Button>
                      <Button onClick={() => investNow()} size='large' type='primary' loading={false}>
                        Make Payment
                      </Button>
                    </Row>
                    </>
                  }
                </Form>

              </Card>
            }

          
            {/* if the user is investor and the payment has not been approved */}
            {(!loading && userInfo?.role === 'investor' && paymentInfo?.status !== 'approved') &&<Result
                status="info"
                title="Payment Method Not Active"
                subTitle="This Payment method is not active. Please wait for approval."
                // extra={[
                //   <Button type="primary" key="Home" href="home" onClick={()=>console.log()}>
                //     Back
                //   </Button>,
                // ]}
              />}

          </Col>

          {userInfo?.role === 'investor' &&
          (<InvestModel displayInvestModel={displayInvestModel}
            handleCancel={cancelInvestModel}
            token={token}
            offeringDetails={offeringDetails}
            remainingShareCount={remainingShareCount}
            userInfo={props.userInfo}
            companyData={[]} />)}
        </Row>

      </>
    );
}

export default AchWireUsa