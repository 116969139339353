export default {
    public:[
        "Whether the company has subsidiaries or interest in any other entity by the way of shareholding or agreement?",
        "List of all states and other jurisdictions (including other countries) in which the Company or any of its subsidiaries is qualified to do business as a foreign corporation, or in which its trade names are registered.",
        "List of all states and other jurisdictions (including other countries) in which the Company or any of its subsidiaries files tax returns by reason of the ownership of property or the conduct of its business.",
        "whether the company has filed any documents with SEC?",
        "whether the company has paid any dividend?",
        "Description of the management structure of the Company and each of its subsidiaries and a hierarchy of officers of the Company and its subsidiaries",
        "Whether the company  or its officer or any of its director has or had  any threatened or pending claim, litigation, or arbitration, and of each claim, litigation?",
        "Description of any bankruptcy, criminal, or other judicial proceeding pending, threatened, expected, or completed within the last five (5) years involving the Company, any of its subsidiaries, or any of the Company's officers, directors, or principal stockholders",
        "Is there any Intellectual property violations by company or its subsidiary or associate or group company?",
        "Are there any  litigation within the last five (5) years involving an officer or director of the Company concerning bankruptcy, crimes, securities law, or business practices?",
        "Description of any investigations of the Company, pending or threatened, by any federal, state, local, or foreign authorities",
        "Are there any actual or threatened disputes or claims of infringement, invalidity, or similar claims of (or relating to) trade or service marks, trade names, patents, copyrights, or other similar rights owned or used by the Company and/or its subsidiaries",
        "Weather the Company has any borrowing of money including term loan and revolving credit agreement and debt instruments and any other agreements issued thereunder, and the current balance of each such source of indebtedness?",
        "weather the company has provided any guaranties of any of the Company's borrowings, including without limitation, letters of credit and guaranties by other entities or persons (including without limitation any of the Company's promoters, officers, directors, employees, or stockholders).",
        "are there any material transactions between the Company and any of its officers, directors, promoters, stockholders, or employees",
        "Whether Financial Statements are provided for last 5 years?",
        "Description of future cashflow projection.",
        " Brief description of contingent liabilities.",
        "whether the company has policy for internal control and describe how it has been implemented?"
    ],
    private:[
        "Whether the Company has done business within the last five (5) fiscal years in any other name?",
        "Description of the classes and series of authorized capital stock and other securities of the Company and its subsidiaries, and a statement of the number of issued and outstanding shares of each such class and series of securities and the number of treasury shares of each class and series of capital stock.",
        "Whether the company has any contractual restrictions on transfer of the Company's securities or assets?",
        "Minutes of all meetings of, and all actions without a meeting by, the Board of Directors (and of all committees thereof) of the Company and each of its subsidiaries",
        "Minutes of all meetings of, and all actions without a meeting by, the stockholders of the Company and each of its subsidiaries",
        "List of the names and addresses of officers and directors of the Company and each of its subsidiaries",
        "List of all family relationships among the officers, directors, principal stockholders, and/or key employees of the Company and each of its subsidiaries. ",
        "List of all of the Company's promoters and any consideration they have received or may receive",
        "Please provide the details of all patents, copyrights, and other intellectual property rights owned by or assigned to, or licensed to or by, the Company and its subsidiaries. ",
        "Description of all real property owned by the Company and/or its subsidiaries, including the following:\n1. Location\n2. Description\n3. Date of purchase\n4. Description of any liens or mortgages",
        "Whether the Company's has provided current business or strategic plan and any long-range business plans?",
        "Copies of all federal, state, local, and foreign income and franchise tax returns filed by the Company and its subsidiaries for the last five (5) years with respect to the business, assets, or income of the Company."
    ]
}