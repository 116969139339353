import React from 'react'
import { Switch, Route, useRouteMatch } from "react-router-dom";
import KYCVerificationRequest from './KYCVerificationRequest'
import AIVerificationRequest from './AIVerificationRequest'
import Whitelist from './Whitelist';
import TeamMemberKYCRequests from './TeamMemberKYCRequests'
import ProposalDetails from './ProposalDetails/ProposalDetails';
export default () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/kyc-verification-request`}>
       <KYCVerificationRequest/>
      </Route>
      <Route path={`${path}/ai-verification-request`}>
      <AIVerificationRequest/>
      </Route>
      <Route path={`${path}/whitelist`}>
        <Whitelist/>
      </Route>
      <Route path={`${path}/team-member-kyc-requests`}>
       <TeamMemberKYCRequests></TeamMemberKYCRequests>
      </Route>
      <Route path={`${path}/proposal-details`}>
       <ProposalDetails/>
      </Route>
    </Switch>
  )
}
