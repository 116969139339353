import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  Row,
  Card,
  Col,
  Form,
  Typography,
  Select,
  message,
  Space,
  List,
  Empty,
  Spin,
} from "antd";
import { IssuerCounselService } from "../IssuerCounsel.service";
import { AuthService } from "../../Shared/Auth.service";
import EditAnswer from "./EditAnswer";
const { Title } = Typography;
const { TextArea } = Input;
const { Option } = Select;
const FormItem = Form.Item;
const issuerCounselService = new IssuerCounselService();
const authService = new AuthService();
const useUserContext = () => authService.useUserContext();
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

export default () => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [issuersList, setIssuersList] = useState(null);
  const [dueDiligenceList, setDueDiligenceList] = useState(null);
  const [reviewDueDiligenceView, setReviewDueDiligenceView] = useState(false);
  const [answerView, setAnswerView] = useState(false);
  const [currentIssuerToDisplay, setCurrentIssuerToDisplay] = useState(null);
  const { userInfo } = useUserContext();
  useEffect(() => {
    (async () => {
      const issuerQuestionListResponse =
        await issuerCounselService.getAnsweredDueDiligence({
          companyId: userInfo.company.id,
        });
      if (
        issuerQuestionListResponse &&
        issuerQuestionListResponse.success &&
        issuerQuestionListResponse.data
      ) {
        if (issuerQuestionListResponse?.data?.length > 0) {
          await setDueDiligenceList(issuerQuestionListResponse.data);
        } else {
          await setDueDiligenceList(null);
          //message.error("No Data to display!");
        }
      } else {
        message.error("Error in loading due diligence!");
      }
    })();
  }, [answerView, reviewDueDiligenceView]);

  const showAnswer = (data) => {
    setCurrentIssuerToDisplay(data);
    setReviewDueDiligenceView(true);
  };

  const submitReview = async (review) => {
    setSubmitting(true);
    if (review) currentIssuerToDisplay.isApprovedByIC = review;
    else currentIssuerToDisplay.isRejectedByIC = true;
    delete currentIssuerToDisplay["_id"];
    const dataSubmissionResult =
      await issuerCounselService.reviewDueDiligenceQuestion(
        currentIssuerToDisplay
      );
    if (dataSubmissionResult.success && dataSubmissionResult.data) {
      await form.resetFields();
      await setDueDiligenceList(
        dueDiligenceList.filter(
          (data) => data.question !== currentIssuerToDisplay.question
        )
      );
      message.success("Due diligence reviewed succesfully.");
      setTimeout(()=> window.location.reload(), 1000);
    } else {
      message.error("Some problem occured while reviewing question.");
    }
    setReviewDueDiligenceView(false);
    setCurrentIssuerToDisplay(null);
    setSubmitting(false);
  };

  const onEdit = async (dataToSend) => {
    await setSubmitting(true);
    let response = await issuerCounselService.editAnswerOfDueDiligenceQuestion(
      dataToSend
    );
    if (response.success && response.data) {
      message.success("Answered.");
    } else {
      message.error("Error while saving data!!");
    }
    await setReviewDueDiligenceView(false);
    await setAnswerView(false);
    await setSubmitting(false);
  };
  const onBack = () => {
    setReviewDueDiligenceView(true);
    setAnswerView(false);
  };
  return (
    <>
      <br />
      <Row justify="center">
        <Col span={22} style={{ textAlign: "center" }}>
          <Card>
            <Title
              level={2}
              style={{
                textAlign: "left",
                color: "#1890ff",
                // fontSize: '30px',
                fontWeight: "bold",
              }}
            >
              Review Due Diligence
            </Title>
            <div hidden={reviewDueDiligenceView || answerView}>
              {dueDiligenceList ? (
                <List
                  style={{ textAlign: "left" }}
                  loading={!dueDiligenceList}
                  key="dueDiligenceList"
                >
                  {dueDiligenceList?.map((data, index) => (
                    <List.Item
                      key={index}
                      style={{ cursor: "pointer" }}
                      actions={[
                        <Button type="primary" onClick={() => showAnswer(data)}>
                          Review
                        </Button>,
                      ]}
                    >
                      <List.Item.Meta title={data.question}></List.Item.Meta>
                    </List.Item>
                  ))}
                </List>
              ) : (
                <Empty />
              )}
            </div>

            <div hidden={!reviewDueDiligenceView} style={{ textAlign: "left" }}>
              <Space direction="vertical">
                <h4>Question:{`  ${currentIssuerToDisplay?.question}`}</h4>
                <br />
                <h5>
                  Answer:
                  {currentIssuerToDisplay?.answerType === "attachment" && (
                    <ol>
                      {currentIssuerToDisplay?.answer.map((a) => (
                        <>
                          <li>
                            <a
                              href={a.URL}
                              rel="noopener norefferer"
                              target="_blank"
                            >
                              {a.name}
                            </a>
                          </li>
                        </>
                      ))}
                    </ol>
                  )}
                  {currentIssuerToDisplay?.answerType !== "attachment" &&
                    currentIssuerToDisplay?.answer}
                </h5>
                <Space>
                  <Button
                    type="primary"
                    onClick={() => submitReview(true)}
                    loading={submitting}
                  >
                    Accept
                  </Button>
                  <Button
                    onClick={() => submitReview(false)}
                    danger
                    loading={submitting}
                  >
                    Reject
                  </Button>
                  <Button
                    onClick={() => {
                      setAnswerView(true);
                      setReviewDueDiligenceView(false);
                    }}
                    loading={submitting}
                  >
                    Edit
                  </Button>
                  <Button
                    onClick={() => setReviewDueDiligenceView(false)}
                    loading={submitting}
                  >
                    Cancel
                  </Button>
                </Space>
              </Space>
            </div>
            <div hidden={!answerView}>
              {answerView && (
                <EditAnswer
                  currentQuestionToAnswer={currentIssuerToDisplay}
                  onSubmit={onEdit}
                  onBack={onBack}
                  submitting={submitting}
                />
              )}
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};
