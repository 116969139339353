import { Button, Card, Col, Descriptions, Input, Modal, notification, Radio, Result, Row, Spin, Table, Typography } from 'antd';
import React, { useState, useEffect } from 'react';
import { CryptoAccount, WithdrawalRequest, User, TokenConfigurationProcess, SymbolDetailsAndSTData } from '../../../Shared/interfaces';
import { WithdrawalRequestsService } from '../WithdrawalRequests.service';
import moment from 'moment'
import { ApproversAndAccountsService } from '../../ApproversAndAccounts/ApproversAndAccounts.service';
import { SharedService } from '../../../Shared/Shared.service';
import { AuthService } from '../../../Shared/Auth.service';
import { TokenConfigurationService } from '../../../TokenConfigurations/TokenConfiguration.service';
import { SecurityTokenRegistryService } from '../../../Shared/SecurityTokenRegistery/SecurityTokenRegistry.service';
import  EscrowFacetService  from '../../../Shared/SecurityToken/Facets/EscrowFacet/EscrowFacet';
import { MetamaskService } from '../../../Shared/Metamask.service';
import BigNumber from 'bignumber.js';
import TransactionModal from '../../../Shared/TransactionModal';
import ERC20Facet from "../../../Shared/SecurityToken/Facets/ERC20Facet";
import EscrowFacet from '../../../Shared/SecurityToken/Facets/EscrowFacet';
import MainFacet from '../../../Shared/SecurityToken/Facets/MainFacet';

const {Title} = Typography;

const withdrawalRequestsService = new WithdrawalRequestsService();
const approversAndAccountsService = new ApproversAndAccountsService();
const sharedService = new SharedService();
const tokenConfigurationService = new TokenConfigurationService();
const securityTokenRegisteryService = new SecurityTokenRegistryService();
const escrowFacetService = new EscrowFacetService();
const erc20Facet = new ERC20Facet();
const escrowFacet = new EscrowFacet();
const mainFacet = new MainFacet();

const useUserContext = () => new AuthService().useUserContext();
const useSelectedWalletContext = () => new MetamaskService().useSelectedWalletContext();


export default function ListWithdrawalRequests() {

  const [loading, setLoading] = useState(true);
  const [withdrawalRequests, setWithdrawalRequests] = useState<WithdrawalRequest[]>();
  const [cryptoAccounts, setCryptoAccounts] = useState<CryptoAccount[]>();
  const [selectedDestAccount, setSelectedDestAccount] = useState<string>();
  const [amount, setAmount] = useState<string | undefined>("");
  const [currencySymbol, setCurrencySymbol] = useState<string>();
  const [address, setAddress] = useState<string | undefined>("");
  const [submitting, setSubmitting] = useState(false);
  const [approvers, setApprovers] = useState<User[]>();
  const [escrowDepositTokenInfo, setEscrowDepositTokenInfo] = useState<any>();
  const {userInfo} = useUserContext();
  const [symbolDetailsAndSTData, setSymbolDetailsAndSTData] = useState<SymbolDetailsAndSTData>();
  const [availableToWithdraw, setAvailableToWithdraw] = useState<any>('');
  const [pendingWithdrawalAmount, setPendingWithdrawalAmount] = useState<any>('');
  const {selectedWallet, networkId} = useSelectedWalletContext();
  const [transactions, setTransactions] = useState<{submitting?: boolean, receipt?: any, details: string}[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false);
  const [actionOption, setActionOption] = useState<'request' | 'approve' | 'reject' | 'withdraw'>();
  const [selectedRecord, setSelectedRecord] = useState<WithdrawalRequest>();
  const [withdrawnTokens, setWithdrawnTokens] = useState<Record<string, boolean>>({});
  const [tokenAddressValue, setTokenAddressValue] = useState('');
  const [loadingButtonId, setLoadingButtonId] = useState<string | null>(null);

  const totalAvailable = new BigNumber(availableToWithdraw).times(new BigNumber(10).pow(-18)).minus(pendingWithdrawalAmount).decimalPlaces(18).toString(10)

  useEffect(() => {
   const fetchData = async () => {
      if(!userInfo) return;
      try {
      const _tokenConfigurationProcess: TokenConfigurationProcess = (await tokenConfigurationService.getLastTokenConfigurationProcess()).data;

      if(!_tokenConfigurationProcess?.tokenSymbol) return setLoading(false);

      const _symbolDetailsAndSTData = await securityTokenRegisteryService.getSymbolDetailsAndSTData(_tokenConfigurationProcess.tokenSymbol);
      setSymbolDetailsAndSTData(_symbolDetailsAndSTData);

      if(!_symbolDetailsAndSTData?.symbolDetails.isDeployed) return setLoading(false);
       const depositTokens = await escrowFacetService.getListedDepositTokens(_symbolDetailsAndSTData.securityTokenData.contractAddress);
       const [resWithdrawalRequests, resAmountUnderReview, resCryptoAccounts, resApprovers, _availableToWithdraw,_escrowDepositTokenInfo,issuerSuperAdminWallet] =await Promise.all([
        withdrawalRequestsService.getWithdrawalRequests(),
        withdrawalRequestsService.getPendingWithdrawalAmount(),
        approversAndAccountsService.getCryptoAccounts(),
        approversAndAccountsService.getApprovers(),
        escrowFacetService.getTotalInvestedAmountsInEscrow(_symbolDetailsAndSTData.securityTokenData.contractAddress,depositTokens),
         await erc20Facet.getTokenDetails(
             depositTokens,
             _symbolDetailsAndSTData.securityTokenData.contractAddress
         ),
         mainFacet.issuerSuperAdmin(_symbolDetailsAndSTData?.securityTokenData?.contractAddress)
      ]);

      const _withdrawalRequests = resWithdrawalRequests.data;
      const _amountUnderReview = resAmountUnderReview.data;
      const _cryptoAccounts = resCryptoAccounts.data;
      const _approvers = resApprovers.data;
      const tokenAddress = _symbolDetailsAndSTData?.securityTokenData?.contractAddress
      setWithdrawnTokens(_escrowDepositTokenInfo.reduce((acc, item) => {
        if (_withdrawalRequests.some(req => req.address === item.address)) {
          acc[item.address] = true
        }
        return acc
      }, {}));

      setWithdrawalRequests(_withdrawalRequests);
      setPendingWithdrawalAmount(_amountUnderReview);
      setCryptoAccounts(_cryptoAccounts);
      setApprovers(_approvers);
      setAvailableToWithdraw(_availableToWithdraw);
      setEscrowDepositTokenInfo(_escrowDepositTokenInfo);
      setSelectedDestAccount(issuerSuperAdminWallet);
      setTokenAddressValue(tokenAddress);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false); // Ensure loading state is updated even on error
    }
  };
  
  fetchData();
}, [userInfo]);


  const columns = [
    {
      title: 'TRANSFER ID',
      dataIndex: '_id'
    },
    {
      title: 'DATE OF REQUEST',
      dataIndex: 'creationTS',
      render: (value: string) => moment(value).format('lll')
    },
    {
      title: 'AMOUNT TO WITHDRAW (MATIC)',
      dataIndex: 'amount'
    },
    {
      title: 'DESTINATION ACCOUNT',
      dataIndex: 'wallet',
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      render: (value: WithdrawalRequest['status']) => {
        if(value === 'pending') return 'Under Review';
        if(value === 'rejected') return 'Rejected';
        if(value === 'verified') return 'Approved';
      }
    },
    // {
    //   title: 'ACTION',
    //   render: (value, record: User) => {
    //     return (
    //       <Button danger onClick={() => {
    //        setSubmitting(false);
    //        setProviderToRemove(record);
    //       }}>
    //         Remove
    //       </Button>
    //     );
    //   }
    // }
  ];

  const isApprover = approvers?.find(approver => approver._id === userInfo?._id);

  if(isApprover || userInfo?.role === 'issuer token admin') {
    columns.push(
      {
      title: 'ACTION',
      render: (value, record: WithdrawalRequest) => {
        return (
          <>
          {userInfo?.role === 'issuer token admin' && 
            <>
              <Button 
              loading={loadingButtonId === `withdraw_${record._id}`}
              disabled={record.status !== 'verified' || record.approvedBy.length < 1} type='link' onClick={() => {
                setSelectedRecord(record);
                openConfirmationModal('withdraw');
                // withdrawETH(record.wallet, record._id, record.amount)
              }
                }>
                Withdraw
              </Button>
            </>
          }

          {isApprover &&
            <>
              <Button 
              loading={loadingButtonId === `approve_${record._id}`}
               disabled={record.approvedBy.includes(userInfo?._id as string) || record.rejectedBy.includes(userInfo?._id as string) || record.status !== 'pending'}
                type='link' onClick={() => {
                setSelectedRecord(record);
                openConfirmationModal('approve');
                // approveWithdrawalRequest(record._id)
              }}>
                Approve
              </Button>
              <Button 
              loading={loadingButtonId === `reject_${record._id}`} 
              disabled={record.approvedBy.includes(userInfo?._id as string) || record.rejectedBy.includes(userInfo?._id as string) || record.status !== 'pending'} type='link' danger onClick={() => {
                setSelectedRecord(record);
                openConfirmationModal('reject');
                // rejectWithdrawalRequest(record._id)
              }}>
                Reject
              </Button>
            </>
          }
          </>
        );
      }
    } as any
    );
  }


  const approveWithdrawalRequest = async(requestId: string) => {
    setLoadingButtonId(`approve_${requestId}`);
console.info({tokenAddressValue});
console.info({selectedWallet});
    try {
    const metaMaskResponse = await escrowFacet.withdrawApproval(tokenAddressValue, selectedWallet!)

    if (!metaMaskResponse.status) {
      notification.error({ message: 'Error', description: 'Request Rejected' });
      return;
    }
    
    const response = await withdrawalRequestsService.approveWithdrawalRequest({requestId});

    if (response.success) {
      notification.success({message: 'Success', description: 'Request Approved succesfully'});

      const _withdrawalRequests = sharedService.clone(withdrawalRequests);

      const withdrawalRequest = _withdrawalRequests?.find(request => request._id === requestId) as WithdrawalRequest ;

      // withdrawalRequest.approvedBy.push(userInfo?._id as string);

      const approvedBy = [...new Set([...withdrawalRequest.approvedBy, userInfo?._id as string])];

      const approversIds = approvers?.map(approver => approver._id) as string[];

      const isPending = approversIds.find(approverId => !approvedBy.includes(approverId));

      withdrawalRequest.approvedBy = approvedBy;
      withdrawalRequest.status = isPending? 'pending' : 'verified';

      setWithdrawalRequests(_withdrawalRequests);
      setIsConfirmationModalVisible(false);
      setSubmitting(false)

    } else {
      throw new Error(response.error.message);
    }
  } catch (error) {
    console.error('Error approving withdrawal request:', error);
    notification.error({message: 'Error', description: 'An unexpected error occurred'});
  } finally {
    setLoadingButtonId(null);
    setIsConfirmationModalVisible(false);
  }
}


  const rejectWithdrawalRequest = async(requestId: string) => {
    setLoadingButtonId(`reject_${requestId}`);
    
    try {
    const response = await withdrawalRequestsService.rejectWithdrawalRequest({requestId});

    if (response.success) {
      notification.success({message: 'Success', description: 'Request Rejected succesfully'});

      const _withdrawalRequests = sharedService.clone(withdrawalRequests);
      const withdrawalRequest = _withdrawalRequests?.find(request => request._id === requestId) as WithdrawalRequest;

      const rejectedBy = [...new Set([...withdrawalRequest.rejectedBy, userInfo?._id as string])];

      withdrawalRequest.rejectedBy = rejectedBy;
      withdrawalRequest.status = 'rejected';

      setWithdrawalRequests(_withdrawalRequests);
      setPendingWithdrawalAmount(prev => new BigNumber(prev).minus(withdrawalRequest.amount).toString(10));
      setIsConfirmationModalVisible(false);

    } else {
      throw new Error(response.error.message);
    }
  } catch (error) {
    console.error('Error rejecting withdrawal request:', error);
    notification.error({message: 'Error', description: 'An unexpected error occurred'});
  } finally {
    setLoadingButtonId(null);
    setIsConfirmationModalVisible(false);
  }
}




  const newWithdrawalRequest = async() => {
    if(!selectedDestAccount || !amount) return notification.error({message: 'Error', description: 'There are empty fields'});
    if(new BigNumber(amount).isEqualTo(0)) return notification.error({message: 'Error', description: 'Amount has to be greather than zero'});
    if(new BigNumber(amount).isGreaterThan(totalAvailable)) return notification.error({message: 'Error', description: 'Invalid amount'});

    setLoadingButtonId('new_withdrawal_request');

    const response = await withdrawalRequestsService.newWithdrawalRequest({amount, destAccount: selectedDestAccount, symbol: currencySymbol, address:address});

    if (response.success) {
      notification.success({message: 'Success', description: 'Request sent succesfully'});

      setWithdrawnTokens(prev => ({
        ...prev,
        [address!]: true,
      }));

      const requestId = response.data;

      const _withdrawalRequests = sharedService.clone(withdrawalRequests);

      _withdrawalRequests?.push({
        _id: requestId,
        status: 'pending',
        creationTS: Date.now(),
        wallet: selectedDestAccount,
        searchAbleWallet: selectedDestAccount.toLowerCase(),
        companyId: '',
        approvedBy: [],
        rejectedBy: [],
        apiKey: '',
        amount
      });

      setWithdrawalRequests(_withdrawalRequests);
      setPendingWithdrawalAmount(prev => new BigNumber(prev).plus(amount).toString(10));
      setIsConfirmationModalVisible(false);
      setAmount('');

    } else {
      notification.error({message: 'Error', description: response.error.message});

    }

    setLoadingButtonId(null);
    
  }



  const withdrawETH = async(receiverAccount: string, requestId: string, amount: string) => {
    setIsModalVisible(true);
    setLoadingButtonId(`withdraw_${requestId}`);
    setTransactions([
      {details: 'Initiating MATIC withdrawal', submitting: true}
    ]);
  
    try {

      if (!receiverAccount || !tokenAddressValue || !selectedWallet) {
        throw new Error(`Invalid parameters: receiverAccount=${receiverAccount}, tokenAddressValue=${tokenAddressValue}, selectedWallet=${selectedWallet}`);
      }
      const _amount = new BigNumber(amount || 0)
        .times(new BigNumber(10).pow(18))
        .decimalPlaces(0)
        .toString(10);
      console.info({receiverAccount});
      console.info({tokenAddressValue});
      console.info({selectedWallet});
      console.info({requestId});
      console.info({amount});
        const metaMaskResponse = await escrowFacet.withdrawCollectedAmountFromContract(tokenAddressValue, selectedWallet!, receiverAccount)
       console.info({metaMaskResponse});
        if (!metaMaskResponse.status) {
          notification.error({ message: 'Error', description: 'Request Rejected' });
          return;
        }
  
        await withdrawalRequestsService.markRequestAsIssued({requestId});
  
        const _withdrawalRequests = sharedService.clone(withdrawalRequests);
        setWithdrawalRequests(_withdrawalRequests?.filter(request => request._id !== requestId));
  
        setAvailableToWithdraw(prev => new BigNumber(prev).minus(_amount).toString(10));
        setPendingWithdrawalAmount(prev => new BigNumber(prev).minus(amount).toString(10));
        setIsConfirmationModalVisible(false);
  
        setTransactions(prev => [{
          ...prev[0],
          submitting: false,
          details: 'MATIC withdrawal successful'
        }]);
  
        notification.success({ message: 'Success', description: 'MATIC withdrawn successfully' });
    } catch (error) {
      console.error(error);
      let errorMessage = 'An unknown error occurred';
      if (error instanceof Error) {
        errorMessage = error.message;
      } else if (typeof error === 'string') {
        errorMessage = error;
      }
      setTransactions(prev => [{
        ...prev[0],
        submitting: false,
        details: `Error`
      }]);
      notification.error({ message: 'Withdrawal Error', description: "Error" });
      console.log({errorMessage});
    } finally {
      setLoadingButtonId(null);  
      setIsConfirmationModalVisible(false);
    }    
  }

const openConfirmationModal = async(option: "request" | "approve" | "reject" | "withdraw", amountValue?:string, smybol?:string, addressValue?:string) => {
  const amount = new BigNumber(amountValue ?? 0).isGreaterThanOrEqualTo(0) ? amountValue : ''
  setAmount(amount)
  setCurrencySymbol(smybol)
  setAddress(addressValue)

  if(option === 'request') {
      if(!selectedDestAccount || !amount) return notification.error({message: 'Error', description: 'There are empty fields'});
      if(new BigNumber(amount).isEqualTo(0)) return notification.error({message: 'Error', description: 'Amount has to be greather than zero'});
      if(new BigNumber(amount).isGreaterThan(totalAvailable)) return notification.error({message: 'Error', description: 'Invalid amount'});
  }

  setActionOption(option);
  setIsConfirmationModalVisible(true);
}


const submitAction = async() => {
  setSubmitting(true); 
  if(actionOption === 'request') {
    await newWithdrawalRequest();
  } else {
    const record = selectedRecord;
    if(!record) return;

    try {
      if(actionOption === 'approve') {
        setLoadingButtonId(`approve_${record._id}`);
        await approveWithdrawalRequest(record._id);
      }
      else if(actionOption === 'reject') {
        setLoadingButtonId(`reject_${record._id}`);
        await rejectWithdrawalRequest(record._id);
      }
      else {
        setLoadingButtonId(`withdraw_${record._id}`);
        await withdrawETH((record as any).address, record._id, record.amount);
      }
    } catch (error) {
      console.error('Error in submitAction:', error);
    }
  }
  setSubmitting(false); 
}




  return (
    <>
      <br/><br/>
      <Row justify="center">
        <Col span={23}>
        {loading &&
          <div style={{textAlign:'center'}}>
            <br/>
            <Spin size='large'/>
          </div>
        }
        {!loading &&
          <Card>
            <Title level={1} style={{textAlign:'center'}}>Withdrawal and Transfer Request Approvers</Title>

            {!symbolDetailsAndSTData?.symbolDetails.isDeployed &&
              <>
                <Result
                  title={`Security Token not deployed`}/>
              </>
            }

            {symbolDetailsAndSTData?.symbolDetails.isDeployed &&
              <>
                {userInfo?.role === 'issuer token admin' && selectedWallet?.toLowerCase() !== symbolDetailsAndSTData.symbolDetails.owner.toLowerCase() &&
                  <>
                    <Title level={2} style={{textAlign:'center'}}>Wrong selected wallet on metamask</Title>
                    <Result
                      status="error"
                      title = {
                        <p>
                          Select the wallet {' '}
                          <a target="_blank" rel="noopener noreferrer" href={`${sharedService.etherscanURL[networkId as string]}/address/${symbolDetailsAndSTData.symbolDetails.owner}`}>
                            {sharedService.minifyAddress(symbolDetailsAndSTData.symbolDetails.owner.toLowerCase())}
                          </a>
                          {' '} in order to Withdraw your Tokens
                        </p>
                      }
                    />
                  </>
                }

                {(userInfo?.role !== 'issuer token admin' || selectedWallet?.toLowerCase() === symbolDetailsAndSTData.symbolDetails.owner.toLowerCase()) &&
                  <>
                    <Table
                      columns={columns}
                      dataSource={withdrawalRequests}
                      rowKey='_id'
                      pagination={false}
                    />

                    <br/>
                    {userInfo?.role === 'issuer token admin' &&
                    <>
                      {escrowDepositTokenInfo.map((item, index) => (
                          <div key={item.address}>
                            <Descriptions column={2} bordered>
                              <Descriptions.Item label={`Available balance in Escrow`}>
                                {item.balance/Math.pow(10,Number(item.decimals))} {item.symbol}
                              </Descriptions.Item>

                              <Descriptions.Item label="Pending withdrawal">
                                {item.balance/Math.pow(10,Number(item.decimals))} {item.symbol}
                              </Descriptions.Item>

                              <Descriptions.Item span={2} label="Available to Withdraw">
                                {item.balance/Math.pow(10,Number(item.decimals))} {item.symbol}
                              </Descriptions.Item>
                              <Descriptions.Item >
                                <Button 
                                key={item.address}
                                loading={loadingButtonId === `withdraw_${item.address}`}
                                onClick={() => {openConfirmationModal('request', String(item.balance/Math.pow(10,Number(item.decimals))),item.symbol,item.address)}} 
                                type="primary"
                                disabled={withdrawnTokens[item.address] || (item.balance <= 0)}
                                >
                                  Withdraw
                                </Button>
                              </Descriptions.Item>
                            </Descriptions>
                            <br /><br />
                          </div>
                      ))}
                    </>
                    }
                    {userInfo?.role === 'issuer token admin' &&
                      <>
                      {/*<Descriptions column={2} bordered>
                        <Descriptions.Item label="Available balance in Smart Contract">
                          {new BigNumber(availableToWithdraw).times(new BigNumber(10).pow(-18)).toString(10)} MATIC
                        </Descriptions.Item>

                        <Descriptions.Item label="Pending withdrawal">
                        {pendingWithdrawalAmount} MATIC
                        </Descriptions.Item>

                        <Descriptions.Item span={2} label=" Available to Withdraw">
                          {totalAvailable} MATIC
                        </Descriptions.Item>

                      </Descriptions>
                      <br/><br/>*/}
                       {/*  <p>
                          Available balance in Smart Contract: {new BigNumber(availableToWithdraw).times(new BigNumber(10).pow(-18)).toString(10)} ETH
                        </p>
                        <p>
                          Pending withdrawal: {pendingWithdrawalAmount} ETH
                        </p>
                        <p>
                          Available to Withdraw: {totalAvailable} ETH
                        </p> */}

                        {/* {isApprover &&
                          <>
                          <div style={{textAlign: 'center'}}>
                            <p style={{fontWeight: 'bold'}}>
                              How much do you want to withdraw (MATIC)
                            </p>
                            <Input value={amount} onChange={e => updateAmount(e.target.value)} style={{width: '300px'}}/>

                            <br/><br/>

                            <p style={{fontWeight: 'bold'}}>
                              Send to Account
                            </p>

                            <Radio.Group onChange={e => setSelectedDestAccount(e.target.value)} value={selectedDestAccount}>
                              {cryptoAccounts?.map((cryptoAccount, index) =>
                                <Radio key={index} style={radioStyle} value={cryptoAccount.wallet}>
                                  ({cryptoAccount.name}) {cryptoAccount.wallet}
                                </Radio>
                              )}
                            </Radio.Group>

                            <br/><br/>
                            <Button loading={submitting} onClick={() => {
                              openConfirmationModal('request');
                            }} type="primary">
                              Withdraw
                            </Button>
                          </div>
                          </>
                        } */}
                      </>
                    }
                    <br/><br/>
                  </>
                }
              </>
            }

          </Card>
        }

        </Col>
      </Row>

      <TransactionModal
        title = {'Withdrawal'}
        transactions = {transactions}
        isModalVisible = {isModalVisible}
        closeModal = {() => setIsModalVisible(false)}
      />

      <Modal
        visible={isConfirmationModalVisible}
        okText={'YES'}
        onOk={submitAction}
        okButtonProps={{loading: submitting}}
        onCancel={()=> setIsConfirmationModalVisible(false)}
        cancelText={'NO'}
        title={'Confirm'}>
          {actionOption === 'request' &&
            <p style={{textAlign: 'center', fontWeight: 'bold'}}>Are you sure you want to perform this request?</p>
          }
          {actionOption === 'approve' &&
            <p style={{textAlign: 'center', fontWeight: 'bold'}}>Are you sure you want to approve this request?</p>
          }
          {actionOption === 'reject' &&
            <p style={{textAlign: 'center', fontWeight: 'bold'}}>Are you sure you want to reject this request?</p>
          }
          {actionOption === 'withdraw' &&
            <p style={{textAlign: 'center', fontWeight: 'bold'}}>Are you sure you want to perform this withdrawal?</p>
          }
      </Modal>
    </>
  );
}
