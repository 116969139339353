import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Typography, Table, Button, Spin, Descriptions, Form, Radio, notification } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { User } from '../../../Shared/interfaces';
// import { SharedService } from '../../../Shared/Shared.service';
import { AuthService } from '../../../Shared/Auth.service';
import { SharedService } from '../../../Shared/Shared.service';
import { AdminService } from '../Admin.service';

const {Title} = Typography;

const adminService = new AdminService();
const sharedService = new SharedService();

const useUserContext = () => new AuthService().useUserContext();

// const useSelectedWalletContext = () => new MetamaskService().useSelectedWalletContext();

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
};


export default () => {
  const {userInfo} = useUserContext();
  // const {selectedWallet, networkId} = useSelectedWalletContext();
  const history = useHistory();

  const [filter, setFilter] = useState({limit: 10, searchPage: 1});
  const [teamMembers, setTeamMembers] = useState<User[] | null>();
  const [loadingMoreTeamMembers, setLoadingMoreTeamMembers] = useState<boolean>();
  const [canLoadMoreTeamMembers, setCanLoadMoreTeamMembers] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    (async () => {
      if(!userInfo) return;
      
      const [_teamMembers] = (await Promise.all([
        adminService.getCompanyMembers(filter.limit, 1)
      ])).map(res => res.data);

      setTeamMembers(_teamMembers);
      setFilter((prev: any) => ({...prev, searchPage: prev.searchPage + 1}));
      setCanLoadMoreTeamMembers(_teamMembers.length === filter.limit);

    })();
  }, [userInfo, filter.limit]);


  const loadMoreTeamMembers = async() => {
    if(loadingMoreTeamMembers || !teamMembers || !userInfo) return;

    setLoadingMoreTeamMembers(true);

    const response = await adminService.getCompanyMembers(filter.limit, filter.searchPage);
    const _teamMembers: User[] = response.data;


    setTeamMembers(prev => [...prev as any, ..._teamMembers]);
    setLoadingMoreTeamMembers(false);
    setCanLoadMoreTeamMembers(_teamMembers.length === filter.limit);
  }



  const columns = [
    // {
    //   title: '#',
    //   dataIndex: 'columNumber',
    //   render: (value: string, record: User) => teamMembers?.findIndex(member => member._id===record._id) as number + 1,
    // },
    {
      title: 'NAME',
      dataIndex: 'firstName'
    },
    // {
    //   title: 'LAST NAME',
    //   dataIndex: 'lastName'
    // },
    {
      title: 'EMAIL ADDRESS',
      dataIndex: 'email'
    },
    {
      title: 'ADMIN ROLES',
      dataIndex: 'roles',
      render: (roles: string[], record: User) => (
        sharedService.adminRolesOptions2.find(opt => roles.includes(opt.value))?
          sharedService.adminRolesOptions2.filter(opt => roles.includes(opt.value)).map(opt => opt.name).join('| ') 
          : 
          'None'
      )
    },
    {
      title: 'DEPARTMENT',
      dataIndex: 'displayableTeamDepartment',
      render: (value: string, record: User) => (
        value || 'None'
      )
    },
    {
      title: 'TEAM ROLE',
      dataIndex: 'displayableTeamRole',
      render: (value: string, record: User) => (
        value || 'None'
      )
    },
    {
      title: 'AFFILIATE',
      dataIndex: 'isAffiliate',
      render: (value: User['isAffiliate']) => (value? 'Affiliate' : 'Not Affiliate')
    },
    {
      title: 'CREATED ON',
      dataIndex: 'creationTS', 
      render: (value: User['creationTS']) => moment(value).format('LLL'),
    }
  ];

  const selectTeamMembersFromTable = (selectedRows: User[]) => {
    const {role} = form.getFieldsValue();
    for(let row of selectedRows) {
      if(row.roles.includes(role)) return notification.error({message: 'Error', description: 'User already have this role'});

      if(!row.isAffiliate && role === 'issuer company officer') return notification.error({message: 'Error', description: 'User has to be an affiliate in order to assign company officer role'});
    }

    const newData = sharedService.clone(teamMembers);
    newData?.forEach(member => member['selected'] = selectedRows.map(row => row._id).includes(member._id));

    setTeamMembers(newData);
  }

  const clearTeamMemberFromTable = () => {
    const newData = sharedService.clone(teamMembers);
    newData?.forEach(member => member['selected'] = false);

    setTeamMembers(newData);
  }


  const assignAdminRoles = async() => {
    console.log("hell" + teamMembers?.find(member => member['selected']));
    if(!teamMembers?.find(member => member['selected'])) return
    console.log(JSON.stringify(teamMembers));
    const {role} = form.getFieldsValue();
    if(teamMembers.find(member => member.roles.find((_role)=> _role === role))) return notification.error({message: 'Error', description: 'User with that Admin role already exists in the company'});

    setSubmitting(true);

    const response = await adminService.assignAdminRoles({
      role, teamMembersIds:  
      teamMembers.filter((member => member['selected'])).map(member => member._id)
    });

    if (response.success) {
      notification.success({message: 'Success', description: 'Role assigned successfully'});
      history.push(`/issuer-super-admin/admin/admin-roles`);

    } else {
      notification.error({message: 'Error', description: response.error.message});
    }
    
    setSubmitting(false);
  
  }


  return (
    <>
      <br/><br/>
      <Row justify="center">
        <Col span={23}>
          <Card>
            <Title level={1} style={{textAlign:'center'}}>Assign Admin Role</Title>

            <Form labelAlign="left" form={form} initialValues={{role: 'issuer token admin'}}>
              <Form.Item
                labelCol={{xs: { span: 24 }, sm: { span: 10, offset: 0 }}}
                wrapperCol={{xs: { span: 24 }, sm: { span: 14 }}}
                label="Choose the Admin role to be assigned"
                name='role'
                rules={[
                  {
                    required: true,
                    message: 'This field is required'
                  }
                ]}>
                <Radio.Group>
                  {sharedService.adminRolesOptions2.map(role => (
                    <Radio style={radioStyle} key={role.value} value={role.value} onChange={clearTeamMemberFromTable}>
                      {role.name}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
            </Form>

            <Table
              columns={columns} 
              dataSource={form.getFieldsValue().role === 'issuer company officer'? (teamMembers as User[])?.filter(member => member.isAffiliate) :  (teamMembers as User[])}
              loading={!teamMembers}
              rowKey='_id'
              pagination={false}
              rowSelection={{
                type: 'checkbox',
                selectedRowKeys: teamMembers?.filter((member => member['selected'])).map(member => member._id),
                onChange: (selectedRowKeys, selectedRows) => {
                  console.log(selectedRowKeys);
                  selectTeamMembersFromTable(selectedRows);
                  console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                },
              }}
              footer={()=>
                <div style={{textAlign:'center'}}>
                  {teamMembers && canLoadMoreTeamMembers && 
                    <Button onClick={loadMoreTeamMembers}><PlusOutlined/>Load more</Button>
                  }
                </div>
              }
            />

            <div style={{textAlign:'right'}}>
              <Button style={{marginRight:'5px'}} htmlType='button' size='large' onClick={()=> history.push(`/issuer-super-admin/admin/admin-roles`)}>
                BACK
              </Button>
              <Button htmlType='submit' size='large' type='primary' onClick={assignAdminRoles} loading={submitting}>
                ASSIGN ADMIN ROLE
              </Button>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};