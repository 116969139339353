import React, { useState, useEffect } from 'react';
import { Typography, Form, Card, Row, Input, Select, DatePicker, Radio, Button, Modal, Col, Progress, Result, message, Descriptions, Spin ,notification} from 'antd';
import { IssuerSuperAdminService } from '../IssuerSuperAdmin.service';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import moment from 'moment';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { SharedService } from '../../Shared/Shared.service';
import { AuthService } from '../../Shared/Auth.service';
import { resolve } from 'path';
import { rejects } from 'assert';
import './memberInfo.css';
import { environment } from '../../../environments/environment';

const authService = new AuthService();
const useUserContext = () => authService.useUserContext();

const { Text, Title } = Typography;
const { Option } = Select;

const sharedService = new SharedService();
const issuerSuperAdminService = new IssuerSuperAdminService();

const AddCompany = () => {
   const [loading, setLoading] = useState(false);
   const [record, setRecord] = useState();
   const [countries, setCountries] = useState();
   const [cCountry, setCCountry] = useState(false);
   const [usaStates, setUSAStates] = useState();
  const [form] = Form.useForm();
  const { userInfo } = useUserContext();
  const [typeOfBusiness, setTypeOfBusiness] = useState(null);
  const [basicInfoFiles, setBasicInfoFiles] = useState(
    {
      companyRegFile: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      },
      taxNumberRegFile: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      },
      boardResolutionFile: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      },
    }
  );

  useEffect(() => {
      (async () => {
         const responsee = await sharedService.getUSAStates();
         setUSAStates(responsee.data);
         const countryRes = await sharedService.getCountries();
         setCountries(countryRes.data);
      })();
   }, []);

   const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      }
    };
    const disabledDate = (current) => {
      // Can not select days after today
      return current && current > moment().endOf('day');
    }

   const currentCountry = (value) => {
      if (value == 'United States of America') setCCountry(true);
      else setCCountry(false);
   }

   const submitMemberCompany = async (_form) => {
   const form = { ..._form };
   form.seriesCompanyId = userInfo.company?.id;

   form.dateOfIncorporation = new Date(form.dateOfIncorporation).getTime();
   
   form.companyRegFile = {
    name: basicInfoFiles.companyRegFile.name,
    url: basicInfoFiles.companyRegFile.URL,
  };

  form.taxNumberRegFile = {
    name: basicInfoFiles.taxNumberRegFile.name,
    url: basicInfoFiles.taxNumberRegFile.URL,
  };
   console.log(form);
   setLoading(true);

   const response = await issuerSuperAdminService.saveMemberCompany(form);

   if (response.success) {
      notification.open({
         message: 'Success',
         description: 'Success',
         duration: 0,
       });
       window.location.reload();

   } else {
      //message.error(response.error.message);
      notification.open({
        message: 'Error',
        description: response.error.message,
        duration: 0,
      });
   }

   setLoading(false);

   }

   const uploadFile = async (e, key) => {
    if (!e.target.files) return;

    const file = e.target.files[0];
    //console.log(file);

    if (!file) return;


    const fileType = sharedService.getFileType(file.name);

    setBasicInfoFiles((prev) => ({ [key]: { URL: null, ...prev[key] }, ...prev }));

    // issuerSuperAdminService.uploadFile(file, (uploadFilePercent: number) => {
    //   setBasicInfoFiles((prev) => {
    //     console.log(uploadFilePercent);
    //     return ({ ...prev, [key]: { ...prev[key], uploading: true, uploadFilePercentage: uploadFilePercent } });
    //   });

    // }, (err) => {
    //   console.error(err);
    //   setBasicInfoFiles((prev) => ({ ...prev, [key]: { ...prev[key], uploading: false } }));

    // }, async (response: APIResponse) => {

    //   if (response.success) {
    //     const URL = response.data.url;
    //     setBasicInfoFiles((prev) => ({ ...prev, [key]: { ...prev[key], URL, uploading: false, name: file.name, type: fileType.toLowerCase() } }));
    //     form.setFieldsValue({ [key]: file.name });

    //   } else {
    //     console.error(response.error);
    //     setBasicInfoFiles((prev) => ({ ...prev, [key]: { ...prev[key], uploading: false } }));
    //   }

    // });

    setBasicInfoFiles((prev) => {
      return ({ ...prev, [key]: { ...prev[key], uploading: true, uploadFilePercentage: 0 } });
    });

    try{
      const {hash} = await sharedService.uploadDocumentToIPFS({file});
      setBasicInfoFiles((prev) => ({ ...prev, [key]: { ...prev[key], URL : `${environment.ipfsDomain}/ipfs/${hash}`,
        uploading: false, name: file.name, type: fileType.toLowerCase() } }));
      form.setFieldsValue({ [key]: file.name });
    }catch(err){
      console.error(err);
      setBasicInfoFiles((prev) => ({ ...prev, [key]: { ...prev[key], uploading: false } }));
    }

  }

  const handleValuesChange = (changedValues) => {
    if (changedValues.typeOfBusiness) {
      setTypeOfBusiness(changedValues.typeOfBusiness);
    }
  };

   return (
      <>
      {loading &&  
         <div style={{textAlign:'center'}}>
           <br/>
           <Spin size='large'/>
         </div>
       }
       {!loading && (
          <>
          <Title level={1} style={{ color: '#186AB4', textAlign: 'center', marginTop:'20px' }}>Add Member Company</Title>
          <br/><br/>
          <Row justify="center">
            <Col span={16}>
              <Form {...formItemLayout} labelAlign="left" form={form} onFinish={submitMemberCompany} onValuesChange={handleValuesChange}>
                  <Card>
                     <Form.Item
                        label={`Company Name`}
                        name="companyName"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input placeholder='Enter Company Name' />
                      </Form.Item>

                      <Form.Item
                        label='Company Registered Country'
                        name="country"
                        validateStatus={countries ? '' : 'validating'}
                        hasFeedback
                        help={countries ? '' : "Loading Countries..."}
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                          }
                        ]}>
                          <Select autoClearSearchValue showSearch onChange={currentCountry} placeholder="Select Country">
                            {countries?.sort(function (a, b) { return b.name['en'].localeCompare(a.name['en']); }).map(country =>
                              <Option key={country._id} value={country.name['en']}>{country.name['en']}</Option>
                            )}
                          </Select>
                      </Form.Item>

                      {cCountry &&
                        <Form.Item
                          label='Company State'
                          name="state"
                          rules={[
                            {
                              required: true,
                              message: 'This field is required',
                              whitespace: true
                            }
                          ]}>

                          <Select showSearch placeholder="Select state"  >
                            {usaStates?.map(state =>
                              <Option key={state?.state_id} value={state.state_name}>{state.state_name}</Option>
                            )}
                          </Select>

                        </Form.Item>
                      }

                      {!cCountry &&
                        <Form.Item
                          label='Company State'
                          name="state"
                          rules={[
                            {
                              required: true,
                              message: 'This field is required',
                              whitespace: true
                            }
                          ]}>
                          <Input />
                        </Form.Item>
                      }

                     <Form.Item
                        label='Company Email'
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input placeholder='Company Email' />
                     </Form.Item>

                     <Form.Item
                        label='Company City'
                        name="city"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input placeholder='Company City' />
                      </Form.Item>

                      <Form.Item
                        label='Zip Code'
                        name="zip"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}>
                        <Input placeholder='Enter Zip Code' />
                      </Form.Item>

                      <Form.Item
                        label='Street Address 1'
                        name='address1'
                        rules={[{ required: true, message: 'Street address-1 is required' }]}>
                        <Input placeholder='Street address 1' />
                      </Form.Item>

                      <Form.Item
                        label='Street Address 2'
                        name='address2'
                        rules={[{ message: 'Street address 2 is required' }]}>
                        <Input placeholder='Street address 2' />
                      </Form.Item>
                      
                      <Form.Item 
                        label='Phone number'
                        name="phone"
                        rules={[
                          {
                            required: true,
                            message: 'Enter Phone number here',
                            whitespace: true
                          },
                          {
                            validator: (rule, value) => {
                              const strongRegex = /^[+]{0,1}[0-9\s-]{0,4}[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]{3,10}$/;
                              if(strongRegex.test(value)) return Promise.resolve();
                              return Promise.reject('Invalid phone number');
                            }
                          }
                        ]}>
                          <PhoneInput country={"us"}/>
                      </Form.Item>

                      <Form.Item
                        label='Date of Incorporation'
                        name="dateOfIncorporation"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                          }
                        ]}>
                        <DatePicker disabledDate={disabledDate} format="MMMM DD, YYYY" />
                      </Form.Item>

                      <Form.Item
                        label='Government Tax ID Number'
                        name="govTaxIdNumber"
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: false,
                          },{
                            validator: async (text,value) => {
                                if(/\D/.test(value)) return new Promise(async (resolve,reject)=> reject("This field should be a number"));
                                else return new Promise(async (resolve,reject)=> resolve(true));
                            } 
                          }
                        ]}
                      >
                        <Input type="text" placeholder='Enter Government Tax ID' maxLength={9} minLength={9} />
                      </Form.Item>

                      <Form.Item
                        label='Type Of Business'
                        name='typeOfBusiness'
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                            whitespace: true
                          }
                        ]}
                      >
                        <Select
                          placeholder="Select Type Of Business"
                        >
                          <Select.Option value="" selected disabled onValuesChange={handleValuesChange}>Select</Select.Option> 
                          {/* <Select.Option value="corporation">Corporation</Select.Option> */}
                          <Select.Option value="llc">LLC</Select.Option>
                          <Select.Option value="partnership">Partnership</Select.Option>
                          <Select.Option value="individual">Individual</Select.Option>
                          <Select.Option value="nonprofit">Non Profit</Select.Option>
                          <Select.Option value="foreigncorp">Foreign Corp</Select.Option>
                        </Select>
                      </Form.Item>
                      <br /><br />
                      <div className="ant-col ant-form-item-label ant-form-item-label-left ant-col-xs-24 ant-col-sm-8">
                        <label className="ant-form-item-required">Upload Documents</label>
                      </div>
                      <div className='custom-card'>
                      <Card style={{ display: "inline-block" }}>
                      <Form.Item
                        label="Certificate of incorporation"
                        name="companyRegFile"
                        rules={[
                          {
                            required: typeOfBusiness !== 'individual',
                            message: 'This field is required',
                          },
                        ]}
                      >
                        <>
                          <Input type="file" onChange={e => { uploadFile(e, 'companyRegFile') }} />
                          {basicInfoFiles.companyRegFile.uploadFilePercentage > 0 &&
                            <Progress percent={basicInfoFiles.companyRegFile.uploadFilePercentage} />
                          }
                            <div>
                              <a href={basicInfoFiles.companyRegFile?.URL} target="_blank" rel="noopener noreferrer">
                                {basicInfoFiles.companyRegFile?.name}
                              </a>
                            </div>
                        
                        </>
                      </Form.Item>

                      <Form.Item
                        label="Tax Number Registration Document(EIN)"
                        name="taxNumberRegFile"
                        rules={[
                          {
                            required: typeOfBusiness !== 'individual',
                            message: 'This field is required',
                          },
                        ]}
                      >
                        <>
                          <Input type="file" onChange={e => { uploadFile(e, 'taxNumberRegFile') }} />
                          {basicInfoFiles.taxNumberRegFile.uploadFilePercentage > 0 &&
                            <Progress percent={basicInfoFiles.taxNumberRegFile.uploadFilePercentage} />
                          }
                          {basicInfoFiles?.taxNumberRegFile?.URL && (
                            <div>
                              <a href={basicInfoFiles.taxNumberRegFile?.URL} target="_blank" rel="noopener noreferrer">
                                {basicInfoFiles.taxNumberRegFile?.name}
                              </a>
                            </div>
                          )}
                        </>
                      </Form.Item>
                    </Card>
                      </div>
                     <Form.Item>
                        <Button size='large' style={{ marginLeft: '300px' }} type='primary' htmlType="submit" loading={loading}>Submit</Button>
                     </Form.Item>
                     <br /><br />
                  </Card>
               </Form>
            </Col>
         </Row>
         </>
       )}
      </>
   )
}

export default AddCompany
