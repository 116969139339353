import React from 'react'
import poly1 from '../../../assets/images/investors/poly1.png';
import poly2 from '../../../assets/images/investors/poly2.png';
import poly3 from '../../../assets/images/investors/poly3.png';
import poly4 from '../../../assets/images/investors/poly4.png';
import { Menuiba,  } from '../Builder/Builder';

const investorCardsArr=[
    {
        img:poly1,
        header:'Engage & Invest',
        sub:'Register, Complete KYC & Strategically Invest in Real Estate',
        detail:`Investors embark on a journey by Registering on platform and do KYC for trusted and compliant association. Elevate your portfolio and strategic vision as Investors. Explore investment opportunities in prime properties, leveraging our platform's benefits and impactful investment decisions.`
    },
    {
        img:poly2,
        header:'Establish ',
        sub:'Create Ownership Through Tokenization',
        detail:`For Real Estate Developers and Builders, revolutionize your approach to ownership structures. Employ the innovative method of tokenization to create distinct and secure ownership units, enhancing the appeal and accessibility of your real estate assets.`
    },
    {
        img:poly3,
        header:'Facilitate',
        sub:'Effortlessly Transfer Tokenized Assets',
        detail:`Real Estate Agents and Property Management Companies, streamline your operations. Facilitate the smooth transfer of tokenized assets within your portfolio, ensuring efficiency and transparency in property transactions.`
    },
    {
        img:poly4,
        header:'Achieve',
        sub:'Attain Enhanced Profits',
        detail:`Investors, Developers, and Agents alike, realize your financial aspirations. Through strategic investments, tokenized ownership, and seamless transfers, achieve elevated profits and solidify your position in the dynamic realm of real estate.`
    },
];

const InvestorHiw = () => {
  return (
    <>
    <Menuiba/>

        <div className="container builder-container">
         <div className="row builder-row ">
            { investorCardsArr.map((card,i )=>
                 <div key={i} className="col-12 col-md-3 builder-col">
                     <span className='marker'>{i+1}
                     <span className='marker-line'></span>
                     </span>
                     <div className="builder-card-warpper">
                         <img src={card.img} alt="Engage" />
                         <div className="builder-card-info text-center">
                             <h4 style={{color:'#27AE60'}} className='mt-3 mb-2 text-success'>{card.header}</h4>
                             <span className='mb-2 text-dark' style={{fontWeight:500}}>{card.sub}</span>
                             <p className='text-dark p-3' style={{fontSize:'13px'}}>
                                 {card.detail}
                             </p>
                         </div>
                     </div>
                 </div>
            )}
         </div>
        </div>

        
        </>
  )
}

export default InvestorHiw