import React from "react";
import "../../layout/faq/faq.scss";
import FAQBanner from "../../assets/images/about-us/FAQBanner.png";

const Faq = () => {
  return (
    <>
      <div className="new-faq">

        <div className="new-faq__Banner">
          <img src={FAQBanner} style={{ width: "100%" }} alt="About Us"></img>


          <div className="new-faq__Banner-content">
            <p className="new-faq__Banner-title">
              Unlocking Wealth, One Token at a Time:
            </p>
            <span className="new-faq__Banner-title-text">Elevate Your Investments With Property Digitization</span>
          </div>


        </div>


        <div className="new-faq__accordion accordion" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                <strong>What is a Real Estate Marketplace?</strong>
              </button>
            </h2>
            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                <strong>Vruddhix</strong> offers Real Estate Tokenization Asset Platform as a marketplace for the real estate professionals to tokenize commercial real estate properties. Worldwide investors can buy into the real estate market through a fully compliant blockchain-based Real Estate Digitalization Asset Platform following all the regulations and laws in a compliant way.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                <strong>What is Asset Tokenization?</strong>
              </button>
            </h2>
            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                Asset tokenization is the process in which an issuer generates digital securities that symbolize either digital or real assets on a distributed ledger or blockchain. Every token represents a proportional share of the digital asset, implying that the token's owner has the related ownership rights or other sorts of rights.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                <strong>Who can list the properties on the marketplace?</strong>
              </button>
            </h2>
            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                The ability to advertise properties on the marketplace is shared by Real Estate Brokers, Real Estate Agents, and Property Asset Owners.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingFour">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                <strong>Who manages the property?</strong>
              </button>
            </h2>
            <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                A property management firm is a third party that strives to preserve the status of a rental property. This involves reviewing all aspects of a property's everyday operations, such as collecting rent, managing maintenance issues, dealing tenant complaints, and more.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingFive">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                <strong>How do the investors receive the tokens?</strong>
              </button>
            </h2>
            <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                Once token sell is closed, the issuer issues the tokens to the registered whitelisted wallets of the investor.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingSix">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                <strong>Can I sell my tokens?</strong>
              </button>
            </h2>
            <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                Yes, after the lock period is completed.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingSeven">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                <strong>Where can I sell my tokens?</strong>
              </button>
            </h2>
            <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                The issuer can list the tokens on the centralized exchange where the investor can sell it in the public market. Investors can also transfer or sell their tokens on an individual basis. In the future, Tokenization Asset Platform may allow investors to transfer their tokens to buyers at a fixed agreed price.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingEight">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                <strong>How can property owners and fund managers benefit from tokenization?</strong>
              </button>
            </h2>
            <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                <ul style={{ paddingLeft: "20px" }}>
                  <li type="1">Fractionalization</li>
                  <li type="1">Global Investors</li>
                  <li type="1">Liquidity for the investors</li>
                  <li type="1">Blockchain token market works 24/7</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingNine">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                <strong>The primary benefits for issuers of digital securities are</strong>
              </button>
            </h2>
            <div id="collapseNine" className="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                <ul style={{ paddingLeft: "20px" }}>
                  <li type="1">Fractionalization</li>
                  <li type="1">Global Investors</li>
                  <li type="1">Liquidity for the investors</li>
                  <li type="1">Blockchain token market works 24/7</li>
                  <li type="1">Improve investor & data management with digital reporting</li>
                  <li type="1">Create liquidity due to online trading venues & collateralizing digital assets</li>
                  <li type="1">Optimize transactions by integrating directly with service providers</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTen">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                <strong>How does tokenization provide liquidity for Real Estate Assets?</strong>
              </button>
            </h2>
            <div id="collapseTen" className="accordion-collapse collapse" aria-labelledby="headingTen" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                Creating liquidity on private assets is the end-goal and most exciting value-add of digital securities. Transforming traditional real estate equity into digital assets (security tokens) creates a transferable digital share. This allows investors to buy and sell on the Secondary Market with a wider pool of potential investors. Liquidity can also be achieved by collateralized borrowing against tokenized equities. Tokenization is an additive process; it creates new pathways for liquidity and traditional methods for capital flow are still available.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingEleven">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                <strong>How can real estate investors benefit from tokenization?</strong>
              </button>
            </h2>
            <div id="collapseEleven" className="accordion-collapse collapse" aria-labelledby="headingEleven" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                <ul style={{ paddingLeft: "20px" }}>
                  <li type="1">Increased transferability and liquidity on private investments</li>
                  <li type="1">Streamlined process for viewing holdings and analyzing asset performance</li>
                  <li type="1">Increased investment opportunities due to the ability to source investments online</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwelve">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                <strong>Can investors register their own wallets?</strong>
              </button>
            </h2>
            <div id="collapseTwelve" className="accordion-collapse collapse" aria-labelledby="headingTwelve" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                Yes, currently we offer Metamask for registering investors' wallet. In the future, we will offer additional wallets, and investors must demonstrate ownership of the wallet.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq;
