import React, { useEffect, useState } from "react";
import {
  Form,
  Row,
  Col,
  Button,
  Input,
  Select,
  message,
  Typography,
  Modal,
  DatePicker,
  Result,
  Checkbox,
  Card,
  Radio,
  InputNumber,
  Spin
} from "antd";
import { InvestorService } from "../Investor.service";
import eversign from "./Eversign";
import { SharedService } from "../../Shared/Shared.service";
import BigNumber from "bignumber.js";

const investorService = new InvestorService();

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 8,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

export default ({ token, userInfo, onBack,offeringDetails,remainingShareCount }) => {
  const [creditCardSelected, setCreditCardSelected] = useState(false);
  const [achSelected, setAchSelected] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [signURL, setSignURL] = useState(null);
  const [paymentData, setPaymentData] = useState(null);
  const [paymentForm] = Form.useForm();
  const [paymentDone, setPaymentDone] = useState(false);
  const [amountStatus, setAmountStatus] = useState(false);
  const [tokenPhaseDetail, setTokenPhaseDetail] = useState([]);
  const [totalToken, setTotalToken] = useState();
  let tokens = 0;


  useEffect(() => {
    paymentForm.setFieldsValue({
      firstName: `${userInfo?.firstName}`,
      lastName: `${userInfo?.lastName}`,
      email: `${userInfo?.email}`,
      phoneNumber: `${userInfo?.contactNumber}`,
      state: `${userInfo?.state}`,
      country: `${userInfo?.country}`,
      zipCode: `${userInfo?.zipCode}`,
      northCapitalInvestorId: `${userInfo?.northCapitalInvestorId}`
    });

    if (userInfo?.paymentDone) {
      setPaymentDone(true)
    }

  }, [paymentForm, userInfo]);

  console.log(userInfo)

  const handleTokenCount = async(event) => {
    let amount = Number(event.target.value.trim().replace(/,/g, ""));
    console.log("Amount || token", event.target.value.trim(), amount, 0);
    let bonus = 0;
    tokens = Number(
      (amount / token?.pricePerToken) * 100
    );

    for (const data of tokenPhaseDetail) {
      if (
        data.minInvestmentAmount.min < amount &&
        amount < data.minInvestmentAmount.max
      ) {
        bonus = data.bonusPercent;
        if (bonus > 0) tokens = tokens + tokens * (bonus / 100);
      }
    }
    tokens = Math.round(tokens);
    let value = String(tokens);
    console.log("Amount || token", amount, value);
    
    const hasError = await paymentForm.validateFields(['amountToInvest'])
    if(hasError.errorFields?.length || tokens > Number(remainingShareCount)){
      if(tokens > Number(remainingShareCount)) message.info('Not enough shares available');
      paymentForm.setFieldsValue({ no_of_tokens: null });
      setTotalToken(null)
      return;
    }
    
    paymentForm.setFieldsValue({ no_of_tokens: tokens });
    setTotalToken(tokens)

  };


  const fetchSignUrl = async formData => {
    console.log(formData)
    setPaymentData(formData)
    try {
      const noOfTokens = totalToken
      const data = { ...userInfo, noOfTokens }
      const response = await investorService.getInvestSignURL(data)
      await setSignURL(response.data);
    } catch (e) {
      console.log(e)
      message.error("Something Went Wrong")
    }
  }

  const onPaymentMethodChange = (e) => {
    if (e.target.value == "creditCard") {
      setCreditCardSelected(true);
      setAchSelected(false);

      // Clearing the input if credit card selected
      paymentForm.setFieldsValue({
        accountName: undefined,
        accountNumber: undefined,
        routingNumber: undefined,
        bankAddress: undefined,
        accountType: undefined,
        checkType: undefined,
      });

    } else if (e.target.value == "ach") {
      setAchSelected(true);
      setCreditCardSelected(false);

      // Clearing the input if ach selected
      paymentForm.setFieldsValue({
        creditCardDetail: undefined,
        creditCardNumber: undefined,
        creditCardExpiration: undefined,
        creditCardCVV: undefined,
        cardType: undefined,
        billingName: undefined,
        billingPostalCode: undefined,
      });
    } else {
      setCreditCardSelected(false);
      setAchSelected(false);
    }
  };

  const makePaymentUtil = async (formData) => {
    console.log(formData)
    setPaymentData(formData)
    let data = formData;
    setSubmitting(true);
    data.no_of_tokens = Number(data?.no_of_tokens);
    data.amountToInvest = Number(data?.amountToInvest.replace(/,/g, ""));
    data.firstName = userInfo?.firstName
    data.lastName = userInfo?.lastName
    data.northCapitalInvestorId = userInfo?.northCapitalInvestorId;
    data.email = userInfo?.email;
    data.investorId = userInfo?._id;
    data.company = token?.issuerInfo[
      "0"
    ].companyName.toLowerCase();
    data.token = token;
    data.accept = false;
    data.reject = false;
    data.creationTS = await Date.now();

    console.log(JSON.stringify(data));

    try {
      const response = await investorService.northCapitalWireTransferPaymentDetails(data);
      if (response.success || response.data) {
        console.log(response.data);
        paymentForm.resetFields();
        setPaymentDone(true);
        const eversignDiv = document.getElementById("embedInvestSignDivisionOther");
        //eversignDiv?.innerHTML = '';
        setSubmitting(false);
        message.success("Payment details succesfully saved");
      } else {
        message.error("Something went wrong please try again");
        const eversignDiv = document.getElementById("embedInvestSignDivisionOther");
        //eversignDiv.innerHTML = '';
        setSubmitting(false);
      }
      setSubmitting(false);
    } catch (e) {
      console.log(e);
      message.error("Something went wrong please try again");
      const eversignDiv = document.getElementById("embedInvestSignDivisionOther");
      //eversignDiv?.innerHTML = '';
      setSubmitting(false);
    }
  };

  useEffect(() => {
    (async () => {
      const tokenResponse = await investorService.getTokenPhaseDetail({
        companyId: token?.issuerInfo[0].companyId,
      });
      if (tokenResponse.success && tokenResponse.data) {
        setTokenPhaseDetail(tokenResponse.data.bonusOffers);
      } else setTokenPhaseDetail([]);
    })();
  }, []);

  return (
    <>
      {submitting && (<Spin size="large" style={{marginLeft: '45%', marginTop: '5%'}}/>)}
      {!submitting && (<>
      {!(paymentDone || paymentData !== null) &&
        <Card style={{ width: "100%", margin: "auto" }}>
          <Form
            {...formItemLayout}
            layout="vertical"
            title="Enter Following details to proceed:"
            form={paymentForm}
            onFinish={makePaymentUtil}
          >
            <p
              style={{
                fontWeight: "bold",
                fontSize: "25px",
                textAlign: "left",
              }}
            >
              Investment Information
            </p>

            <Row gutter={10}>
              <Col span={12}>
              <Form.Item
                label="First name"
                name="firstName"
                labelCol={{span: 12}}
                wrapperCol={{span: 24}}
                rules={[
                  {
                    required: true,
                    message: "Please input your first name!",
                    whitespace: true,
                  },
                ]}
              >
                <Input style={{ color: "green" }} disabled />
              </Form.Item>
              </Col>
              <Col span={12}>
              <Form.Item
                label="Last name"
                name="lastName"
                labelCol={{span: 12}}
                wrapperCol={{span: 24}}
                rules={[
                  {
                    required: true,
                    message: "Please input your last name!",
                    whitespace: true,
                  },
                ]}
              >
                <Input style={{ color: "green" }} disabled />
              </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label="E-mail"
              name="email"
              style={{ width: "70%" }}
              rules={[
                {
                  required: true,
                  message: "Please input your E-mail",
                  whitespace: true,
                },
              ]}
            >
              <Input style={{ color: "green" }} disabled />
            </Form.Item>
            <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                label="Amount to Invest"
                name="amountToInvest"
                style={{ width: "100%" }}
                labelCol={{span: 12}}
                wrapperCol={{span: 24}}
                normalize={(value, prevValue, allValues) => {
                  const _value = value.replace(/,/g, "");
                  console.log(value,prevValue,allValues)
                  console.log(offeringDetails)
                  
                  if (new BigNumber(_value).isGreaterThan(0))
                    return new BigNumber(_value).toFormat(0);
                  if (!_value) return "";
                  return prevValue || "";
                }}
                rules={[
                  {
                    required: true,
                    message: "Please input amount to raise!",
                  },
                  {
                    validator: (_, value) => {
                      const minAmount = Number(offeringDetails.minInvestmentAmount);
                      const maxAmount = Number(offeringDetails.maxInvestmentAmount);
                      const incrementAmount = Number(offeringDetails.investmentIncrementAmount);
                      const numericValue = Number(value.replace(/,/g, ''));

                      if(!numericValue || isNaN(numericValue)) return

                      if (numericValue < minAmount || numericValue > maxAmount) {
                        return Promise.reject(new Error(`The min and max investment amount is ${minAmount} and ${maxAmount}`));
                      }
                
                      console.error(numericValue, incrementAmount)
                      console.error(numericValue % incrementAmount)
                      if (numericValue % incrementAmount !== 0) {
                        if(numericValue % incrementAmount !== minAmount){
                          return Promise.reject(new Error(`Investment amount should be an increment of ${incrementAmount}`));
                        }
                      }
                
                      return Promise.resolve();
                    },
                  },
                ]}
                
              >
                <Input
                  disabled={amountStatus}
                  onChange={handleTokenCount}
                  addonAfter="USD"
                />
              </Form.Item>
              </Col>
              <Col span={12}>
              <Form.Item
                label="# of tokens"
                name="no_of_tokens"
                style={{ width: "100%" }}
                labelCol={{span: 12}}
                wrapperCol={{span: 24}}
                rules={[
                  {
                    required: true,
                    message: "Enter the number of shares",
                  },
                ]}
              >
                <Input type="number" style={{ color: "#3d61ff" }} disabled />
              </Form.Item>
              </Col>
            </Row>

            <Form.Item
              label="Mode of fund transfering :"
              name="modeOfFundTransfer"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Col style={{ textAlign: "left" }}>
                <Radio.Group onChange={onPaymentMethodChange}>
                  {token?.regulation !== "regulation-s" && (
                    <>
                      <Radio key="ach" value={"ach"}>
                        ACH(Electronic Check)-Bank Account Only
                      </Radio>
                    </>
                  )}
                  <br />
                  <Radio key="creditCard" value={"creditCard"}>
                    Credit Card
                  </Radio>
                  <br />
                </Radio.Group>
              </Col>
            </Form.Item>


            <Form.Item
              label="Credit card details"
              name="creditCardDetail"
              labelCol={{span: 24}}
              wrapperCol={{span: 24}}
              hidden={!creditCardSelected}
              rules={[
                {
                  required: creditCardSelected,
                },
              ]}
            >

                <Row>
                  <Card style={{width:"100%"}}>
                    <Row gutter={10}>
                      <Col span={10}>
                        <Form.Item
                          label="Card Number"
                          name="creditCardNumber"
                          labelCol={{span: 12}}
                          wrapperCol={{span: 24}}
                        >
                          <Input maxLength={16} />
                        </Form.Item>
                      </Col>
                      <Col span={10}>
                        <Form.Item
                          label="Card Expiration"
                          name="creditCardExpiration"
                          labelCol={{span: 12}}
                          wrapperCol={{span: 24}}
                        >
                          <DatePicker style={{width:'100%'}} picker="month" />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item label="CVV"
                        name="creditCardCVV"
                        labelCol={{span: 12}}
                        wrapperCol={{span: 24}}
                        >
                          <InputNumber style={{width:'100%'}} maxLength={3} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item
                      label="Card Type"
                      name="cardType"
                      labelCol={{span: 24}}
                       wrapperCol={{span: 24}}
                    >
                      <Col style={{ textAlign: "left" }}>
                        <Radio.Group>
                          <Radio key="MC" value={"MC"}>
                            MasterCard
                          </Radio>
                          <Radio key="VI" value={"VI"}>
                            Visa
                          </Radio>
                          <br />
                        </Radio.Group>
                      </Col>
                    </Form.Item>

                    <Row gutter={10}>
                      <Col span={16}>
                        <Form.Item
                          label="Billing Name"
                          name="billingName"
                          labelCol={{span:24}}
                          wrapperCol={{span: 24}}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          label="Billing postal code"
                          name="billingPostalCode"
                          labelCol={{span:24}}
                          wrapperCol={{span: 24}}
                        >
                          <InputNumber style={{width:'100%'}} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </Row>
              
            </Form.Item>


            <Form.Item
              label="Account details for ACH mode of payment"
              name="accountDetails"
              labelCol={{span: 24}}
              wrapperCol={{span: 24}}
              hidden={!achSelected}
              rules={[
                {
                  required: achSelected,
                },
              ]}
            >
              <Row>
                  <Card style={{width:'100%'}}>
                    <Row>
                      <Col span={12}>
                        <Form.Item label="Name on Account" name="accountName">
                          <Input />
                        </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={10}>
                      <Col span={12}>
                        <Form.Item label="Account Number" name="accountNumber">
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="Routing Number" name="routingNumber">
                          <Input maxLength={9} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item label="Bank address" name="bankAddress">
                      <Input />
                    </Form.Item>

                    <Row gutter={10}>
                      <Col span={12}>
                        <Form.Item label="Account Type" name="accountType">
                          <Col style={{ textAlign: "left" }}>
                            <Radio.Group>
                              <Radio key="saving" value={"saving"}>
                                Saving
                              </Radio>
                              <br />
                              <Radio key="checking" value={"checking"}>
                                Checking
                              </Radio>
                              <br />
                            </Radio.Group>
                          </Col>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="Check type" name="checkType">
                          <Col style={{ textAlign: "left" }}>
                            <Radio.Group>
                              <Radio key="business" value={"business"}>
                                Business
                              </Radio>
                              <br />
                              <Radio key="personal" value={"personal"}>
                                Personal
                              </Radio>
                              <br />
                            </Radio.Group>
                          </Col>
                        </Form.Item>
                      </Col>
                    </Row>

                  </Card>
              </Row>

            </Form.Item>

            {token?.regulation !== "regulation-s" && (
              <>
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "25px",
                    textAlign: "left",
                  }}
                >
                  Investor Questionnaire
                </p>
                <p
                  style={{
                    fontStyle: "oblique",
                    fontWeight: "bold",
                    textAlign: "justify",
                  }}
                >
                  This information is needed for us to comply with SEC and
                  stable securities regulations.We ask the following questions
                  to determine if the amount if the amount you may invest is
                  limited by law.
                </p>
                {/* <br />
                <p
                  style={{
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  Are you an "accrediated" investor (i.e do you earn over
                  $200,000 per year, have a net worth of $1M or more ,or are
                  an indivisual investor)?
                </p>
                <Form.Item
                  label=""
                  name="accrediated"
                  style={{ fontSize: "25px" }}
                  labelCol={{ span: 24, offset: 4 }}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Col style={{ textAlign: "left" }}>
                    <Radio.Group>
                      <Radio key="yes" value={true}>
                        Yes
                      </Radio>
                      <Radio key="no" value={false}>
                        No
                      </Radio>
                    </Radio.Group>
                  </Col>
                </Form.Item>
                <p
                  style={{
                    fontStyle: "oblique",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  Are you an "accrediated investor" the law limits the total
                  amount you can invest based on your annual income and your
                  net worth. Please provide these so that we may determine if
                  the amount you wish to invest is within these limitation.
                </p>
                <Form.Item
                  label="Annual Income"
                  name="annualIncome"
                  rules={[
                    {
                      required: true,
                      message: "Enter your Annual Income !!",
                    },
                  ]}
                >
                  <Input addonAfter="USD" />
                </Form.Item>
                <Form.Item
                  label="Net Worth"
                  name="netWorth"
                  rules={[
                    {
                      required: true,
                      message: "Enter the net worth of your income !!",
                    },
                  ]}
                >
                  <Input addonAfter="USD" />
                </Form.Item> */}
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "25px",
                    textAlign: "left",
                  }}
                >
                  Substitute Form W-9 Statement :
                </p>
                <p
                  style={{
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  <sub style={{ color: "red", fontSize: "22px" }}>*</sub>
                  Under penality of perjury, by accepting the aggrement below
                  I certify that i have provided my correct tax payer
                  identification number and,
                </p>
                <Form.Item
                  label=""
                  name="usCitizen"
                  style={{ fontSize: "25px" }}
                  labelCol={{ span: 26, offset: 4 }}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Col
                    style={{
                      textAlign: "left",
                      padding: "0px",
                      margin: "0px",
                    }}
                  >
                    <Radio.Group>
                      <Radio key="yes" value={"yes"}>
                        I am US citizen
                      </Radio>
                      <Radio key="no" value={"no"}>
                        I am not a US citizen
                      </Radio>
                    </Radio.Group>
                  </Col>
                </Form.Item>
                <p style={{ textAlign: "left", fontWeight: "bold" }}>
                  <sub style={{ color: "red", fontSize: "22px" }}>*</sub>
                  And :
                </p>
                <Form.Item
                  label=""
                  name="withHolding"
                  labelCol={{ span: 26, offset: 1 }}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Col style={{ textAlign: "left" }}>
                    <Radio.Group>
                      <Radio key="yes" value={"yes"}>
                        I am exempt from backup withHolding
                      </Radio>
                      <Radio
                        key="no"
                        value={"no"}
                        style={{ textAlign: "justify" }}
                      >
                        I am a subject to backup withHolding
                      </Radio>
                      <p>
                        (Only check this option when you've been notified by
                        the IRS that you are subject to backup withHolding)
                      </p>
                    </Radio.Group>
                  </Col>
                </Form.Item>
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "25px",
                    textAlign: "justify",
                  }}
                >
                  Confirm Investor Accrediation :
                </p>
                <p style={{ fontWeight: "bold", textAlign: "justify" }}>
                  The SEC requires that all investors in 506(c) offerings be
                  verified as to their accrediated investor status. The Issuer
                  (Ultimate Test LLC) or selected service will reach out to
                  confirm your accrediation status. Per SEC Regulations you
                  will not be able to finalize your investment if you do not
                  completes this accrediated investor process.
                </p>
                <Form.Item
                  label=""
                  name="investorAccrediationConfirm"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Col style={{ textAlign: "left" }}>
                    <Radio.Group>
                      <Radio key="yes" value={"yes"}>
                        Yes
                      </Radio>
                      <Radio key="no" value={"no"}>
                        No
                      </Radio>
                    </Radio.Group>
                  </Col>
                </Form.Item>
              </>
            )}
            <Form.Item {...tailFormItemLayout}>
              <Button type="primary" htmlType="submit" loading={submitting}>
                Save
              </Button>
            </Form.Item>
          </Form>
        </Card>
      }
      <div hidden={!paymentDone}>
        <Result
          status="success"
          title="Payment Completed"
          extra={[
            <Button
              type="primary"
              key="console"
              onClick={() => {
                onBack();
              }}
            >
              Home
            </Button>,
          ]}
        ></Result>
      </div>
      <div hidden={paymentDone} id="embedInvestSignDivisionOther">
        <>
          {signURL !== null && eversign.open({
            url: signURL,
            containerID: "embedInvestSignDivisionOther",
            width: 750,
            height: 800,
            events: {
              loaded: () => console.log("load hogya"),
              signed: () => makePaymentUtil(paymentData),
              error: () => console.log("EVERSIGN ERROR"),
              declined: () => {
                message.error("Payment Declined");
                setPaymentData(null);
                window.location.reload();
              },
            },
          })}
        </>
      </div>
      </>)}
    </>
  );
};
