import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Typography, Form, Input, Button, notification, Spin, Steps, Descriptions, Modal, Radio} from 'antd';
import { CheckOutlined, LineOutlined, PlusOutlined } from '@ant-design/icons';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { AuthService } from '../../Shared/Auth.service';
import { IssuerSuperAdminService } from '../../IssuerSuperAdmin/IssuerSuperAdmin.service';
import { SharedService } from '../../Shared/Shared.service';
import AddBasicInfo from './AddBasicInfo.js';
import AdditionalDetails from './AdditionalDetails.js';
import AddDocuments from './AddDocuments.js';
import OfferingInformation from './OfferingInformation.js';
const {Title} = Typography;
const { Step } = Steps;

const issuerSuperAdminService = new IssuerSuperAdminService();
const sharedService = new SharedService();

const configTypeToCurrentStep = {
  'basic-information': 1,
  'Offering-information': 2,
  'additional-details': 3,
  'documents': 4
};
const currentStepToConfigType = Object.fromEntries(
  Object.entries(configTypeToCurrentStep).map((entry) => entry.reverse())
);

const useUserContext = () => new AuthService().useUserContext();

export default function AddProperty() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const { userInfo } = useUserContext();
  const [stepNo, setStepNo] = useState(1);
  const [updated, setUpdated] = useState(false);
  const [propertyInfo, setPropertyInfo] = useState();
  const [basicInfoDone, setBasicInfoDone] = useState(false);
  const [offeringDisabled, setOfferingDisabled] = useState(true);
  const [offeringDone, setOfferingDone] = useState(false);
  const [additionalDisabled, setAdditionalDisabled] = useState(true);
  const [additionalDetailsDone, setAdditionalDetailsDone] = useState(false);
  const [documentsDisabled, setDocumentsDisabled] = useState(true);
  const [documentsDone, setDocumentsDone] = useState(false);
  const [disableEdit, setDisableEdit] = useState(false);
  const [propertyType, setPropertyType] = useState('commercial');
  const [visible, setVisible] = useState(false);
  const [noOptionChoosedByUser, setNooptionChoosedByUser] = useState(true);

  useEffect(() => {
    (async () => {
      setLoading(true);
      let companyId = userInfo?.company?.id

      if(userInfo?.role == 'master company super admin'){
        const id =sessionStorage.getItem('companyId')
        if(id){
          companyId = id
        }
      }

      let res = await issuerSuperAdminService.getPropertyDetails(companyId);
      if(res.data){
        setPropertyInfo(res.data[0]);
        if(res.data[0]){
          if(isNaN(res.data[0].stepDone)){
            setStepNo(1);
          }else{
            setStepNo(res.data[0].stepDone + 1);
          }
          if(res.data[0].basicInfo=='done'){
            setBasicInfoDone(true);
            setOfferingDisabled(false);
          }
          if(res.data[0].offering=='done'){
            setOfferingDone(true);
            setAdditionalDisabled(false);
          }
          if(res.data[0].additionalDetails=='done'){
            setAdditionalDetailsDone(true);
            setDocumentsDisabled(false);
          }
          if(res.data[0].propertyDocuments=='done'){
            setDocumentsDone(true);
          }
        }
      }
      setLoaded(true);
      setLoading(false);
    })();
  }, [updated]);

  useEffect(()=>{
    if(userInfo?.role != 'issuer company manager'){
      setDisableEdit(true)
    }else{
      setDisableEdit(false)
    }
  },[userInfo])

  useEffect(() => {
    let redirectConfigType: string | null = null;

    if (stepNo == 1) {
       redirectConfigType = 'basic-information';
    } else if (stepNo === 2) {
      redirectConfigType = 'offering-information';
    } else if (stepNo === 3) {
      redirectConfigType = 'additional-details';
    }else {
      redirectConfigType = 'documents';
    }
    if (!redirectConfigType) return;
    history.push(
      `/issuer-super-admin/add-property/${redirectConfigType}`
    );
  }, [stepNo]);


  const handleOk = () => {
    setVisible(false);
    try {
      localStorage.setItem('propertyType', propertyType);
    } catch (error) {
      throw new Error('local storage unavailable ' + error);
    }
  }

  const handleCancel = () => {
    setVisible(false);
  }
  return (
    <>
    {loading && 
      <div style={{textAlign:'center'}}>
        <br/>
        <Spin size='large'/>
      </div>}
      <br/>
      {!loading && loaded && (
        <Card>
          <Row justify="center" style={{ marginBottom: '50px' }}>
            <Col span={24}>
            {userInfo?.role === 'issuer company manager' && (
            <Title level={1} style={{ color: '#186AB4', textAlign: 'center' }}>
                Add Property Page
              </Title>
            )}
            

                <div style={{ textAlign:"center", marginBottom:"10px"}}>
  
                  <div style={{marginBottom:"15px"}}>Property Type : {propertyType?.toUpperCase()}</div>
               
                { userInfo?.role === 'issuer company manager' && <Button type="primary" icon={<PlusOutlined />}
                  onClick={() => { setVisible(true); setNooptionChoosedByUser(true); }}
                  >
                  Select Property Type
                </Button>}
                  </div>


              <Steps
                type="navigation"
                current={stepNo-1}
              >
                <Step
                  status={
                    stepNo>1 ? 'finish' : undefined
                  }
                  title="Basic Information"
                  onClick={()=>{
                    setStepNo(1);
                    history.push(
                      '/issuer-super-admin/add-property/basic-information'
                    )}}
                    style={{cursor:'pointer'}}
                ></Step>
                <Step
                  disabled={offeringDisabled}
                  status={
                    stepNo>2
                      ? 'finish'
                      : undefined
                  }
                  title="Offering Information"
                  onClick={()=>{
                    setStepNo(2);
                    history.push(
                      '/issuer-super-admin/add-property/offering-information'
                    )}}
                    style={{cursor:`${!offeringDisabled?'pointer':'not-allowed'}`}}
                ></Step>
                <Step
                  disabled={additionalDisabled}
                  status={
                    stepNo>3
                      ? 'finish'
                      : undefined
                  }
                  title="Additional Details"
                  onClick={()=>{
                    setStepNo(3);
                    history.push(
                      '/issuer-super-admin/add-property/additional-details'
                    )}}
                    style={{cursor:`${!additionalDisabled?'pointer':'not-allowed'}`}}
                ></Step>
                <Step
                  disabled={documentsDisabled}
                  status={
                    stepNo>4
                      ? 'finish'
                      : undefined
                  }
                  onClick={()=>{
                    setStepNo(4);
                    history.push(
                      '/issuer-super-admin/add-property/documents'
                    )}}
                    style={{cursor:`${!documentsDisabled?'pointer':'not-allowed'}`}}
                  title="Documents"
                ></Step>
              </Steps>
              {stepNo == 1 && loaded && (
                <AddBasicInfo
                  userInfo={userInfo}
                  propertyInfo={propertyInfo}
                  setStepNo={setStepNo}
                  setUpdated={setUpdated}
                  basicInfoDone={basicInfoDone}
                  disableEdit={disableEdit}
                  propertyType={propertyType}
                />
              )}
              {stepNo == 2 && loaded && (
                <OfferingInformation
                  userInfo={userInfo}
                  propertyInfo={propertyInfo}
                  setStepNo={setStepNo}
                  setUpdated={setUpdated}
                  offeringDone={offeringDone}
                  disableEdit={disableEdit}
                  propertyType={propertyType}
                />
              )}
              {stepNo == 3 && loaded && (
                <AdditionalDetails
                  userInfo={userInfo}
                  propertyInfo={propertyInfo}
                  setStepNo={setStepNo}
                  setUpdated={setUpdated}
                  additionalDetailsDone={additionalDetailsDone}
                  disableEdit={disableEdit}
                  propertyType={propertyType}
                />
              )}
              {stepNo >= 4 && loaded && (
                <AddDocuments 
                  userInfo={userInfo}
                  propertyInfo={propertyInfo}
                  setStepNo={setStepNo}
                  setUpdated={setUpdated}
                  documentsDone={documentsDone}
                  disableEdit={disableEdit}
                  propertyType={propertyType}
                />
              )}

              <Modal
                title="Select Type of Property"
                visible={visible && noOptionChoosedByUser}
                onOk={handleOk}
                okText="Save"
                onCancel={handleCancel}
                closable={false}
                mask={true}
                maskClosable={false}
              >
                <Radio.Group onChange={(e) => setPropertyType(e.target.value)} value={propertyType}>
                  <Radio value="commercial">Commercial</Radio><br />
                  <Radio value="farm">Farm</Radio>
                </Radio.Group>
              </Modal>
            </Col>
          </Row>
        </Card>
      )}
    </>
  );
}