import React, { useEffect, useState, useRef } from "react";
import {Table,Card,Col,Row,Typography, Descriptions,Button,Form, Radio, Input,Progress, message,Tabs,List,Carousel} from 'antd'
import {PlatformComplianceAdminService} from '../PlatformComplianceAdmin.service'
import { environment } from "../../../environments/environment";
import { formatPhoneNumberIntl } from "react-phone-number-input";

import {
  RightCircleOutlined,
  LeftCircleOutlined
} from '@ant-design/icons'
import moment from 'moment'
import { PlaySquareOutlined, ProjectOutlined } from '@ant-design/icons';
import { IssuerComplianceAdminService } from "../../IssuerComplianceAdmin/IssuerComplianceAdmin.service";
import { AuthService } from "../../Shared/Auth.service";
const {Title}=Typography
const platformComplianceAdminService=new PlatformComplianceAdminService();
const issuerComplianceAdminService = new IssuerComplianceAdminService();
const authService = new AuthService();
const useUserContext = () => authService.useUserContext();

const { TextArea } = Input;
const {TabPane}=Tabs;
const displayOptions = {
  AfterRejectDivision: "2",
  MainDivision: "1",
};
const subDisplayOptions = {
  RejectView: "1",
  ResubmitView: "2",
};



export default ()=>{
  const [investors,setInvestors]=useState()
  const [detailsDivision,setDetailsDivision]=useState(true)
  const [detailsDivision1,setDetailsDivision1]=useState(true)
  const [investorDetails,setInvestorDetails]=useState()
  const [submitting,setSubmitting]=useState(false)
  const [filteredInvestor,setFilteredInvestor]=useState(investors)
  const [issuers,setIssuers]=useState(null);
  const { userInfo, setUserInfo } = useUserContext();
  const [form]=Form.useForm();
  const [documents,setDocuments]=useState([])
  const [approvedAndRejectedKYCRequests,setInvestorsList]=useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingApprovedRejected, setLoadingApprovedRejected] = useState(false);
  const [displayOption, setDisplayOption] = useState(
    displayOptions.MainDivision
  );

  const [subDisplayOption, setSubDisplayOption] = useState(
    subDisplayOptions.ResubmitView
  );

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    total: 0,
  });
  const [paginationApprovedRejected, setPaginationApprovedRejected] = useState({
    current: 1,
    pageSize: 5,
    total: 0,
  });
  const [KYCResultFile,setKYCResult] = useState(
    {
      KYCResultFile: {
        uploading: false,
        uploadFilePercentage: 0,
        URL: null,
        name: null,
        type: null
      },
    }
  );


  let carousel = useRef(null);
  const resubmitRadioRef = useRef(null);
  const rejectRadioRef = useRef(null);
  const textBoxRef = useRef(null);

  const card = {
    height: "400px",
    backgroundColor: "#ebeff5"
  };

  const iconCard = {
    height: "400px"
  };

  const image = {
    height: "100%",
    width: "100%",
    borderRadius: "10px"
  };

  const p = {
    fontSize: "25px",

  };

  const icon = {
    fontSize: "25px",
    margin: 0,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",

  };

  const imageDiv = {
    backgroundColor: "#ebeff5",
    width: "50%",
    margin: "auto",
    height: "285px"
  };


  const columns=[
    {
      title:'FIRST NAME',
      dataIndex:'firstName',
      key:'firstName'
    },
    {
      title:'LAST NAME',
      dataIndex:'lastName',
      key:'lastName'
    },
    {
      title:'EMAIL',
      dataIndex:'email',
      key:'email'
    },
    {
      title:'CREATED ON',
      dataIndex:'dateOfSubmission',
      key:'creationTS',
      render:text=>moment(text).format('LLL')
    },
    {
      title:'ACTION',
      dataIndex:'action',
      key:'action',
      render:(text,investor)=><Button type='primary' onClick={()=>showDetails(investor)}>DETAILS</Button>
    },

  ]
  const columns2=[
    {
      title:'FIRST NAME',
      dataIndex:'firstName',
      key:'firstName'
    },
    {
      title:'LAST NAME',
      dataIndex:'lastName',
      key:'lastName'
    },
    {
      title:'EMAIL',
      dataIndex:'email',
      key:'email'
    },
    {
      title:'PHONE',
      dataIndex:'contactNumber',
      key:'contactNumber'
    },
    {
      title:'CREATED ON',
      dataIndex:'dateOfSubmission',
      key:'creationTS',
      render:text=>moment(text).format('LLL')
    },
    {
      title:'Status',
      dataIndex:'action',
      key:'action',
      render:(text,investor)=>{
        return(
          <>
          {(investor.approvedByICA && investor.approved)&& <span style={{color:'Green'}} >Approved</span>}
          {investor.rejected && <span style={{color:'red'}} >rejected</span>}
          {(!investor.approvedByICA && investor.approved) && <span style={{color:'Green'}} >Approved</span>}
          {/* {(!investor.approvedByICA && investor.approved) && <span style={{color:'orange'}} >Approval pending from ICA</span>} */}
          </>
        )
      }
    },
    {
      title:'ACTION',
      dataIndex:'action',
      key:'action',
      render:(text,investor)=><Button type='primary' onClick={()=>showDetailsForResult(investor)}>DETAILS</Button>
    }

  ]
  const showDetails=investor=>{
    setDetailsDivision(false)
    setInvestorDetails(investor)
  }
  const showDetailsForResult=investor=>{
    setDetailsDivision1(false)
    setInvestorDetails(investor)
  }
  const rejectKYC=(details)=>{
    (async ()=>{
      setSubmitting(true);
      delete details._id;
      details.approved=false
      details.rejected=true
      const response=await platformComplianceAdminService.updateKYC(details);
      if(response.success)
      {
        const inv=investors.filter(investor=>investorDetails._id!==investor._id);
        setInvestors(inv);
        setFilteredInvestor(inv);
        setKYCResult({
          KYCResultFile: {
            uploading: false,
            uploadFilePercentage: 0,
            URL: null,
            name: null,
            type: null
          },
        });
        setDocuments([]);
        setDetailsDivision(true)
      }
      setSubmitting(false)
    })();
  }
  const acceptKYC=(details)=>{
    (async ()=>{
      setSubmitting(true);
      delete details._id;
      details.approved=true
      details.rejected=false
      const response=await platformComplianceAdminService.updateKYC(details);
      if(response.success)
      {
        const inv=investors.filter(investor=>investorDetails._id!==investor._id);
        setInvestors(inv);
        setFilteredInvestor(inv);
        setKYCResult({
          KYCResultFile: {
            uploading: false,
            uploadFilePercentage: 0,
            URL: null,
            name: null,
            type: null
          },
        });
        setDocuments([]);
        setDetailsDivision(true);
        setTimeout(()=> window.location.reload(), 1000);
      }
      setSubmitting(false)
    })();
  }


  useEffect(() => {
    getInvestorKYCRequestList(pagination.current, pagination.pageSize);
  }, [pagination.current, pagination.pageSize]);

  const getInvestorKYCRequestList = async (page, pageSize) => {
    setLoading(true);
    const response = await platformComplianceAdminService.getInvestorKYCRequests(page, pageSize);
    if (response.success && response?.data?.questions) {
      setInvestors(response?.data?.questions);
      setFilteredInvestor(response?.data?.questions);
      setPagination({
        ...pagination,
        total: response?.data?.totalCount,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    getApprovedAndRejectedKYCRequests(paginationApprovedRejected.current, paginationApprovedRejected.pageSize);
  }, [paginationApprovedRejected.current, paginationApprovedRejected.pageSize]);
  
  const getApprovedAndRejectedKYCRequests = async (page, pageSize) => {
    setLoadingApprovedRejected(true);
    const response = await platformComplianceAdminService.getInvestorsList(page, pageSize);
    if (response.success && response?.data?.investors) {
      setInvestorsList(response?.data?.investors);
      setPaginationApprovedRejected({
        ...paginationApprovedRejected,
        total: response?.data?.totalCount,
      });
    }
    setLoadingApprovedRejected(false);
  };

  const proceedToRejectWithCheck = () => {
    console.log(resubmitRadioRef);
    if (resubmitRadioRef?.current?.state?.checked) {
      resubmitKYC();
      return;
    } else {
      if (!textBoxRef.current.resizableTextArea.textArea.value) {
        message.error("Please provide the reason for rejection.");
        return;
      }
      investorDetails.reasonToReject = textBoxRef.current.resizableTextArea.textArea.value.trim();
      rejectKYC(investorDetails);
    }
  };

  const resubmitKYC = async () => {
    investorDetails.resubmit = true;
    const response = await issuerComplianceAdminService.resubmitKYC(
      investorDetails
    );
    if (response.success) {
      setDetailsDivision(true);
    } else {
      message.error(response.error);
      return;
    }
  };
  

const changeView = (e) => {
  if (e.target.value === 1)
    setSubDisplayOption(subDisplayOptions.ResubmitView);
  else setSubDisplayOption(subDisplayOptions.RejectView);
};


  return(
  <>
   <br/>
     <Row justify="center">
      <Col span={22} style={{textAlign:'center'}}>
        <Card>
          <Title
            level={2}
            style={{
              textAlign: 'left',
              color: '#1890ff',
              // fontSize: '30px',
              fontWeight: 'bold'
            }}
            >
            Investors KYC Request
          </Title>
          <Tabs defaultActiveKey="1" centered>
            <TabPane tab={<span><PlaySquareOutlined/>Pending KYC Requests</span>}  key="1">
          <div hidden={!detailsDivision}>
          <br/>
          {/* <Table id="inv" dataSource={filteredInvestor} columns={columns} /> */}
          <Table id="inv" dataSource={filteredInvestor} columns={columns}  scroll={{ x: true }}
            pagination={{
              current: pagination.current,
              pageSize: pagination.pageSize,
              total: pagination.total,
              onChange: (page, pageSize) => {
                setPagination({ ...pagination, current: page, pageSize });
              },
            }}
            loading={loading}
          />
          </div>

           <div hidden={detailsDivision}>
                  <Row>

                    <Col span={1}>
                      <Card
                        style={iconCard}
                        onClick={
                          () => carousel.prev()
                        }
                      >
                        <LeftCircleOutlined style={icon} />
                      </Card>
                    </Col>

                    <Col span={22}>
                      <Carousel ref={node => (carousel = node)} autoplay >

                        <div>

                          <Card
                            style={card}>
                            <p style={p}>
                              Selfie
                            </p>
                            <div
                              style={imageDiv}
                            >
                              <img
                                style={image}
                                src={investorDetails?.selfie}
                                alt="selfie"
                              />
                            </div>
                          </Card>
                        </div>



                        <div>
                          <Card
                            style={card}>
                            <p style={p}>
                              Document Front Side
                            </p>
                            <div
                              style={imageDiv}
                            >
                              <img
                                style={image}
                                src={investorDetails?.IDFront}
                                alt="ID Front"
                              />
                            </div>
                          </Card>
                        </div>

                        <div>
                          <Card
                            style={card}>
                            <p style={p}>
                              Document Back Side
                            </p>
                            <div
                              style={imageDiv}
                            >
                              <img
                                style={image}
                                src={investorDetails?.IDBack}
                                alt="ID Back"
                              />
                            </div>
                          </Card>
                        </div>
                      </Carousel>
                    </Col>

                    <Col span={1}>
                      <Card style={iconCard}>
                        <RightCircleOutlined
                          style={icon}
                          onClick={
                            () => carousel.next()
                          }
                        />
                      </Card>
                    </Col>

                  </Row>


                  <Descriptions>
                    {investorDetails?.fullResult?.state === "A" && (
                      <Descriptions.item label="Automated KYC result">
                        <p
                          style={{
                            fontWeight: "bold",
                            color: "green",
                            fontSize: "25px",
                          }}
                        >
                          Approved
                        </p>
                      </Descriptions.item>
                    )}
                    {investorDetails?.fullResult?.state === "R" && (
                      <Descriptions.item label="Automated KYC result">
                        <p
                          style={{
                            fontWeight: "bold",
                            color: "yellow",
                            fontSize: "25px",
                          }}
                        >
                          Could not decide. Manual intervention needed.
                        </p>
                      </Descriptions.item>
                    )}
                    {investorDetails?.fullResult?.state === "D" && (
                      <Descriptions.item label="Automated KYC result">
                        <p
                          style={{
                            fontWeight: "bold",
                            color: "red",
                            fontSize: "25px",
                          }}
                        >
                          Rejected
                        </p>
                      </Descriptions.item>
                    )}
                  </Descriptions>


                  <Card
                    style={{
                      margin: "auto",
                      width: 900,
                      boxShadow:
                        "0 4px 12px 0 rgba(0, 0, 0, .1), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        textAlign: "left",
                      }}
                    >
                      PROFILE
                    </p>
                    <table style={{ margin: "auto", width: "100%" }}>
                    <tbody>
                      <tr>
                        <td style={{ textAlign: "left", fontWeight: "bold" }}>
                          Name
                        </td>
                        <td style={{ textAlign: "left" }}>
                          {investorDetails?.firstName}{" "}
                          {investorDetails?.lastName}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "left", fontWeight: "bold" }}>
                          Present Address
                        </td>
                        <td style={{ textAlign: "left" }}>
                          {
                            <div>
                              {investorDetails?.pAStreetAddress1}{" "}
                              {investorDetails?.pAStreetAddress2}{" "}
                              {investorDetails?.pACity}{" "}
                              {investorDetails?.pAState}{" "}
                              {investorDetails?.pACountry}{" "}
                              {investorDetails?.pAZipCode}
                            </div>
                          }
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "left", fontWeight: "bold" }}>
                          Permanent Address
                        </td>
                        <td style={{ textAlign: "left" }}>
                          {
                            <div>
                              {investorDetails?.pStreetAddress1}{" "}
                              {investorDetails?.pStreetAddress2}{" "}
                              {investorDetails?.pCity}{" "}
                              {investorDetails?.pAState}{" "}
                              {investorDetails?.pACountry}{" "}
                              {investorDetails?.pZipCode}
                            </div>
                          }
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "left", fontWeight: "bold" }}>
                          Contact Number
                        </td>
                        {investorDetails?.contactNumber && (
                          <td style={{ textAlign: "left" }}>
                            {formatPhoneNumberIntl(
                              "+" + investorDetails?.contactNumber
                            )}
                          </td>
                        )}
                      </tr>
                      <tr>
                        <td style={{ textAlign: "left", fontWeight: "bold" }}>
                          Email
                        </td>
                        <td style={{ textAlign: "left" }}>
                          {investorDetails?.email}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "left", fontWeight: "bold" }}>
                          Last Stage run
                        </td>
                        <td style={{ textAlign: "left", fontWeight: "bold" }}>
                          3
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "left", fontWeight: "bold" }}>
                          Date of KYC Request
                        </td>
                        <td style={{ textAlign: "left" }}>
                          {moment(investorDetails?.dateOfSubmission).format(
                            "LLL"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "left", fontWeight: "bold" }}>
                          Customer since
                        </td>
                        <td style={{ textAlign: "left" }}>
                          {moment(userInfo?.creationTS).format("LLL")}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "left", fontWeight: "bold" }}>
                          Token Symbol
                        </td>
                        {/* <td style={{ textAlign: "left" }}>{selectedTokenSymbol}</td> */}
                      </tr>
                      <tr>
                        <td style={{ textAlign: "left", fontWeight: "bold" }}>
                          Priority
                        </td>
                        <td style={{ textAlign: "left" }}>Normal</td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "left", fontWeight: "bold" }}>
                          Progress
                        </td>
                        <td style={{ textAlign: "left" }}>New</td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "left", fontWeight: "bold" }}>
                          Name of fraud rule
                        </td>
                        <td style={{ textAlign: "left" }}>
                          {investorDetails?.fullResult?.frn}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "left", fontWeight: "bold" }}>
                          Result of fraud evaluation
                        </td>
                        {investorDetails?.fullResult?.frp === "ACCEPT" && (
                          <td style={{ textAlign: "left", color: "green" }}>
                            Accepted
                          </td>
                        )}
                        {investorDetails?.fullResult?.frp !== "ACCEPT" && (
                          <td style={{ textAlign: "left", color: "red" }}>
                            Rejected
                          </td>
                        )}
                      </tr>
                      <tr>
                        <td style={{ textAlign: "left", fontWeight: "bold" }}>
                          Result of policy evaluation
                        </td>
                        {investorDetails?.fullResult?.res === "ACCEPT" && (
                          <td style={{ textAlign: "left", color: "green" }}>
                            Accepted
                          </td>
                        )}
                        {investorDetails?.fullResult?.res !== "ACCEPT" && (
                          <td style={{ textAlign: "left", color: "red" }}>
                            Rejected
                          </td>
                        )}
                      </tr>
                      <tr>
                        <td style={{ textAlign: "left", fontWeight: "bold" }}>
                          User reputation
                        </td>
                        <td style={{ textAlign: "left" }}>
                          {investorDetails?.fullResult?.user}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "left", fontWeight: "bold" }}>
                          User previous reputation
                        </td>
                        <td style={{ textAlign: "left" }}>
                          {investorDetails?.fullResult?.upr}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "left", fontWeight: "bold" }}>
                          Reason for reputation
                        </td>
                        <td style={{ textAlign: "left" }}>
                          {investorDetails?.fullResult?.erd}
                        </td>
                      </tr>
                      </tbody>
                    </table>

                    <p style={{ textAlign: "right" }}>
                      <Button
                        style={{ color: "green" }}
                        href={
                          environment.acuantUrl
                        }
                        target="__blank"
                      >
                        Detailed Report
                      </Button>
                    </p>
                  </Card>
                  <br />
                  <br />
                  <br />

                  <div hidden={displayOption !== displayOptions.MainDivision}>
                    <Button
                      onClick={() => acceptKYC(investorDetails)}
                      type="primary"
                      loading={submitting}
                    >
                      Accept
                    </Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button
                      onClick={() =>
                        setDisplayOption(displayOptions.AfterRejectDivision)
                      }
                      disabled={submitting}
                    >
                      Reject
                    </Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button
                      onClick={() => setDetailsDivision(true)}
                      disabled={submitting}
                    >
                      Cancel
                    </Button>
                  </div>

                  <div
                    hidden={
                      displayOption !== displayOptions.AfterRejectDivision
                    }
                    id="radioDiv"
                  >
                    <Radio.Group onChange={changeView}>
                      <Radio value={1} ref={resubmitRadioRef}>
                        Ask To Resubmit Details
                      </Radio>
                      <Radio value={2} ref={rejectRadioRef}>
                        Reject
                      </Radio>
                    </Radio.Group>
                    {subDisplayOption === subDisplayOptions.RejectView && (
                      <>
                        <center>
                          <table>
                            <tr>
                              <td>
                                Reason:<sup style={{ color: "red" }}>*</sup>
                              </td>
                              <td>
                                <TextArea
                                  style={{ width: 650 }}
                                  ref={textBoxRef}
                                  rows={8}
                                ></TextArea>
                              </td>
                            </tr>
                          </table>
                        </center>{" "}
                      </>
                    )}
                    <Button
                      type="primary"
                      htmlType="button"
                      onClick={proceedToRejectWithCheck}
                    >
                      Submit
                    </Button>
                  </div>
                </div>








          </TabPane>
          <TabPane tab={<span><ProjectOutlined/>Approved/Rejected KYC Requests</span>} key="2">
              <div hidden={!detailsDivision1}>
              <Table columns={columns2} dataSource={approvedAndRejectedKYCRequests}  scroll={{ x: true }}
              pagination={{
                current: paginationApprovedRejected.current,
                pageSize: paginationApprovedRejected.pageSize,
                total: paginationApprovedRejected.total,
                onChange: (page, pageSize) => {
                  setPaginationApprovedRejected({ ...paginationApprovedRejected, current: page, pageSize });
                },
              }}
              loading={loadingApprovedRejected}></Table>
              </div>
              <div hidden={detailsDivision1}>
              <Row>

                <Col span={1}>
                  <Card
                    style={iconCard}
                    onClick={
                      () => carousel.prev()
                    }
                  >
                    <LeftCircleOutlined style={icon} />
                  </Card>
                </Col>

                <Col span={22}>
                  <Carousel ref={node => (carousel = node)} autoplay >

                    <div>

                      <Card
                        style={card}>
                        <p style={p}>
                          Selfie
                        </p>
                        <div
                          style={imageDiv}
                        >
                          <img
                            style={image}
                            src={investorDetails?.selfie}
                            alt="selfie"
                          />
                        </div>
                      </Card>
                    </div>



                    <div>
                      <Card
                        style={card}>
                        <p style={p}>
                          Document Front Side
                        </p>
                        <div
                          style={imageDiv}
                        >
                          <img
                            style={image}
                            src={investorDetails?.IDFront}
                            alt="ID Front"
                          />
                        </div>
                      </Card>
                    </div>

                    <div>
                      <Card
                        style={card}>
                        <p style={p}>
                          Document Back Side
                        </p>
                        <div
                          style={imageDiv}
                        >
                          <img
                            style={image}
                            src={investorDetails?.IDBack}
                            alt="ID Back"
                          />
                        </div>
                      </Card>
                    </div>
                  </Carousel>
                </Col>

                <Col span={1}>
                  <Card style={iconCard}>
                    <RightCircleOutlined
                      style={icon}
                      onClick={
                        () => carousel.next()
                      }
                    />
                  </Card>
                </Col>

              </Row>
              <Descriptions>
                {investorDetails?.fullResult.state == "A" && (
                  <Descriptions.item label="Automated KYC result">
                    <p
                      style={{
                        color: "green",
                        fontWeight: "bold",
                        fontSize: "25px",
                      }}
                    >
                      Approved
                    </p>
                  </Descriptions.item>
                )}
                {investorDetails?.fullResult.state == "R" && (
                  <Descriptions.item label="Automated KYC result">
                    <p
                      style={{
                        fontWeight: "bold",
                        color: "orange",
                        fontSize: "25px",
                      }}
                    >
                      Could not decide. Manual review needed.
                    </p>
                  </Descriptions.item>
                )}
                {investorDetails?.fullResult.state == "D" && (
                  <Descriptions.item label="Automated KYC resultt">
                    <p
                      style={{
                        fontWeight: "bold",
                        color: "red",
                        fontSize: "25px",
                      }}
                    >
                      Rejected
                    </p>
                  </Descriptions.item>
                )}
              </Descriptions>
              <Card
                style={{
                  margin: "auto",
                  width: 900,
                  boxShadow:
                    "0 4px 12px 0 rgba(0, 0, 0, .1), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
                }}
              >
                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  PROFILE
                </p>
                <table style={{ margin: "auto", width: "100%" }}>
                  <tr>
                    <td style={{ textAlign: "left", fontWeight: "bold" }}>
                      Name
                    </td>
                    <td style={{ textAlign: "left" }}>
                      {investorDetails?.firstName}{" "}
                      {investorDetails?.lastName}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left", fontWeight: "bold" }}>
                      Present Address
                    </td>
                    <td style={{ textAlign: "left" }}>
                      {
                        <div>
                          {investorDetails?.pAStreetAddress1}{" "}
                          {investorDetails?.pAStreetAddress2}{" "}
                          {investorDetails?.pACity}{" "}
                          {investorDetails?.pAState}{" "}
                          {investorDetails?.pACountry}{" "}
                          {investorDetails?.pAZipCode}
                        </div>
                      }
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left", fontWeight: "bold" }}>
                      Permanent Address
                    </td>
                    <td style={{ textAlign: "left" }}>
                      {
                        <div>
                          {investorDetails?.pStreetAddress1}{" "}
                          {investorDetails?.pStreetAddress2}{" "}
                          {investorDetails?.pCity}{" "}
                          {investorDetails?.pAState}{" "}
                          {investorDetails?.pACountry}{" "}
                          {investorDetails?.pZipCode}
                        </div>
                      }
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left", fontWeight: "bold" }}>
                      Contact Number
                    </td>
                    {investorDetails?.contactNumber && (
                      <td style={{ textAlign: "left" }}>
                        {formatPhoneNumberIntl(
                          "+" + investorDetails?.contactNumber
                        )}
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left", fontWeight: "bold" }}>
                      Email
                    </td>
                    <td style={{ textAlign: "left" }}>
                      {investorDetails?.email}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left", fontWeight: "bold" }}>
                      Last Stage run
                    </td>
                    <td style={{ textAlign: "left", fontWeight: "bold" }}>
                      3
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left", fontWeight: "bold" }}>
                      Date of KYC Request
                    </td>
                    <td style={{ textAlign: "left" }}>
                      {moment(investorDetails?.dateOfSubmission).format(
                        "LLL"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left", fontWeight: "bold" }}>
                      Customer since
                    </td>
                    <td style={{ textAlign: "left" }}>
                      {moment(userInfo?.creationTS).format("LLL")}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left", fontWeight: "bold" }}>
                      Priority
                    </td>
                    <td style={{ textAlign: "left" }}>Normal</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left", fontWeight: "bold" }}>
                      Progress
                    </td>
                    <td style={{ textAlign: "left" }}>New</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left", fontWeight: "bold" }}>
                      Name of fraud rule
                    </td>
                    <td style={{ textAlign: "left" }}>
                      {investorDetails?.fullResult.frn}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left", fontWeight: "bold" }}>
                      Result of fraud evaluation
                    </td>
                    {investorDetails?.fullResult.frp === "ACCEPT" && (
                      <td style={{ textAlign: "left", color: "green" }}>
                        Accepted
                      </td>
                    )}
                    {investorDetails?.fullResult.frp !== "ACCEPT" && (
                      <td style={{ textAlign: "left", color: "red" }}>
                        Rejected
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left", fontWeight: "bold" }}>
                      Result of policy evaluation
                    </td>
                    {investorDetails?.fullResult.res === "ACCEPT" && (
                      <td style={{ textAlign: "left", color: "green" }}>
                        Accepted
                      </td>
                    )}
                    {investorDetails?.fullResult.res !== "ACCEPT" && (
                      <td style={{ textAlign: "left", color: "red" }}>
                        Rejected
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left", fontWeight: "bold" }}>
                      User reputation
                    </td>
                    <td style={{ textAlign: "left" }}>
                      {investorDetails?.fullResult?.user}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left", fontWeight: "bold" }}>
                      User previous reputation
                    </td>
                    <td style={{ textAlign: "left" }}>
                      {investorDetails?.fullResult?.upr}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left", fontWeight: "bold" }}>
                      Reason for reputation
                    </td>
                    <td style={{ textAlign: "left" }}>
                      {investorDetails?.fullResult?.erd}
                    </td>
                  </tr>
                </table>

                <p style={{ textAlign: "right" }}>
                  <Button
                    style={{ color: "green" }}
                    href={
                      environment.acuantUrl
                    }
                    target="__blank"
                  >
                    Detailed Report
                  </Button>
                </p>
              </Card>
              <br />
              <br />
              <Button
                type="primary"
                htmlType="button"
                onClick={() => {
                  setDetailsDivision1(true);
                }}
              >
                BACK
              </Button>

              </div>
          </TabPane>
          </Tabs>
          </Card>
      </Col>
     </Row>
  </>
  )
}