import React, { useState, useEffect } from 'react'
import { Card,Row,Col,Typography, Table,Button,Descriptions, Divider } from 'antd'
import {PlatformSuperAdminService} from '../PlatformSuperAdmin.service'
import { SharedService } from '../../Shared/Shared.service';
import moment from 'moment';
const {Title}=Typography
const sharedService=new SharedService()
const platformSuperAdminService=new PlatformSuperAdminService()

export default ()=>{

const [data,setData]=useState(null)
const [detailsView,setDetailsViewFlag]=useState(true)
const [basicInfo,setBasicInfo]=useState()

useEffect(()=>{
(async ()=>{
  const response=await platformSuperAdminService.getIssuers();
  if(response.data && response.success)
  {
    setData(response.data)
  }
})();
},[]);

const setDetailsView=(detail)=>{
  setDetailsViewFlag(false)
  setBasicInfo(detail)
}
const changeView=()=>{
  setDetailsViewFlag(true)
}

  const column=[
    {
      title:"Company Name",
      dataIndex:"companyName",
      key:"companyName"

    },
    {
      title:"Date of Incorporate",
      dataIndex:"creationTS",
      key:"creationTS"
    },
    {
      title:"Country",
      dataIndex:"companyCountry",
      key:"companyCountry"
    },
    {
      title:"State",
      dataIndex:"comapnyState",
      key:"companyState"
    },
    {
      title:"Govt Tax ID No.",
      dataIndex:"govTaxIdNumber",
      key:"govTaxIdNumber"
    },
    {
      title:"Details",
      render:(detail,index)=><Button onClick={()=>setDetailsView(index)}>View</Button>,
      key:"details"
    }
  ]
  return(
    <>
    <br/>
     <Row justify="center">
      <Col span={22} style={{textAlign:'center'}}>
        <Card>
          <Title
            level={2}
            style={{
              textAlign: 'left',
              color: '#1890ff',
              // fontSize: '30px',
              fontWeight: 'bold'
            }}
            >
            View All Issuers
          </Title>
          <div hidden={!detailsView}>
          <Table dataSource={data} columns={column} loading={!data} ></Table>
          </div>
          <div hidden={detailsView}>
              {basicInfo && <Descriptions bordered column={2} title="Information">
                <Descriptions.Item label="Company Name">{basicInfo.companyName}</Descriptions.Item>
                <Descriptions.Item label="Company Registered Country">{basicInfo['displayableCountry']}</Descriptions.Item>
                <Descriptions.Item label="Company Registered State">{basicInfo.companyState}</Descriptions.Item>
                <Descriptions.Item label="Zip Code">{basicInfo.zipCode}</Descriptions.Item>
                <Descriptions.Item label="Date of Incorporate">{moment(basicInfo.dateOfIncorporate).format('LLL')}</Descriptions.Item>
                <Descriptions.Item label="Government Tax ID Number">{basicInfo.govTaxIdNumber}</Descriptions.Item>
                <Descriptions.Item label="Company registered documents">
                  <a href={basicInfo.companyRegFile?.URL} download={basicInfo.companyRegFile?.name} target="_blank" rel="noopener noreferrer">{basicInfo.companyRegFile?.name}</a>
                </Descriptions.Item>
                <Descriptions.Item label="Tax Number Registration Document">
                  <a href={basicInfo.taxNumberRegFile?.URL} download={basicInfo.taxNumberRegFile?.name} target="_blank" rel="noopener noreferrer">{basicInfo.taxNumberRegFile?.name}</a>
                </Descriptions.Item>
                {basicInfo.boardResolution.option === 'upload' && 
                  <Descriptions.Item label="Board Resolution on token Creation">
                    <a href={basicInfo.boardResolution.file?.URL} download={basicInfo.boardResolution.file?.name} target="_blank" rel="noopener noreferrer">{basicInfo.boardResolution.file?.name}</a>
                  </Descriptions.Item>
                }
                {basicInfo.additionInfo && 
                  <Descriptions.Item label="Addition Info">{basicInfo.additionInfo}</Descriptions.Item>
                }
                <Descriptions.Item label="Regulation">{sharedService.regulationName(basicInfo.regulation)}</Descriptions.Item>
                <Descriptions.Item label="Issuer Filed for Regulation D Excemption with SEC">{basicInfo.SECFilingOption === 'yes'? 'Yes' : 'No'}</Descriptions.Item>
                {basicInfo.SECFilingFile && 
                  <Descriptions.Item label="SEC filing Document">
                    <a href={basicInfo.SECFilingFile?.URL} download={basicInfo.SECFilingFile?.name} target="_blank" rel="noopener noreferrer">{basicInfo.SECFilingFile?.name}</a>
                  </Descriptions.Item>
                }
              </Descriptions>
              }
              <Divider/>
              <Button type='primary' htmlType='button' onClick={changeView}>OK</Button>
          </div>
      </Card>
      </Col>
     </Row>
  </>
  )
}