import React, { useState, useEffect } from "react";
import { Row, Col, Card, Typography, Table, Spin, Button, Modal, Input, notification } from "antd";
import axios from "axios";
import { AuthService } from "../../Shared/Auth.service";
import { environment } from "../../../environments/environment";
import { APIResponse } from "../../Shared/interfaces";
import { SharedService } from "../../Shared/Shared.service";
import { InvestorService } from "../Investor.service";
const authService = new AuthService();
const useUserContext = () => authService.useUserContext();

const sharedService = new SharedService();
const investorService = new InvestorService()

const { Title } = Typography;

const PaymentApproval = () => {
  const APIURL = environment.APIURL;
  const [paymentData, setPaymentData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [showRejectPopup, setShowRejectPopup] = useState(false);
  const [selectedInvestorId, setSelectedInvestorId] = useState(null);
  const [selectedPaymentId, setSelectedPaymentId] = useState(null);
  const { userInfo } = useUserContext();
  const companyId = userInfo?.company?.id;

 

  async function fetchData() {
    try {
      const response = await investorService.getAllPaymentDetailsWithAllInvestorDetailsByCompanyId(companyId);
      setPaymentData(response?.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    fetchData();
  }, [companyId]);

  const handleApprove = async (investorId, paymentId) => {
    try {
      const data = {
        investorId: investorId,
        status: "approved",
        paymentId: paymentId,
      }
      const resp = await investorService.approveOrRejectPaymentRequestByCompanyId(companyId,data)
      if(resp.success){
        notification.success({message:'Investment approved successfully!'})
      }
      fetchData();
    } catch (error) {
      console.error("Error approving payment:", error);
    }
  };

  const openRejectPopup = (investorId, paymentId) => {
    setSelectedInvestorId(investorId);
    setSelectedPaymentId(paymentId);
    setShowRejectPopup(true);
  };

  const closeRejectPopup = () => {
    setSelectedInvestorId(null);
    setSelectedPaymentId(null);
    setShowRejectPopup(false);
    setRejectReason("");
  };

  const handleReject = async () => {
    if (rejectReason && selectedInvestorId && selectedPaymentId) {
      try {


        const data = {
          investorId: selectedInvestorId,
          status: "rejected",
          message: rejectReason,
          paymentId: selectedPaymentId,
        }
        const resp = await investorService.approveOrRejectPaymentRequestByCompanyId(companyId,data)
        if(resp.success){
          notification.success({message:'Investment rejected successfully!'})
        }

        fetchData();
        closeRejectPopup();
      } catch (error) {
        console.error("Error rejecting payment:", error);
      }
    }
  };

  const columns = [
    
    {
      title: "First Name",
      dataIndex: ["investorDetails", "firstName"],
      key: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: ["investorDetails", "lastName"],
      key: "lastName",
    },
    {
      title: "Email",
      dataIndex: ["investorDetails", "email"],
      key: "email",
    },
    {
      title: "Transaction Id",
      dataIndex: "txnId",
      key: "txnId",
    },
    // {
    //   title: "Payment id",
    //   dataIndex: "investmentId",
    //   key: "investmentId",
    // },
    {
      title: "Token Count",
      dataIndex: "tokenCount",
      key: "tokenCount",
    },
    {
      title: "Amount Invest",
      dataIndex: "amountToInvest",
      key: "amountToInvest",
    },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    // },
    {
      title: "Token Issued",
      dataIndex: "tokenIssued",
      key: "tokenIssued",
      render: (tokenIssued) => (tokenIssued ? "Yes" : "No"),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => {
        if (record.status === "approved") {
          return <Button disabled>Approved</Button>;
        } else if (record.status === "rejected") {
          return <Button disabled>Rejected</Button>;
        }
        return (
          <>
            <div className="d-flex gap-2">
              <Button onClick={() => handleApprove(record.investorId, record._id)}>
                Approve
              </Button>
              <Button onClick={() => openRejectPopup(record.investorId, record._id)}>Reject</Button>
            </div>
          </>
        );
      },
    },
  ]

  return (
    <>
      <br />
      <br />
      <Row justify="center">
        <Col span={23}>
          {loading ? (
            <div style={{ textAlign: "center" }}>
              <Spin size="large" />
            </div>
          ) : (
            <Card>
              <Title level={1} style={{ textAlign: "center" }}>
                Investor Payments Approval
              </Title>
              <Table dataSource={paymentData} columns={columns}  scroll={{ x: true }}/>
            </Card>
          )}
        </Col>
      </Row>
      <Modal
        title="Reject Payment"
        visible={showRejectPopup}
        onCancel={closeRejectPopup}
        footer={[
          <Button key="back" onClick={closeRejectPopup}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleReject}>
            Submit
          </Button>,
        ]}
      >
        <Input
          placeholder="Reason for rejection"
          value={rejectReason}
          onChange={(e) => setRejectReason(e.target.value)}
        />
      </Modal>
    </>
  );
};

export default PaymentApproval;
