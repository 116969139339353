import { environment } from "../../../environments/environment";
import axios from 'axios';
import { SharedService } from "../Shared.service";
import { APIResponse } from "../interfaces";

const sharedService = new SharedService();

export class PaymentInfoService {
    private APIURL = environment.APIURL;

     routes = {
        issuercomplianceadmin: 'issuerSuperAdmin/approveOrRejectBankInfoByLLPCAByCompanyId',
        mastercompanycomplianceadmin: 'issuerSuperAdmin/approveOrRejectBankInfoByMCAByCompanyId',
        platformissueradmin:'issuerSuperAdmin/approveOrRejectBankInfoByPSAByCompanyId'
    }

    async approveOrRejectPaymentInfo(companyId,payload,role,paymentType) {
        return axios.post<any, APIResponse>(
          `${this.APIURL}/${this.routes[role]}?paymentType=${paymentType}&companyId=${companyId}`,
          payload,
          { headers: await sharedService.getAuthHeader() }
        );
      }

      async getPaymentDetailsByPaymentTypeAndCompanyId(companyId,paymentType) {
        return axios.get<any, APIResponse>(
          `${this.APIURL}/issuerSuperAdmin/getPaymentDetailsByPaymentTypeAndCompanyId?paymentType=${paymentType}&companyId=${companyId}`,
          { headers: await sharedService.getAuthHeader() }
        );
      }
      async getPaymentDetailsByPaymentTypeForMCAOrPSA(paymentType) {
        return axios.get<any, APIResponse>(
          `${this.APIURL}/issuerSuperAdmin/getPaymentDetailsByPaymentTypeForMCAOrPSA?paymentType=${paymentType}`,
          { headers: await sharedService.getAuthHeader() }
        );
      }
}