import React, { useEffect,useState } from 'react'
import {Table,Card,Col,Row,Typography, Descriptions,Button,Tabs, Modal, Input, message, notification} from 'antd'
import { PlaySquareOutlined, ProjectOutlined } from '@ant-design/icons';
import moment from 'moment'

import { AuthService } from '../../Shared/Auth.service';
import { IssuerSuperAdminService } from '../IssuerSuperAdmin.service';
import { SharedService } from '../../Shared/Shared.service';
const {Title}=Typography
const {TabPane}=Tabs;
const issuerSuperAdminService=new IssuerSuperAdminService()
const sharedService = new SharedService();
const useUserContext = () => new AuthService().useUserContext();

const ShareholderDetailsList = () => {
    const [shareholders,setShareholders]=useState<any>()
    const [detailsDivision,setDetailsDivision]=useState(true)
    const [shareholderDetails,setShareholderDetails]=useState<any>(null)
    const [submitting,setSubmitting]=useState(false)
    const [approvedAndRejectedShareholders,setShareholderList]=useState([])
    const { userInfo } = useUserContext();
    const [showRejectPopup, setShowRejectPopup] = useState(false);
    const [rejectReason, setRejectReason] = useState("");
    const [companyId, setCompanyId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [companyStatus, setCompanyStatus] = useState(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedShareholders, setSelectedShareholders] = useState([]);
    const [repaint, setRepaint] = useState(false);

    const columns=[
      {
        title:'Company',
        dataIndex: userInfo?.role == 'issuer compliance admin' ? ["companyDetails", "name"]:  ["seriesDetails", "name"],
        key:'name',
      },
      {
        title:'Name',
        dataIndex:'name',
        key:'name',
      },
      {
        title:'Wallet',
        dataIndex:'wallet',
        key:'wallet',
      },
      {
        title:'Token Count',
        dataIndex:'tokenCount',
        key:'tokenCount',
      },
      {
        title:'Invested Amount',
        dataIndex:'investedAmount',
        key:'investedAmount',
      },
      {
        title:'CREATED ON',
        dataIndex:'creationTS',
        key:'creationTS',
        render:text=>moment(text).format('YYYY-MM-DD')
      },
      {
        title:'Status',
        dataIndex:'status',
        key:'status',
      }
  
    ]
    const columns1=[
      {
        title:'Company',
        dataIndex:["companyDetails", "name"],
        key:'name',
      },
      {
        title:'Name',
        dataIndex:'name',
        key:'name',
      },
      {
        title:'Wallet',
        dataIndex:'wallet',
        key:'wallet',
      },
      {
        title:'Token Count',
        dataIndex:'tokenCount',
        key:'tokenCount',
      },
      {
        title:'Invested Amount',
        dataIndex:'investedAmount',
        key:'investedAmount',
      },
      {
        title:'CREATED ON',
        dataIndex:'creationTS',
        key:'creationTS',
        render:text=>moment(text).format('YYYY-MM-DD')
      },
      {
        title:'Status',
        dataIndex:userInfo?.role == 'issuer compliance admin' ? 'llpCA' :'status',
        key:'status',
      }
  
    ]

    

    const shListcolumns=[
      {
        title:'Name',
        dataIndex:'name',
        key:'name',
      },
      {
        title:'Wallet',
        dataIndex:'wallet',
        key:'wallet',
      },
      {
        title:'Token Count',
        dataIndex:'tokenCount',
        key:'tokenCount',
      },
      {
        title:'Invested Amount',
        dataIndex:'investedAmount',
        key:'investedAmount',
      },
      
    ]

    const showDetails=shareholder=>{
        setDetailsDivision(false)
        console.log(shareholder)
        return;
        // setShareholderDetails(shareholder.shareHolderList.map((x,i) => ({...x,key:i}) ).filter(Boolean).filter(x => !isNaN(x.tokenCount)))
        // setCompanyId(shareholder.companyId);
        // setCompanyStatus(shareholder.status)
      }

    useEffect(
        ()=>{
          (async ()=>{
            setIsLoading(true)
            let response;
            switch (userInfo?.role) {
              case "issuer compliance admin":
                response=await issuerSuperAdminService.getShareholderApi(userInfo?.company?.id);
                
                const pendingShareHolders = response.data.shareHolderDetails.filter(sh => sh.llpCA == 'pending')
                setShareholders(pendingShareHolders)
                
                const appovedShareHolders = response.data.shareHolderDetails.filter(sh => sh.llpCA == 'approved' ||  sh.llpCA == 'rejected')
                setShareholderList(appovedShareHolders)
                setIsLoading(false)

                break;
              case "master company super admin":
                response=await issuerSuperAdminService.getAllCompaniesShareHolderListsForMCA();

                const pendingShareHoldersMCA = response.data.pendingShareHolders.filter(sh => sh.llpCA == 'approved');
                setShareholders(pendingShareHoldersMCA)
                
                const appovedShareHoldersMCA = response.data.appovedShareHolders;
                setShareholderList(appovedShareHoldersMCA)
                setIsLoading(false)
                break;
            }
            
            console.log(response)
            // setShareholders(response.data.pendingShareHolders)

            
          }
            
            )();
        },[repaint]
      )
    

      const approveOrReject = async(status) =>{
        try {
          // console.log(data)
          
          // let payload = {status,reason:'',shareHolderId:''}
          if(status == 'rejected'){

            if(rejectReason == ''){
              notification.info({message: 'Info', description: 'Provide valid rejection message'});
              return;
            }
            
            // payload.reason = rejectReason;
            setShowRejectPopup(false)
          }
          // console.log(payload)

          if(selectedShareholders.length <= 0){
            message.info('Please select record(s) first!')
            return;
          }

          const shResArr = await Promise.all(selectedShareholders.map(async(sh:any) => {
            if(userInfo?.role === 'master company super admin'){

              let payload={mca:status,status,rejectionReason:rejectReason,shareHolderId: sh?._id}
              return await issuerSuperAdminService.approveOrRejectShareHoldersListByCompanyIdByMCA(sh?.companyId,payload);

            }else if(userInfo?.role === 'issuer compliance admin'){

              let payload={llpCA:status,rejectionReason:rejectReason,shareHolderId: sh?._id}
              return await issuerSuperAdminService.approveOrRejectShareHoldersListByCompanyIdByLLPCA(sh?.companyId,payload);

            }
            
          }))
          
          const successCount = shResArr.reduce((c, n:any) => (n.success ? c + 1 : c), 0);
          if(shResArr){
            message.success(`${successCount} Shareholders ${status} successfully`)
            setRejectReason('')
            setRepaint(prev => !prev)
          }

        } catch (error) {
          console.log(error);
          message.error(error)
        }
      }

      const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          setSelectedRowKeys(selectedRowKeys);
          setSelectedShareholders(selectedRows)
        },
      };
    
  return (
    <>
   <br/>
     <Row justify="center">
      <Col span={22} style={{textAlign:'center'}}>
        <Card>
          <Title
            level={2}
            style={{
              textAlign: 'left',
              color: '#1890ff',
              // fontSize: '30px',
              fontWeight: 'bold'
            }}
            >
            Shareholder's Request
          </Title>
          <Tabs defaultActiveKey="request" centered onChange={() => setDetailsDivision(true)}>
            <TabPane tab={<span><PlaySquareOutlined/>Pending Shareholder Requests</span>} key="request">
          <div hidden={!detailsDivision}>
          <Table rowSelection={rowSelection} dataSource={shareholders} columns={columns} rowKey={'_id'} scroll={{x: true}}  loading={isLoading}/>
          <div className="button-grid">
          <Button type='primary' onClick={()=>approveOrReject('approved')} className='mb-2'>Approve</Button>&nbsp;
          <Button type='primary' onClick={()=>setShowRejectPopup(true)}>Reject</Button>
          </div>
          </div>
          {/* <div hidden={detailsDivision}>
            <h4>Shareholder Details</h4>
              <Table columns={shListcolumns} dataSource={shareholderDetails}  loading={isLoading} ></Table>
            <br/>
            {companyStatus == 'rejected' || companyStatus == 'pending' &&<Button type='primary' onClick={()=>approveOrReject('approved')}>Approve</Button>} &nbsp;
            {companyStatus == 'approved'|| companyStatus == 'pending' &&<Button type='primary' onClick={()=>{setShowRejectPopup(true);}}>Reject</Button>}&nbsp;
            <Button onClick={()=>setDetailsDivision(true) } disabled={submitting}>Back</Button>
          </div> */}
          </TabPane>
          <TabPane tab={<span><ProjectOutlined/> Approved/Rejected Request</span>}>
          <div hidden={!detailsDivision}>
            <Table columns={columns1} dataSource={approvedAndRejectedShareholders} loading={isLoading} scroll={{x: true}}></Table>
            </div>
            <div hidden={detailsDivision}>
            <h4>Shareholder Details</h4>
              <Table rowSelection={rowSelection}  columns={shListcolumns} dataSource={shareholderDetails}  loading={isLoading} scroll={{x: true}}></Table>
            <br/>
            {companyStatus == 'rejected' &&<Button type='primary' onClick={()=>approveOrReject('approved')}>Approve</Button>} &nbsp;
            {companyStatus == 'approved' &&<Button type='primary' onClick={()=>{setShowRejectPopup(true);}}>Reject</Button>}&nbsp;
            <Button onClick={()=>setDetailsDivision(true) } disabled={submitting}>Back</Button>
          </div>
          </TabPane>
          </Tabs>
          
          
          </Card>
      </Col>
     </Row>

     <Modal
        title="Reject Shareholder"
        visible={showRejectPopup}
        onCancel={()=>setShowRejectPopup(false)}
        footer={[
          <Button key="back" onClick={()=>{setShowRejectPopup(false); setCompanyId(null)}}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={()=>approveOrReject('rejected')}>
            Submit
          </Button>,
        ]}
      >
        <Input 
          placeholder="Reason for rejection"
          value={rejectReason}
          onChange={(e) => setRejectReason(e.target.value)}
        />
      </Modal>
  </>
  )
}

export default ShareholderDetailsList