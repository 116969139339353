import React, { useEffect, useState, useRef } from "react";
import {
  Table,
  Card,
  Col,
  Row,
  Typography,
  Descriptions,
  Button,
  Tabs,
  Radio,
  message,
  Input,
  Carousel,
  Form,
} from "antd";
import { RightCircleOutlined, LeftCircleOutlined } from "@ant-design/icons";
import { PlaySquareOutlined, ProjectOutlined } from "@ant-design/icons";
import { SharedService } from "../Shared/Shared.service";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { useForm } from "antd/lib/form/Form";
import { AccountService } from "../Account/Account.service";
import './InvesterProperty.scss'

const sharedService = new SharedService();
const accountService = new AccountService();

const InterestPropertyCompanyManager = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [comments, setComments] = useState("");
  const [form] = Form.useForm();
  const [submitted, setSubmitted] = useState(false);
  const { TextArea } = Input;

  const submitInterest = async () => {
    try {
      let form = {
        firstName,
        lastName,
        email,
        comments,
      };

      let response = await accountService.submitInterestToRegisterAsPCA(form);
      if (response) {
        message.success(
          "Successfully send interest to marketplace super admin."
        );
        setSubmitted(true);
      }
    } catch (error) {
      console.error(error);
      message.error("Something went wrong while sending interest!");
    }
  };

  return (
    <>
      <div className="container invester-form-wrapper ">
        <div className=" row ">
          <div className="col-12 col-md-6 offset-md-3 iform  my-5">

            { !submitted &&<Form form={form} onFinish={submitInterest}>
              <div className="mb-4">
                <label htmlFor="firstname" className="form-label">
                  First Name
                </label>
                <Form.Item
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: "Please input your first name!",
                    },
                  ]}
                >
                  <Input 
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </Form.Item>
              </div>
              <div className="mb-4">
                <label htmlFor="lastname" className="form-label">
                  Last Name
                </label>
                <Form.Item
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: "Please input your last name!",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </Form.Item>
              </div>
              <div className="mb-4">
                <label htmlFor="email" className="form-label">
                  E-mail
                </label>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                >
                  <Input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Item>
              </div>

              <div className="mb-4">
                <label htmlFor="text" className="form-label">
                  Comment
                </label>
                <Form.Item name="comments">
                  <TextArea
                    rows={4}
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                  />
                </Form.Item>
              </div>

              <div className=" d-flex justify-content-center  my-4">
                <Form.Item>
                  <Button
                    style={{ marginTop: "20px" }}
                    type="primary"
                    htmlType="submit"
                    className="login-form-button rounded-pill"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </div>
            </Form>}

              {submitted && (
            <Card>
              <h6>Your interest to register as propery company manager has been successfully submitted to MarketPlace Super Admin.
              If he approves your interest then you will be getting an email invite to register!
              <br/>
              Now you can go to marketplace page to see all properties!</h6>
            </Card>
              )}
          </div>
        </div>
      </div>
    </>
  );
};

export default InterestPropertyCompanyManager;
