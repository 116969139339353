import React from 'react';
import { IssuerRegistrationRequest } from "../../Shared/interfaces"
import { Table, Card, Button } from 'antd';
import { SharedService } from '../../Shared/Shared.service';
import moment from 'moment';

const { Column } = Table;

const sharedService = new SharedService();

export default (prop) => {
  const {data, loading, statuses, showDetails} = prop;
  
  (data as IssuerRegistrationRequest[])?.forEach((req, index) => {
    req['columNumber'] = index + 1;
    req['createdOn'] = moment(req.creationTS).format('LLL');
    req['statusName'] = sharedService.statusName(req.status);
  });

  return (
    <Card style={{ margin: 'auto', marginTop: '4%' }}>
      <Table 
        dataSource={data} 
        rowKey="_id"  
        loading={loading}
        scroll={{x: 'max-content',y:400}}
      >
        <Column title='#' dataIndex='columNumber' width={50} />
        <Column title='FIRST NAME' dataIndex='firstName' width={100} ellipsis={true} />
        <Column title='LAST NAME' dataIndex='lastName' width={100} ellipsis={true} />
        <Column title='COMPANY' dataIndex='companyName' width={150} ellipsis={true} />
        <Column title='EMAIL ADDRESS' dataIndex='email' width={200} ellipsis={true} />
        <Column title='PHONE' dataIndex='phone' width={150} ellipsis={true} />
        <Column title='CREATED ON' dataIndex='createdOn' width={150} ellipsis={true} />
        {!statuses.includes('pending') && 
          <Column title='STATUS' dataIndex='statusName' width={100} ellipsis={true} />
        }
        <Column
          title='ACTION'
          key='1'
          width={150}
          render={(text, issuerRegReq: IssuerRegistrationRequest) => (
            statuses.includes('pending') ?
            <Button type='link' onClick={() => showDetails(issuerRegReq)}>Approve/Reject</Button> : 
            <Button type='link' onClick={() => showDetails(issuerRegReq)}>Details</Button>
          )}
        />
      </Table>
    </Card>
  )
}