import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  Typography,
  Table,
  Button,
  Tabs,
  notification,
  message,
} from 'antd';
import { SharedService } from '../../Shared/Shared.service';
import moment from 'moment';
import axios from 'axios';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../Shared/Auth.service';
import { SymbolDetailsAndSTData, TokenConfigurationProcess } from '../../Shared/interfaces';
import { TokenConfigurationService } from '../../TokenConfigurations/TokenConfiguration.service';
import { MetamaskService } from '../../Shared/Metamask.service';
// import CompanyDashboard from '../CompanyDashboard/CompanyDashboard';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { IssuerTokenAdminService } from '../IssuerTokenAdmin.service';


const { Title } = Typography;
const { TabPane } = Tabs;
const sharedService = new SharedService();
const issuerTokenAdminService = new IssuerTokenAdminService();
const UserContext = AuthService.UserContext;
const useUserInfo = () => new AuthService().useUserInfo();
const tokenConfigurationService = new TokenConfigurationService();
const metamaskService = new MetamaskService();


export default function IssueTokensToShareholders() {
  const APIURL = environment.APIURL;
  const [globalCompanyId, setGlobalCompanyId] = useState("");
  const [globalInvestorId, setGlobalInvestorId] = useState("");
  const investerIdListArray: any[] = [];
  const [loading, setLoading] = useState(false);
  let filteredInvesterIdListArray: any[] = [];
  const [USDTokenPurchases, setUSDTokenPurchases] = useState<any[]>();
  const [userInfo, setUserInfo] = useUserInfo();
  const [isTokenSaleClosed, setIsTokenSaleClosed] = useState(false);
  const [symbolDetailsAndSTData, setSymbolDetailsAndSTData] = useState<SymbolDetailsAndSTData>();
  const [shareholderData, setShareholderData] = useState([]);
  const [llpAddress, setllpAddress] = useState("");
  const [tokenAdminWallet, settokenAdminWallet] = useState("");
  const [showIssueTokenForm, setShowIssueTokenForm] = useState(false);
  const [alreadyIssuedTokenDetailsList, setAlreadyIssuedTokenDetailsList] = useState<any>();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedShareholders, setSelectedShareholders] = useState([]);

  const [tokenConfigurationProcesses, setTokenConfigurationProcess] = useState<TokenConfigurationProcess>();
  const SelectedWalletContext = MetamaskService.SelectedWalletContext;
  const useSelectedWallet = (userId: string) =>
    new MetamaskService().useSelectedWallet(userId);
  const [
    selectedWallet,
    networkId,
    isMetamaskAuthenticated,
    setSelectedWallet,
    setNetworkId,
    setIsMetamaskAuthenticated,
  ] = useSelectedWallet(userInfo);



  useEffect(() => {
    if (!userInfo) return;
    (async () => {
      try {
        console.log(userInfo?.company?.id)
        setLoading(true);
        // const tokenDetail = await issuerTokenAdminService.getTokenDetailsByCompanyId(globalCompanyId);
        // console.log("token_details ; ",tokenDetail);

        // const llpaddress = await tokenConfigurationService.getLLPAddress(tokenDetail?.data?.companyDetails?.companyId);
        // console.log("LLP Address = ",llpaddress?.data?.deployedSmartContractAddress);
        // setllpAddress(llpaddress?.data?.deployedSmartContractAddress);

        // const admins = await tokenConfigurationService.getAllAdminsWallets(tokenDetail?.data?.companyDetails?.companyId);
        // console.log("admins : ", admins);
        // const tokensadmin = admins?.data?.llpTokenAdminWalletAddress?.split(',');
        // if (tokensadmin && tokensadmin.length > 0) {
        //   settokenAdminWallet(tokensadmin[0]);
        // }
        // if(tokenDetail.success){
        //   if(tokenDetail.data)
        //   tokenDetail['data'].hasOwnProperty('isTokenSaleClosed') && tokenDetail['data'].isTokenSaleClosed ? 
        //   setIsTokenSaleClosed(true) : setIsTokenSaleClosed(false);
        // }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        throw error;
      }

    })();

    (async () => {
      try {
        setLoading(true);

        // const alreadyIssuedTokenDetails = await issuerTokenAdminService.getIssuedTokenDetailsToInvestorByCompanyId(globalCompanyId)
        // setAlreadyIssuedTokenDetailsList(alreadyIssuedTokenDetails.data)

        const shareholderDetailRes = await issuerTokenAdminService.getShareHolderListByCompanyId(userInfo?.company?.id);
        console.log(shareholderDetailRes.data.shareHolderDetails);
        if (shareholderDetailRes.success) {
          let shareHolderDetails = shareholderDetailRes.data.shareHolderDetails
          // let companyDetails = shareholderDetailRes.data.shareHolderDetails.companyDetails
          // let shareHolderList = shareholderDetailRes.data.shareHolderDetails?.shareHolderList;

          const shData = shareHolderDetails?.map((data, i) => {
            if (data?.hasOwnProperty('tokenCount') && !isNaN(data.tokenCount))
              return {
                shareHolderId: data._id,
                name: data?.name,
                wallet: data?.wallet,
                status: data?.status ? data?.status : '',
                creationTS: data?.creationTS ? new Date(data?.creationTS).toLocaleString() : '',
                tokenCount: data?.tokenCount,
                tokenIssued: data?.hasOwnProperty('tokenIssued') ? data?.tokenIssued.toString() : 'false',
                companyId: userInfo?.company?.id
              }
          }).filter(Boolean);

          console.log("shareholders_data:", shData)
          //set shareholders who's status is approved and tokenIssued === false
          setShareholderData(shData.filter(sh => sh.status === 'approved' && (sh.hasOwnProperty('tokenIssued') || sh.tokenIssued === false)));
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        throw error;
      }

    })();

  }, [userInfo])


  const shareholderCols = [
    {
      title: 'Shareholder Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Wallet Address',
      dataIndex: 'wallet',
      key: 'wallet',
    },
    {
      title: 'Created Date',
      dataIndex: 'creationTS',
      key: 'creationTS',
    },
    {
      title: 'Token Count',
      dataIndex: 'tokenCount',
      key: 'tokenCount',
    },
    {
      title: 'Token Issued',
      dataIndex: 'tokenIssued',
      key: 'tokenIssued',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
  ];

  const getUsers = async () => {
    axios
      .post(
        `${APIURL}/users/getUser`,
        {},
        { headers: await sharedService.getAuthHeader() }
      )
      .then(async (res) => {
        console.log("companyId: ", res);
        getAllPaymentDetailsWithAllStatusByCompanyId(res.data.company.id);
        setGlobalCompanyId(res.data.company.id);
        setGlobalInvestorId(res.data._id);
      });
  };

  const getAllPaymentDetailsWithAllStatusByCompanyId = async (companyId) => {
    return axios
      .get(
        `${APIURL}/issuerSuperAdmin/getAllPaymentDetailsWithAllInvestorDetailsByCompanyId?companyId=${companyId}`,
        { headers: await sharedService.getAuthHeader() }
      )
      .then((response) => {
        console.log("companyId: ", response);
        setUSDTokenPurchases(response.data);
        response.data.forEach((element: any, index: number) => {
          investerIdListArray[index] = element.investorId;
        });
        setGlobalInvestorId(investerIdListArray[0]);
        filteredInvesterIdListArray = investerIdListArray.filter(
          (element, index) => {
            return investerIdListArray.indexOf(element) === index;
          }
        );
        console.log("investerIdListArray: ", investerIdListArray);
        console.log(
          "filteredInvesterIdListArray: ",
          filteredInvesterIdListArray
        );
      });
  };

  const issueTokensToInvestorForLLPCompanyByCompanyId = async () => {
    axios
      .post(
        `${APIURL}/issuerSuperAdmin/issueTokensToInvestorForLLPCompanyByCompanyId?companyId=${globalCompanyId}`,
        {
          investorId: globalInvestorId,
        },
        { headers: await sharedService.getAuthHeader() }
      )
      .then(async (response: any) => {
        console.log("response: ", response);
        if (response.success == true) {
          alert(response.data);
        }
        if (response.success == false) {
          alert(response.error.message);
        }
      });
  };

  const issueTokensToShareholdersAction = async () => {
    try {

      setLoading(true)
      if (selectedShareholders.length <= 0) {
        notification.info({ message: "Please select record(s) first!" });
        setLoading(false)
        return;
      }
      // const onchainstatus = await issueTokenOnchain();
      // if(!onchainstatus) {
      //   setLoading(false);
      //   notification.error({ message: "Error in onchain call" });
      //   return;
      // }

      const res = await Promise.all(
        selectedShareholders?.map(async (data: any) => {
          if (data.tokenIssued == 'true') {
            return false;
          }
          return issuerTokenAdminService.issueTokensToShareHoldersByTAByCompanyId(
            userInfo?.company?.id, data
          );
        })
      );


      if (res.every((r: any) => !r.success)) {
        console.log(res[0] && res[0].error.message)
        message.error(res[0] && res[0].error.message)
        setLoading(false);
        return;
      }

      console.warn(res)
      if (!res[0]) {
        message.info('Tokens already issued')
        setLoading(false);
        return;
      }

      const successCount = res.reduce((c, n: any) => (n.success ? c + 1 : c), 0);
      setLoading(false);
      notification.success({ message: `${successCount} Tokens issued successfully` })
      setTimeout(() => {
        window.location.reload();
        // setShowIssueTokenForm(prev => !prev);
      }, 500);
    } catch (error) {
      setLoading(false);
      notification.error({ message: "Something went wrong" + error })
    }
  }


  // const issueTokenOnchain = async () => {
  //     try{

  //       const walletAddresses = selectedShareholders
  //       .filter((item: any) => item && item.wallet !== undefined && item.tokenIssued != "true")
  //       .map((item: any) => item.wallet) as string[];

  //     const tokenCounts = selectedShareholders
  //       .filter((item: any) => item && item.tokenCount !== undefined && item.tokenIssued != "true")
  //       .map((item: any) => {
  //         const floatValue = parseFloat(item.tokenCount); // Convert to float
  //         const integerValue = BigInt(Math.round(floatValue)) * BigInt(10 ** 18); // Convert to integer
  //         return integerValue.toString();
  //       }) as string[];

  //     console.log(walletAddresses);
  //     console.log(tokenCounts);

  //     const receipt0 =
  //         await tokenConfigurationService.issueTokens(
  //           walletAddresses,
  //           tokenCounts,
  //           llpAddress
  //         );
  //       console.log("call result : ", receipt0);       
  //       if (receipt0.status){
  //         return true;
  //       }else{
  //         console.log("Error in Onchain Token Issuance");
  //         return false;
  //       }

  //     }catch(e){
  //       console.log(e);
  //       return false;
  //     }
  //   }

  const displayIssueTokenForm = (companyData) => {
    setShowIssueTokenForm(true);
    setGlobalCompanyId(companyData?.company?.id);
    console.log('hellow', companyData)
  }



  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedShareholders(selectedRows)
    },
    getCheckboxProps: (record) => {
      return {
        disabled: record.tokenIssued === 'true' || record.status === 'pending'
      };
    }
  };

  return (
    <>


      {<Row justify="center">
        <Col span={24}>
          {(
            <Card>
              <Title level={1} style={{ textAlign: 'center' }}>
                Issue your Security Tokens
              </Title>
              {(
                <>
                  {(
                    <>
                      <Tabs
                        defaultActiveKey="1"
                        style={{ textAlign: 'center' }}
                        type="card"
                        size="large"
                      >
                        <TabPane tab="USD" key="1">
                          <Table
                            rowSelection={rowSelection}
                            scroll={{ x: true }}
                            columns={shareholderCols}
                            dataSource={shareholderData}
                            loading={loading}
                            pagination={false}
                            rowKey="shareHolderId"
                          />

                          <div style={{ textAlign: 'right' }}>
                            <br />
                            <Button
                              size="large"
                              type="primary"
                              //onClick={openTxFeeDelegationModal}
                              onClick={issueTokensToShareholdersAction}

                            >
                              ISSUE TOKENS FOR INVESTORS
                            </Button>
                          </div>
                        </TabPane>
                      </Tabs>
                    </>
                  )}
                </>
              )}
            </Card>
          )}
        </Col>
      </Row>}
    </>
  );
}
