import React, {
  useState,
  useEffect,
} from "react";
import CardHOC from "../CardHOC";
import {
  Button,
  Form,
  Input,
  Spin,
  InputNumber,
  Typography,
  Descriptions,
} from "antd";
import {AuthService} from "../Shared/Auth.service";
import {MetamaskService} from "../Shared/Metamask.service";
import { MasterCompanyDetails, SCField} from "../Shared/interfaces";
import {SharedService} from "../Shared/Shared.service";
import TransactionModal from "../Shared/TransactionModal/TransactionModal";
import MasterCompanyService from "./MasterCompany.service";
const { Title } = Typography;


const masterCompanyService = new MasterCompanyService();
const sharedService = new SharedService();


const useUserContext = () => new AuthService().useUserContext();
const useSelectedWalletContext = () => new MetamaskService().useSelectedWalletContext();


export default function MasterCompany() {
  const {userInfo} = useUserContext();
  const [loading, setLoading] = useState<boolean>(true);
  const {selectedWallet, networkId} = useSelectedWalletContext();
  const [fieldsForm] = Form.useForm();
  const [scFields, setScFields] = useState<SCField[]>();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [transactions, setTransactions] = useState<
    { submitting?: boolean; receipt?: any; details: string }[]
    >([]);

  const [masterCompanyDetails, setMasterCompanyDetails] = useState<MasterCompanyDetails>();
  const [closingTransactionModal, setClosingTransactionModal] = useState(false);
  const [uploading, setUploading] = useState({});
  const [formValues, setFormValues] = useState({
    "Name of Master LLC" : "",
    "Purpose or object of the Company" : "",
    "Place of Business(State)" : "",
    "Name of registered agent" : "",
    "URL of related documents for Master Company" : ""
  })


  useEffect(() => {
    (async () => {
      if(!userInfo || !selectedWallet) return;

      const _masterCompanyDetails = (await masterCompanyService.getMasterCompanyDetails()).data;
      setMasterCompanyDetails(_masterCompanyDetails);

      const res = (await masterCompanyService.getMasterCompanyDetails()).data;
      if(res){
        setFormValues({
          "Name of Master LLC" : res.companyName,
          "Purpose or object of the Company" : res.objectOfCompany,
          "Place of Business(State)" : res.placeOfBusiness,
          "Name of registered agent" : res.firstName + ' ' + res.lastName,
          "URL of related documents for Master Company" : ""
        })
      }

      await getForm();

      setLoading(false);
    })();
  }, [userInfo, selectedWallet]);


  const getForm = async() => {
    const _scFields = (await masterCompanyService.getFields()).data as SCField[];

    setScFields(_scFields);
  };

  const uploadFile = async(e: React.ChangeEvent<HTMLInputElement>, creationTS: number) => {
    if(!e.target.files) return;

    const file =  e.target.files[0];
    console.log(file);

    if(!file) return;

    setUploading(prev => {
      const current = sharedService.clone(prev);
      current[creationTS] = true;
      return current;
    });

    fieldsForm.setFieldsValue(({[creationTS]: null}));

    const {hash} = await sharedService.uploadDocumentToIPFS({file});
    fieldsForm.setFieldsValue(({[creationTS]: hash}));

    setUploading(prev => {
      const current = sharedService.clone(prev);
      current[creationTS] = false;
      return current;
    });
  }



  const deployMasterContract = async(formValue) => {
    const _formValue = {};
    console.log(formValues);

    Object.keys(formValues).forEach(key => {
      if(scFields?.find(field => field.creationTS === +key)?.saveInContract) {
        _formValue[key] = formValues[key];
      }
    });

    setIsModalVisible(true);
    setTransactions([
      { details: 'Deploying Master Company Contract', submitting: true },
      { details: 'Saving Details', submitting: false }
    ]);

    try {
      const receipt1 = await masterCompanyService.deploy(selectedWallet as string);

      setTransactions((prev) => {
        const current = sharedService.clone(prev);
        current[0].submitting = false;
        current[0].receipt = receipt1;
        return current;
      });

      setTransactions((prev) => {
        const current = sharedService.clone(prev);
        current[1].submitting = true;
        return current;
      });

      const receipt2 = await masterCompanyService.saveFieldAnswers(
        receipt1.contractAddress as string,
        selectedWallet as string,
        Object.keys(_formValue),
        Object.values(_formValue)
        // Object.values(encrypted).map(value => sharedService.stringToBytes32(value as string))
      );

      setTransactions((prev) => {
        const current = sharedService.clone(prev);
        current[1].submitting = false;
        current[1].receipt = receipt2;
        return current;
      });

      await masterCompanyService.saveMasterCompanyDetails({
        contractAddress: receipt1.contractAddress as string,
        answers: Object.entries(formValues)
      });

    } catch (e) {
      console.error(e);
    }

    setTransactions((prev) => {
      const current = sharedService.clone(prev);
      current.forEach(c => c.submitting = false);
      return current;
    });
  }

  const closeTransactionModal = async() => {
    setClosingTransactionModal(true);
    await getForm();
    const _masterCompanyDetails = (await masterCompanyService.getMasterCompanyDetails()).data;
    setMasterCompanyDetails(_masterCompanyDetails);
    setClosingTransactionModal(false);
    setIsModalVisible(false);
  }

  return (
    <CardHOC
      heading={'Master Company Details'}
      component={<>

        {loading &&
          <div style={{textAlign:'center'}}>
            <br/><br/>
            <Spin size='large'/>
          </div>
        }

        {!loading &&
          <>
            {masterCompanyDetails &&
              <>

                <Title
                  level={2}
                  style={{ fontWeight: "bold", marginBottom: "0" }}
                >
                  Master Company Contract Address
                </Title>
                <a
                  href={`${sharedService.etherscanURL[networkId as string]}/address/${masterCompanyDetails.contractAddress}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Title
                    style={{ color: "royalblue", textDecoration: "underline" }}
                    level={3}
                  >
                    {masterCompanyDetails.contractAddress}
                  </Title>
                </a>

                <br/><br/>

                <Descriptions bordered column={2}>
                  {scFields?.map(scField => (
                    <React.Fragment key={scField._id}>
                      {scField['answer'] &&
                      <Descriptions.Item span={2} label={scField.label}>
                        {scField.type !== 'file' && scField['answer']}

                        {scField.type === 'file' &&
                          <a href={`${sharedService.getIPFSDocumentURL({hash: scField['answer']})}`}
                             target="_blank"
                             rel="noopener noreferrer"
                          >
                            {scField['answer']}
                          </a>
                        }
                      </Descriptions.Item>
                      }
                    </React.Fragment>
                  ))}
                </Descriptions>

              </>
            }

            {!masterCompanyDetails &&
              <>

                <Form
                  form={fieldsForm}
                  autoComplete={'off'}
                  onFinish={deployMasterContract}
                >
                  {scFields?.length
                    ?
                    <Title level={3}> Fill Form</Title>
                    :
                    <Title level={3}>No Fields Saved yet</Title>
                  }

                  {scFields?.map(scField => (

                    scField.type === 'text'
                      ?
                      <Form.Item
                        label={scField.label}
                        key={scField._id}
                        wrapperCol={{ xs: { span: 24 }, sm: { span: 14 } }}
                        rules={[
                          {
                            required: scField.isRequired,
                            message: "This field is required",
                          },
                        ]}
                      >
                        <Input placeholder={scField.label} value={formValues[scField.label]}
                         onChange={(e)=>setFormValues({...formValues, [scField.label] : e.target.value})}/>
                      </Form.Item>
                      :

                      scField.type === 'text area'
                        ?
                        <Form.Item
                          label={scField.label}
                          key={scField._id}
                          wrapperCol={{ xs: { span: 24 }, sm: { span: 14 } }}
                          rules={[
                            {
                              required: scField.isRequired,
                              message: "This field is required",
                            },
                          ]}
                        >
                          <Input.TextArea placeholder={scField.label} value={formValues[scField.label]}/>
                        </Form.Item>
                        :

                        scField.type === 'number'
                          ?
                          <Form.Item
                            name={scField.label}
                            label={scField.label}
                            key={scField._id}
                            wrapperCol={{ xs: { span: 24 }, sm: { span: 14 } }}
                            rules={[
                              {
                                required: scField.isRequired,
                                message: "This field is required",
                              },
                            ]}
                          >
                            <InputNumber placeholder={scField.label}/>
                          </Form.Item>
                          :

                          scField.type === 'file'
                            ?
                            <Form.Item
                              name={scField.creationTS}
                              label={scField.label}
                              key={scField._id}
                              wrapperCol={{ xs: { span: 24 }, sm: { span: 14 } }}
                              rules={[
                                {
                                  required: scField.isRequired,
                                  message: "This field is required",
                                },
                              ]}
                            >
                              <Input type='file' onChange={e => { uploadFile(e, scField.creationTS) }} />

                              {uploading[scField.creationTS] && 'Uploading to IPFS'}

                              <Form.Item
                                  style={{ marginBottom: 0 }}
                                 shouldUpdate={(prevValues, currentValues) => prevValues.creationTS !== currentValues.creationTS}
                              >
                                {
                                  ({ getFieldValue }) =>
                                    getFieldValue(scField.creationTS) && 'Uploaded to IPFS'
                                }
                              </Form.Item>

                              {/*{rule144Files.natureOfBusinessFile.uploadFilePercentage>0 &&*/}
                              {/*  <Progress percent={rule144Files.natureOfBusinessFile.uploadFilePercentage} />*/}
                              {/*}*/}
                            </Form.Item>

                            :null

                  ))}

                  <div style={{ textAlign: 'center' }}>
                    {!!scFields?.length &&
                    <Button
                      size="large"
                      type="primary"
                      htmlType={'submit'}
                    >
                      CREATE MASTER COMPANY CONTRACT
                    </Button>
                    }

                  </div>
                </Form>

              </>
            }

          </>
        }

        <TransactionModal
          title={'Master Contract'}
          transactions={transactions}
          isModalVisible={isModalVisible}
          closingModal={closingTransactionModal}
          closeModal={closeTransactionModal}
        />

      </>}
    />
  )
}