import { notification } from "antd";
import { AuthService } from "../../../Shared/Auth.service";
import { achInputs } from "./AchUSA/AchWireUsa";
import { MailInputs } from "./MailCheque/MailCheque";
import { escrowFields } from "./EscrowCrypto/EscrowCrypto";


type PaymentType = 'USBankDetail' | 'escrowCrypto' | 'mailACheck'
type MetaData = {title:string,columns:[any],key:string,visibleCols:string[]}
export class PaymentHandlerService{

    constructor(private paymentType: PaymentType,private paymentApiService){
      // this.validatePaymentType()
    }

    validatePaymentType(){
      if(!['USBankDetail', 'escrowCrypto', 'mailACheck'].includes(this.paymentType)){
        throw new Error('Invalid payment type provided')
      }
    }

    public async savePaymentInfo(payload:unknown,companyID){

        const response = await this.paymentApiService.savePaymentDetailsByPaymentTypeAndCompanyId(companyID,this.paymentType,payload);
  
        if (response.success) {
          notification.success({message: 'Success', description: 'Payment Info was saved successfully'});
          return true
          
        } else {
          notification.error({message: 'Error', description: response.error.message});
          return false
          
        }
    }

    public async sendForApproval(companyID) {
        try {
          const res = await this.paymentApiService.sendPaymentDetailsForApprovalByPaymentTypeAndCompanyId(companyID,this.paymentType)
          if(!res.success){
            notification.error({message: 'Error', description: res.error.message});
            return false;
          }
            notification.success({message: 'Success', description: 'Payment Info send for approval successfully'});
            return true;
        } catch (error) {
          console.log(error)
          notification.error({message: 'Error', description: error});
        }
      }

    public async loadData(companyID){
        try{
            const _paymentinfo = (await this.paymentApiService.getPaymentDetailsByPaymentTypeAndCompanyId(companyID,this.paymentType));
              if(_paymentinfo?.success && _paymentinfo.data?._id){
                return _paymentinfo
              }else{
                return false
              }
        }catch(error){
            throw error
        }
    }

    public getMetaData():MetaData{
     const meta = {
        'crypto-escrow':{
          title:'Escrow Crypto',
          columns: escrowFields,
          visibleCols:['bankName','walletAddress','cryptoType',"escrowProviderName",'escrowAccountNumber'],
          key:'escrowCrypto'
        },
        'us-bank':{
          title: 'Bank Details',
          columns: achInputs,
          visibleCols:['bankName','bankAddress','confirmationOfAuthorization','email'],
          key:'USBankDetail'
        },
        'mail-a-cheque':{
          title: 'Mail a Cheque',
          columns: MailInputs,
          visibleCols:["checkNumber","mailingAddress","checkNumber","email"],
          key:'mailACheck'
        },
      }

      return meta[this.paymentType]
    }

    public async approveOrRejectPaymentInfo(companyID,payload,role,paymentTypeKey){
      return await this.paymentApiService.approveOrRejectPaymentInfo(companyID,payload,role,paymentTypeKey)
    }

    public async getPaymentDetailsByPaymentTypeForMCAOrPSA(paymentTypeKey){
      return await this.paymentApiService.getPaymentDetailsByPaymentTypeForMCAOrPSA(paymentTypeKey)
    }

    public async getPaymentDetailsByPaymentTypeAndCompanyId(companyId,paymentTypeKey){
      return await this.paymentApiService.getPaymentDetailsByPaymentTypeAndCompanyId(companyId,paymentTypeKey)
    }

}