import React, { Component, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo/logo.png";
import Config from "../../configure";
import { AuthService } from './../../components/Shared/Auth.service';
import { SharedService } from '../../components/Shared/Shared.service';
// import logo from "../../assets/images/logo/logo.png";
import $ from "jquery";
import "./index.css";
import { Dropdown, Menu, Space } from 'antd';
import profileIcon from "../../assets/profile.png"
import mpStyle from '../marketplaceHeader/MarketplaceHeader.module.scss'
import { environment } from "../../environments/environment";

const useUserContext = () => new AuthService().useUserContext();
const sharedService = new SharedService();
class OuterHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navMenuMobile: false,
      redirect: false,
      userInfo: null,
    };
  }
  toggleNavMenu = () => {

    this.setState({ navMenuMobile: !this.state.navMenuMobile });
  };
  handleScroll = () => {
    if (this.mount) {
      this.setState({ scroll: window.scrollY });
    }
  };

  logout = async() => {
    this.setState({ userInfo: null});;
    sharedService.deleteToken();
    window.location.href = '/';
  }

  componentDidMount() {
    this.mount = true;
    const el = document.querySelector(".gc_main_menu_wrapper");
    this.setState({ top: el.offsetTop + 700, height: el.offsetHeight, userInfo: sharedService.getToken() });
    window.addEventListener("scroll", this.handleScroll);
  }
  componentDidUpdate() {
    this.state.scroll > this.state.top
      ? (document.body.style.paddingTop = `${this.state.height}px`)
      : (document.body.style.paddingTop = "0");
  }
  componentWillUnmount() {
    this.mount = false;
  }
  
  render() {
    $(document).ready(() => {
      $('.wd_single_index_menu ul li a[href^="#"]').bind(
        "click",
        function (event) {
          event.preventDefault();
          event.stopPropagation();
          var $anchor = $(this);
          if ($(window).width() > 991) {
            var headerH = "60";
          } else {
            headerH = "56";
          }
          $("html, body")
            .stop()
            .animate(
              {
                scrollTop:
                  $($anchor.attr("href")).offset().top - headerH + "px",
              },
              800
            );
        }
      );
      $(window).scroll(function () {
        var windscroll = $(window).scrollTop();
        var target = $(".wd_single_index_menu ul li");
        if (windscroll >= 0) {
          $(".wd_scroll").each(function (i) {
            if ($(this).position().top <= windscroll + 90) {
              target.removeClass("active");
              target.eq(i).addClass("active");
            }
          });
        } else {
          target.removeClass("active");
          $(".wd_single_index_menu ul li:first").addClass("active");
        }
      });
    });

    // const aboutMenu = (
    //   <Menu>
    //     <Menu.Item>
    //       <Link to="/about-us" onClick={()=>window.scroll({top:0,left:0})} target="_blank" rel="noopener noreferrer">
    //         About Us
    //       </Link>
    //     </Menu.Item>
    //     <Menu.Item>
    //       <Link to="/mission-vision">
    //         Vision & Mission
    //       </Link>
    //     </Menu.Item>
    //     <Menu.Item>
    //       <Link to="/corporate-responsibility">
    //         Corporate Responsibility
    //       </Link>
    //     </Menu.Item>
    //   </Menu>
    // );
    // const resourceMenu = (
    //   <Menu>
    //     <Menu.Item>
    //       <Link to="/how-it-works" target="_blank" rel="noopener noreferrer">
    //         How it works
    //       </Link>
    //     </Menu.Item>
    //     <Menu.Item >
    //       <Link to="/faq" target="_blank" rel="noopener noreferrer">
    //         FAQ
    //       </Link>
    //     </Menu.Item>
    //     <Menu.Item>
    //       <Link to="/knowledge-center" target="_blank" rel="noopener noreferrer">
    //       Knowldege Center
    //       </Link>
    //     </Menu.Item>
    //     <Menu.Item>
    //       {/* <Link to="#">
    //       Learn
    //       </Link> */}
    //     </Menu.Item>
    //     <Menu.Item>
    //       <Link to="/career" target="_blank" rel="noopener noreferrer">
    //         Career
    //       </Link>
    //     </Menu.Item>
    //   </Menu>
    // );


    let navigation = (
      <ul className="navbar-nav me-auto mb-2 mb-lg-0 menu-search">
         {environment.companyName !== 'vastu' && environment.companyName !== 'reet' && (
        <a className="home" style={{ fontWeight: "bolder" }} href="/"><li>Home</li></a>)}
       
        {/* {environment.companyName !== 'vastu' && environment.companyName !== 'reet' && (
        <li className="home" style={{fontWeight: 'bold' }}>
          <Dropdown overlay={aboutMenu}>
          <Link >
              <Space>
                About
                <DownOutlined />
              </Space>
              </Link>
          </Dropdown>
        </li>
        )} */}

        <a className="home" style={{ fontWeight: "bolder" }} href="/marketplace"><li>Marketplace</li></a>
        {/* {environment.companyName !== 'vastu' && environment.companyName !== 'reet' && environment.companyName !== 'reet' && (
        <Link className="home" style={{ fontWeight: "bolder"}} to="/list-your-property"><li>List Property</li></Link>
        )} */}
       
        <Link className="home" style={{ fontWeight: "bolder"}} to="/faq"><li>FAQ</li></Link>
        
        
       {/* {environment.companyName !== 'vastu' && environment.companyName !== 'reet' && (<li className="home" style={{fontWeight: 'bold' }}>
          <Dropdown overlay={resourceMenu}>
          <Link >
              <Space>
                Resources
                <DownOutlined />
              </Space>
              </Link>
          </Dropdown>
        </li>)} */}
        {/* <Link className="home" style={{ fontWeight: "bolder" }} to="/features" target="_blank" rel="noopener noreferrer"><li>Features</li></Link> */}

        {this.state.userInfo ?
          <>
            <Link
              className="home"
              style={{ fontWeight: "bolder" }}
              to="/"
              onClick={this.logout}
            >
              <span className="login">
                <img style={{ height: "20px", alignSelf: "center" }} src={profileIcon}></img>
                <span style={{ color: "black" ,paddingLeft:'5px'}}>LogOut</span>
              </span>
            </Link>
          </>
          :
          <>
            <Link
              className="home"
              style={{ fontWeight: "bolder" }}
              to="/account/login"
            >
              <span className="login">
                <img style={{ height: "20px", alignSelf: "center" }} src={profileIcon}></img>
                <span style={{ color: "rgba(0, 0, 0, 0.61)",paddingLeft:'5px' }}>Login</span>
              </span>
            </Link>

            <Link to="/account/register/investor">
              <span className="register">Register</span>
            </Link>
          </>
        }

      </ul>
    );
    return (
      <div id="default" className={ `${mpStyle.uNav} wd_scroll_wrap wd_scroll`} style={{marginTop:'0px'}}>
        <header
          className={`gc_main_menu_wrapper ${
            this.state.scroll > this.state.top
              ? "menu_fixed animated fadeInDown"
              : "" 
          }`} 
        >
          <Container fluid >
            <Row>
            <nav className="navbar navbar-expand-lg  " style={{height:'65px', marginTop:'54px !important'}}>
                <div className={`container-fluid align-items-baseline `}
            >
                  <div className="col-0 col-md-1"></div>
                  {(environment.companyName === 'vastu' || environment.companyName === 'reet') && (
                     <span style={{color: "#102253", fontWeight: "500", fontSize: "32px"}}>{environment.headerTitle}</span>
                  )}
                  {environment.companyName !== 'vastu' && environment.companyName !== 'reet' && (
                  <Link to={Config.defaultPath} style={{alignSelf: "center"}}>
                    <span style={{color: "#102253", fontWeight: "500", fontSize: "32px"}}>{environment.headerTitle}
                    {environment.companyName === 'vruddhix' && (
                      <span  style={{color: "#6bd346"}}>USA</span>
                    )}
                    </span>
                  </Link>
                  )}
                  <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
                    {navigation}
                  </div>
                </div>
              </nav>
          
          
            </Row>
          </Container>
        </header>
      </div>
    );
  }
}

export default OuterHeader;
