 import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import {
  Button,
  Input,
  Row,
  Card,
  Col,
  Form,
  message,
  notification,
  Progress,
  Spin,
  Space,
  Modal
} from "antd";
import { IssuerSuperAdminService } from '../IssuerSuperAdmin.service';
import { SharedService } from '../../Shared/Shared.service';
import './AddDocument.scss';
import { environment } from "../../../environments/environment";
import { AuthService } from '../../Shared/Auth.service';


const issuerSuperAdminService = new IssuerSuperAdminService();
const sharedService = new SharedService();
const useUserContext = () => new AuthService().useUserContext();


export default function AddDocuments(props) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [editView, setEditView] = useState(false);
  const [enableEdit, setEnableEdit] = useState(false);
  const InputArrMap = [ {value:'propertyTitleSearchReport', label:'Property Title Search Report'},
                        {value:'privatePlacementFile', label:'Private Placement Memorandum'},
                        {value:'subscriptionAgreementFile', label:'Subscription Agreement'},
                        {value:'financialProjectionsFile', label:'Financial Projection'},
                        {value:'operatingAgreementFile', label:'Operating Agreement'},
                        {value:'propertyDetailsFile', label:'Property Details (Key Facts)'},
                        {value:'otherDocumentsFile', label:'Other Documents File'},
                       
                    ]
  const [propertyFiles, setPropertyFiles] = useState(
    {
      ...(InputArrMap.map(x => 
                ({[x.value]: {
                  uploading: false,
                  uploadFilePercentage: 0,
                  URL: null,
                  name: null,
                  type: null
                }})
      ))
      
    }
  );

  const [rejectReason, setRejectReason] = useState("");
  const [showRejectPopup, setShowRejectPopup] = useState(false);
  const [propertyInfoStatus, setPropertyInfoStatus] = useState('');
  const [sendForApprovalVisibility, setSendForApprovalVisibility] = useState(false);
  const { userInfo } = useUserContext();


  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 12 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 }
    }
  };
  
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0
        },
        sm: {
          span: 14,
          offset: 10
        }
      }
    };

  const [uploadFiles, setUploadFiles] = useState(props.propertyInfo);
  const [privatePlacementFile, setPrivatePlacementFile] = useState(props.propertyInfo?.privatePlacementFile);
  const [subscriptionAgreementFile, setSubscriptionAgreementFile] = useState(props.propertyInfo?.subscriptionAgreementFile);
  const [financialProjectionsFile, setFinancialProjectionsFile] = useState(props.propertyInfo?.financialProjectionsFile);
  const [operatingAgreementFile, setOperatingAgreementFile] = useState(props.propertyInfo?.operatingAgreementFile);
  const [propertyDetailsFile, setPropertyDetailsFile] = useState(props.propertyInfo?.propertyDetailsFile);
  const [otherDocumentsFile, setOtherDocumentsFile] = useState(props.propertyInfo?.otherDocumentsFile)
  const [propertyTitleSearchReport, setPropertyTitleSearchReport] = useState(props.propertyInfo?.propertyTitleSearchReport ? props.propertyInfo?.propertyTitleSearchReport :[])

    // multi upload screen
    const multiUploadFile = async (e, name) => {
      if (!e.target.files) return;
      const file = e.target.files[0];
      if (!file) return;
      const fileType = sharedService.getFileType(file.name);
      if(!enableEdit) setEnableEdit(true);
      
      try {
        const { hash } = await sharedService.uploadDocumentToIPFS({ file });
        const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
        const uploadedFile = { URL, uploading: false, uploadFilePercentage: 100, name: file.name, type: fileType.toLowerCase() };
    
        switch (name) {
          case 'private':
            setPrivatePlacementFile(prev => [uploadedFile, ...prev]); // Prepend new file
            form2.setFieldsValue({ [name]: file.name });
            break;
          case 'subscription':
            setSubscriptionAgreementFile(prev => [uploadedFile, ...prev]); // Prepend new file
            form2.setFieldsValue({ [name]: file.name });
            break;
          case 'financial':
            setFinancialProjectionsFile(prev => [uploadedFile, ...(prev ? prev : [])]); // Prepend new file
            form2.setFieldsValue({ [name]: file.name });
            break;
          case 'operating':
            setOperatingAgreementFile(prev => [uploadedFile, ...prev]); // Prepend new file
            form2.setFieldsValue({ [name]: file.name });
            break;
          case 'property':
            setPropertyDetailsFile(prev => [uploadedFile, ...prev]); // Prepend new file
            form2.setFieldsValue({ [name]: file.name });
            break;
          case 'other':
            setOtherDocumentsFile(prev => [uploadedFile, ...prev]); // Prepend new file
            form2.setFieldsValue({ [name]: file.name });
            break;
          case 'propertyTitleSearchReport':
            setPropertyTitleSearchReport(prev => [uploadedFile, ...prev]); // Prepend new file
            form2.setFieldsValue({ [name]: file.name });
            break;
          default:
            return;
        }
      } catch (err) {
        console.error(err);
        switch (name) {
          case 'private':
            setPrivatePlacementFile(prev => [...prev, { uploading: false }]);
            break;
          case 'subscription':
            setSubscriptionAgreementFile(prev => [...prev, { uploading: false }]);
            break;
          case 'financial':
            setFinancialProjectionsFile(prev => [...prev, { uploading: false }]);
            break;
          case 'operating':
            setOperatingAgreementFile(prev => [...prev, { uploading: false }]);
            break;
          case 'property':
            setPropertyDetailsFile(prev => [...prev, { uploading: false }]);
            break;
          case 'other':
            setOtherDocumentsFile(prev => [...prev, { uploading: false }]);
            break;
          case 'propertyTitleSearchReport':
            setPropertyTitleSearchReport(prev => [...prev, { uploading: false }]);
            break;
          default:
            return;
        }
      }
    }

    useEffect(() => {
      console.log('props.propertyInfo: ', props.propertyInfo);
      if(props.documentsDone){
        setSendForApprovalVisibility(true)
        console.log('status ', props.propertyInfo.status)
        setPropertyInfoStatus(props.propertyInfo.status ?? '')
      }
    }, [])

  const uploadFile = async (e, key) => {
    if (!e.target.files) return;
    const file = e.target.files[0];
    if (!file) return;
    const fileType = sharedService.getFileType(file.name);

    setPropertyFiles((prev) => ({ [key]: { URL: null, ...prev[key] }, ...prev }));

    try{
      setPropertyFiles((prev) => {
        return ({ ...prev, [key]: { ...prev[key], uploading: true, uploadFilePercentage: 10 } });
      });
      const {hash} = await sharedService.uploadDocumentToIPFS({file});
      const URL = `${environment.ipfsDomain}/ipfs/${hash}`;
      setPropertyFiles((prev) => ({ ...prev, [key]: { ...prev[key], URL, uploading: false, uploadFilePercentage: 100, name: file.name, type: fileType.toLowerCase() } }));
      form.setFieldsValue({ [key]: file.name });
    }catch(err){
      console.error(err);
      setPropertyFiles((prev) => ({ ...prev, [key]: { ...prev[key], uploading: false } }));
    }

  }

  const updateUploadedDocuments = async (_form2) => {
    const form2 = { ..._form2 };
    setLoading(true);

    form2.privatePlacementFile = privatePlacementFile.map(file=>({URL : file.URL, name : file.name, type : file.type}));
    form2.subscriptionAgreementFile = subscriptionAgreementFile.map(file=>({URL : file.URL, name : file.name, type : file.type}));
    form2.financialProjectionsFile = financialProjectionsFile.map(file=>({URL : file.URL, name : file.name, type : file.type}));
    form2.operatingAgreementFile = operatingAgreementFile.map(file=>({URL : file.URL, name : file.name, type : file.type}));
    form2.propertyDetailsFile = propertyDetailsFile.map(file=>({URL : file.URL, name : file.name, type : file.type}));
    form2.otherDocumentsFile = otherDocumentsFile.map(file=>({URL : file.URL, name : file.name, type : file.type}));
    form2.propertyTitleSearchReport = propertyTitleSearchReport.map(file=>({URL : file.URL, name : file.name, type : file.type}));
    form2.userId = props.userInfo._id;
    form2.propertyDocuments='done';

    const response = await issuerSuperAdminService.updateUploadedDocuments(form2);

    if (response.success) {
      history.push('/issuer-super-admin/add-property/basic-information');
      notification.success({ message: 'Success' });
    } else {
      message.error(response.error.message);
      notification.error({message: 'Error', description: 'response.error.message'});
    }

    props.setUpdated(true);
    setLoading(false);
    //window.location.reload();
  }

  const submitUploadDocuments = async (_form) => {
    const form = { ..._form };
    setLoading(true);

    form.privatePlacementFile = propertyFiles.privatePlacementFile?.URL ? [{
      name: propertyFiles.privatePlacementFile?.name,
      URL: propertyFiles.privatePlacementFile?.URL,
      type: propertyFiles.privatePlacementFile.type,
    }] : [];
    form.subscriptionAgreementFile = propertyFiles.subscriptionAgreementFile?.URL ? [{
      name: propertyFiles.subscriptionAgreementFile?.name,
      URL: propertyFiles.subscriptionAgreementFile?.URL,
      type: propertyFiles.subscriptionAgreementFile.type,
    }] : [];
    form.financialProjectionsFile = propertyFiles.financialProjectionsFile?.URL ? [{
      name: propertyFiles.financialProjectionsFile?.name,
      URL: propertyFiles.financialProjectionsFile?.URL,
      type: propertyFiles.financialProjectionsFile.type,
    }] : [];
    form.operatingAgreementFile = propertyFiles.operatingAgreementFile?.URL ? [{
      name: propertyFiles.operatingAgreementFile?.name,
      URL: propertyFiles.operatingAgreementFile?.URL,
      type: propertyFiles.operatingAgreementFile.type,
    }] : [];
    form.propertyDetailsFile = propertyFiles.propertyDetailsFile?.URL ? [{
      name: propertyFiles.propertyDetailsFile?.name,
      URL: propertyFiles.propertyDetailsFile?.URL,
      type: propertyFiles.propertyDetailsFile.type,
    }] : [];
    form.otherDocumentsFile = propertyFiles.otherDocumentsFile?.URL ? [{
      name: propertyFiles.otherDocumentsFile?.name,
      URL: propertyFiles.otherDocumentsFile?.URL,
      type: propertyFiles.otherDocumentsFile.type,
    }] : [];
    form.propertyTitleSearchReport = propertyFiles.propertyTitleSearchReport?.URL ? [{
      name: propertyFiles.propertyTitleSearchReport?.name,
      URL: propertyFiles.propertyTitleSearchReport?.URL,
      type: propertyFiles.propertyTitleSearchReport.type,
    }] : [];
    // form.minInvestmentFile ={
    //   name: propertyFiles.minInvestmentFile?.name,
    //   URL: propertyFiles.minInvestmentFile?.URL,
    //   type: propertyFiles.minInvestmentFile.type,
    // };
    // form.titleClearanceFile = propertyFiles.titleClearanceFile?.URL ? {
    //   name: propertyFiles.titleClearanceFile?.name,
    //   URL: propertyFiles.titleClearanceFile?.URL,
    //   type: propertyFiles.titleClearanceFile.type,
    // } : null ;
    // form.titleDocumentsFile = propertyFiles.titleDocumentsFile?.URL ? {
    //   name: propertyFiles.titleDocumentsFile?.name,
    //   URL: propertyFiles.titleDocumentsFile?.URL,
    //   type: propertyFiles.titleDocumentsFile.type,
    // } : null ;
    // form.floorPlanFile = propertyFiles.floorPlanFile?.URL ? {
    //   name: propertyFiles.floorPlanFile?.name,
    //   URL: propertyFiles.floorPlanFile?.URL,
    //   type: propertyFiles.floorPlanFile.type,
    // } : null ;
    // form.mortgageDeedsFile = propertyFiles.mortgageDeedsFile?.URL ? {
    //   name: propertyFiles.mortgageDeedsFile?.name,
    //   URL: propertyFiles.mortgageDeedsFile?.URL,
    //   type: propertyFiles.mortgageDeedsFile.type,
    // } : null ;
    // form.leanDocumentFile = propertyFiles.leanDocumentFile?.URL ? {
    //   name: propertyFiles.leanDocumentFile?.name,
    //   URL: propertyFiles.leanDocumentFile?.URL,
    //   type: propertyFiles.leanDocumentFile.type,
    // } : null ;
    // form.constructionTimelineFile = propertyFiles.constructionTimelineFile?.URL ? {
    //   name: propertyFiles.constructionTimelineFile?.name,
    //   URL: propertyFiles.constructionTimelineFile?.URL,
    //   type: propertyFiles.constructionTimelineFile.type,
    // } : null ;
    // form.constructionBudgetFile = propertyFiles.constructionBudgetFile?.URL ? {
    //   name: propertyFiles.constructionBudgetFile?.name,
    //   URL: propertyFiles.constructionBudgetFile?.URL,
    //   type: propertyFiles.constructionBudgetFile.type,
    // } : null ;
    // form.otherDocumentFile = propertyFiles.otherDocumentFile?.URL ? {
    //   name: propertyFiles.otherDocumentFile?.name,
    //   URL: propertyFiles.otherDocumentFile?.URL,
    //   type: propertyFiles.otherDocumentFile.type,
    // } : null ;

    form.userId = props.userInfo._id;
    form.companyId = props.userInfo?.company?.id;
    form.propertyDocuments='done';
    form.stepDone=4;

    const response = await issuerSuperAdminService.savePropertyDocuments(form);

    if (response.success) {
      notification.success({ message: 'Success' });
      history.push('/issuer-super-admin/add-property/basic-information')
    } else {
      message.error(response.error.message);
      notification.error({message: 'Error', description: 'response.error.message'});
    }

    props.setUpdated(true);
    setLoading(false);
    window.location.reload();
  }

  const sendPropertyDetailsForApproval  = async()=>{
    try {
      if(!userInfo) return;

      setLoading(true);
      const propertyId = props.propertyInfo?._id
      const res = await issuerSuperAdminService.sendPropertyDetailsForApprovalToMSA(userInfo?.company?.id , propertyId);
      if(!res.success){
        notification.error({ message: 'Error', description: res?.error?.message })
        setLoading(false);
        return;
      }
      notification.success({ message: 'Success', description: 'Property details sent for approval successfully.'});
      setTimeout(()=> window.location.reload(), 1000);
      setLoading(false);

    } catch (error) {
      console.log(error)
      setLoading(false);
    }
  }
                
    const approveOrReject = async(status) =>{
    try {
      let payload
      const propertyId = props.propertyInfo?._id
      if(status == 'rejected'){
        if(rejectReason == ''){
            notification.info({message: 'Info', description: 'Provide valid rejection message'});
            return;
          }
        setShowRejectPopup(false)
      }
      if(props.userInfo.role === 'issuer compliance admin'){
        payload = {isIssuerComplianceAdminStatus:status,issuerComplianceAdminRejectionMessage:rejectReason}
      }else if(props.userInfo.role === 'master company super admin'){
        payload = {isMarketplaceSuperAdminStatus:status,marketplaceSuperAdminRejectionMessage:rejectReason}
      }
      
      let companyId = userInfo?.company?.id
      if(userInfo?.role == 'master company super admin'){
        const id =sessionStorage.getItem('companyId')
        if(id){
          companyId = id  
        }
      }
      const res = await issuerSuperAdminService.approveOrRejectPropertyDetails(companyId,propertyId,payload)
      console.log(res)
      if(res.success){
        notification.success({message: 'Success', description: `Property info ${status} successfully`});
        setTimeout(() => {
          window.location.reload();
        }, 3000);   
      }else{
        notification.error({message: 'Error', description: res.error.message});
      }

    } catch (error) {
      console.log(error);
      notification.error({message:'error', description:error})
    }
  }

return(
  <>
  {loading &&
        <div style={{textAlign:'center'}}>
          <br/><br/>
          <Spin size='large'/>
        </div>
      }
  {!loading && !props.documentsDone && (<Row justify="center">
    <Col span={20}>
      <h2 style={{ color: 'darkblue', fontSize: '20px', marginTop: '20px', fontWeight: 'bold' }}>Offering Document</h2>
      <Form {...formItemLayout} labelAlign="left" form={form} initialValues={{ boardResolution: { option: 'upload' }, additionInfo: null }} onFinish={submitUploadDocuments}>
          <div>
            <Card>
            <Form.Item
                label="Property Title Search Report"
                name='propertyTitleSearchReport'
                rules={[
                  {
                    required: true,
                    message: 'This field is required'
                  }
                ]}>
                <>
                  <Input type='file' onChange={e => { uploadFile(e, 'propertyTitleSearchReport') }} />
                  {propertyFiles.propertyTitleSearchReport?.uploadFilePercentage > 0 &&
                    <Progress percent={propertyFiles.propertyTitleSearchReport?.uploadFilePercentage} />
                  }
                </>
              </Form.Item>
              <Form.Item
                label="Private Placement Memorandum"
                name='privatePlacementFile'
                rules={[
                  {
                    required: false,
                    message: 'This field is required'
                  }
                ]}>
                <>
                  <Input type='file' onChange={e => { uploadFile(e, 'privatePlacementFile') }} />
                  {propertyFiles.privatePlacementFile?.uploadFilePercentage > 0 &&
                    <Progress percent={propertyFiles.privatePlacementFile?.uploadFilePercentage} />
                  }
                </>
              </Form.Item>
              {/* <Form.Item
                label="WhitePaper- Business profile document"
                name='whitePaperFile'
                rules={[
                  {
                    required: true,
                    message: 'This field is required'
                  }
                ]}>
                <>
                  <Input type='file' onChange={e => { uploadFile(e, 'whitePaperFile') }} />
                  {propertyFiles.whitePaperFile?.uploadFilePercentage > 0 &&
                    <Progress percent={propertyFiles.whitePaperFile?.uploadFilePercentage} />
                  }
                </>
              </Form.Item> */}
              <Form.Item
                label="Subscription Agreement"
                name='subscriptionAgreementFile'
                rules={[
                  {
                    required: false,
                    message: 'This field is required'
                  }
                ]}>
                <>
                  <Input type='file' onChange={e => { uploadFile(e, 'subscriptionAgreementFile') }} />
                  {propertyFiles.subscriptionAgreementFile?.uploadFilePercentage > 0 &&
                    <Progress percent={propertyFiles.subscriptionAgreementFile?.uploadFilePercentage} />
                  }
                </>
              </Form.Item>
              <Form.Item
                label="Financial Projection"
                name='financialProjectionsFile'
                rules={[
                  {
                    required: false,
                    message: 'This field is required'
                  }
                ]}>
                <>
                  <Input type='file' onChange={e => { uploadFile(e, 'financialProjectionsFile') }} />
                  {propertyFiles.financialProjectionsFile?.uploadFilePercentage > 0 &&
                    <Progress percent={propertyFiles.financialProjectionsFile?.uploadFilePercentage} />
                  }
                </>
              </Form.Item>
              <Form.Item
                label="Operating Agreement"
                name='operatingAgreementFile'
                rules={[
                  {
                    required: props.propertyType==='farm' ? true : false,
                    message: 'This field is required'
                  }
                ]}>
                <>
                  <Input type='file' onChange={e => { uploadFile(e, 'operatingAgreementFile') }} />
                  {propertyFiles.operatingAgreementFile?.uploadFilePercentage > 0 &&
                    <Progress percent={propertyFiles.operatingAgreementFile?.uploadFilePercentage} />
                  }
                </>
              </Form.Item>
              <Form.Item
                label="Property Details (Key Facts)"
                name='propertyDetailsFile'
                rules={[
                  {
                    required: false,
                    message: 'This field is required'
                  }
                ]}>
                <>
                  <Input type='file' onChange={e => { uploadFile(e, 'propertyDetailsFile') }} />
                  {propertyFiles.propertyDetailsFile?.uploadFilePercentage > 0 &&
                    <Progress percent={propertyFiles.propertyDetailsFile?.uploadFilePercentage} />
                  }
                </>
              </Form.Item>
              <Form.Item
                label="Other Documents File"
                name='otherDocumentsFile'
                rules={[
                  {
                    required: false,
                    message: 'This field is required'
                  }
                ]}>
                <>
                  <Input type='file' onChange={e => { uploadFile(e, 'otherDocumentsFile') }} />
                  {propertyFiles.otherDocumentsFile?.uploadFilePercentage > 0 &&
                    <Progress percent={propertyFiles.otherDocumentsFile?.uploadFilePercentage} />
                  }
                </>
              </Form.Item>
              
              {/* <Form.Item
                label="Min Investment"
                name='minInvestmentFile'
                rules={[
                  {
                    required: true,
                    message: 'This field is required'
                  }
                ]}>
                <>
                  <Input type='file' onChange={e => { uploadFile(e, 'minInvestmentFile') }} />
                  {propertyFiles.minInvestmentFile?.uploadFilePercentage > 0 &&
                    <Progress percent={propertyFiles.minInvestmentFile?.uploadFilePercentage} />
                  }
                </>
              </Form.Item> */}
              {/* <h3 className="property-details">Property Details Documents</h3>
              <Form.Item
                label="Title clearance certificate"
                name='titleClearanceFile'
                rules={[
                  {
                    required: false,
                    message: 'This field is required'
                  }
                ]}>
                <>
                  <Input type='file' onChange={e => { uploadFile(e, 'titleClearanceFile') }} />
                  {propertyFiles.titleClearanceFile?.uploadFilePercentage > 0 &&
                    <Progress percent={propertyFiles.titleClearanceFile?.uploadFilePercentage} />
                  }
                </>
              </Form.Item>
              <Form.Item
                label="Title Document"
                name='titleDocumentsFile'
                rules={[
                  {
                    required: false,
                    message: 'This field is required'
                  }
                ]}>
                <>
                  <Input type='file' onChange={e => { uploadFile(e, 'titleDocumentsFile') }} />
                  {propertyFiles.titleDocumentsFile?.uploadFilePercentage > 0 &&
                    <Progress percent={propertyFiles.titleDocumentsFile?.uploadFilePercentage} />
                  }
                </>
              </Form.Item>
              <Form.Item
                label="Floor Plan"
                name='floorPlanFile'
                rules={[
                  {
                    required: false,
                    message: 'This field is required'
                  }
                ]}>
                <>
                  <Input type='file' onChange={e => { uploadFile(e, 'floorPlanFile') }} />
                  {propertyFiles.floorPlanFile?.uploadFilePercentage > 0 &&
                    <Progress percent={propertyFiles.floorPlanFile?.uploadFilePercentage} />
                  }
                </>
              </Form.Item>
              <Form.Item
                label="Mortgage deeds if any"
                name='mortgageDeedsFile'
                rules={[
                  {
                    required: false,
                    message: 'This field is required'
                  }
                ]}>
                <>
                  <Input type='file' onChange={e => { uploadFile(e, 'mortgageDeedsFile') }} />
                  {propertyFiles.mortgageDeedsFile?.uploadFilePercentage > 0 &&
                    <Progress percent={propertyFiles.mortgageDeedsFile?.uploadFilePercentage} />
                  }
                </>
              </Form.Item>
              <Form.Item
                label="Any lean document"
                name='leanDocumentFile'
                rules={[
                  {
                    required: false,
                    message: 'This field is required'
                  }
                ]}>
                <>
                  <Input type='file' onChange={e => { uploadFile(e, 'leanDocumentFile') }} />
                  {propertyFiles.leanDocumentFile?.uploadFilePercentage > 0 &&
                    <Progress percent={propertyFiles.leanDocumentFile?.uploadFilePercentage} />
                  }
                </>
              </Form.Item>
              <Form.Item
                label="Construction Timeline"
                name='constructionTimelineFile'
                rules={[
                  {
                    required: false,
                    message: 'This field is required'
                  }
                ]}>
                <>
                  <Input type='file' onChange={e => { uploadFile(e, 'constructionTimelineFile') }} />
                  {propertyFiles.constructionTimelineFile?.uploadFilePercentage > 0 &&
                    <Progress percent={propertyFiles.constructionTimelineFile?.uploadFilePercentage} />
                  }
                </>
              </Form.Item>
              <Form.Item
                label="Construction budget"
                name='constructionBudgetFile'
                rules={[
                  {
                    required: false,
                    message: 'This field is required'
                  }
                ]}>
                <>
                  <Input type='file' onChange={e => { uploadFile(e, 'constructionBudgetFile') }} />
                  {propertyFiles.constructionBudgetFile?.uploadFilePercentage > 0 &&
                    <Progress percent={propertyFiles.constructionBudgetFile?.uploadFilePercentage} />
                  }
                </>
              </Form.Item>
              <Form.Item
                label="Other document"
                name='otherDocumentFile'
                rules={[
                  {
                    required: false,
                    message: 'This field is required'
                  }
                ]}>
                <>
                  <Input type='file' onChange={e => { uploadFile(e, 'otherDocumentFile') }} />
                  {propertyFiles.otherDocumentFile?.uploadFilePercentage > 0 &&
                    <Progress percent={propertyFiles.otherDocumentFile?.uploadFilePercentage} />
                  }
                </>
              </Form.Item> */}
            </Card>
          </div>

          <br /><br />
          <Form.Item {...tailFormItemLayout}>
            <Button size='large' style={{ marginLeft: '10px' }} type='primary' htmlType="submit" loading={loading}>Submit</Button>
          </Form.Item>
          <br /><br />

      </Form>
    </Col>
  </Row>)}

  {props.documentsDone && !loading && !editView &&
    <div>
        <Row justify="center">
            <Col span={16}>
                <Card>
                    {props.userInfo.role === 'master company super admin' && props.propertyInfo?.isIssuerComplianceAdminStatus !== 'approved' ? (
                        <h3 style={{height: "50vh",display: "flex",alignItems: "center"}}>Property Compliance Admin is reviewing the property</h3>
                    ) : (
                        <>
                            {InputArrMap.map((input, index) => (
                                <Row key={index} className="mb-3">
                                    <Col span={20}>
                                        <div>
                                            <sub style={{ color: '#666770', fontFamily: 'Arial, Helvetica, sans-serif', textAlign: 'left', fontSize: '15px' }}>
                                                {input.label} :
                                            </sub>
                                            <sub style={{ color: '#050505', fontSize: '20px', textAlign: 'left' }}>
                                                {uploadFiles[input.value]?.length > 0 ? (
                                                    <a
                                                        href={uploadFiles[input.value][uploadFiles[input.value].length - 1]?.URL}
                                                        download={uploadFiles[input.value][uploadFiles[input.value].length - 1]?.name}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {uploadFiles[input.value][uploadFiles[input.value].length - 1]?.name}
                                                    </a>
                                                ) : (
                                                    <span>No Documents</span>
                                                )}
                                            </sub>
                                        </div>
                                    </Col>
                                </Row>
                            ))}

                            {!props.disableEdit && (
                                <Space style={{ display: "block", float: 'right' }}>
                                    <Button
                                        type="primary"
                                        onClick={() => setEditView(true)}
                                    >
                                        Edit Documents
                                    </Button>
                                </Space>
                            )}

                            {(props.userInfo?.role === 'issuer company manager' && sendForApprovalVisibility) && 
                                <Button
                                    type="primary"
                                    style={{ float: 'right', marginRight: '5px' }}
                                    disabled={propertyInfoStatus?.toLowerCase() === 'pending' || propertyInfoStatus?.toLowerCase() === 'approved'}
                                    onClick={() => sendPropertyDetailsForApproval()}
                                >
                                    {propertyInfoStatus?.toLowerCase() === 'approved' ? 'Approved' : 'Send For Approval'}
                                </Button>
                            }

                            {(['issuer compliance admin', 'master company super admin'].includes(props.userInfo.role)) &&
                                <>
                                    <Space style={{ float: 'right', marginRight: '5px' }}>
                                        {props.userInfo.role === 'issuer compliance admin' && propertyInfoStatus?.toLowerCase() !== 'rejected' && 
                                            <Button
                                                type="primary"
                                                onClick={() => approveOrReject('approved')}
                                                disabled={propertyInfoStatus?.toLowerCase() === 'approved' || propertyInfoStatus?.toLowerCase() === 'rejected' || props.propertyInfo?.isIssuerComplianceAdminStatus === 'approved'}
                                            >
                                                {propertyInfoStatus?.toLowerCase() === 'approved' || props.propertyInfo?.isIssuerComplianceAdminStatus === 'approved' ? 'Approved' : 'Approve'}
                                            </Button>
                                        }

                                        {props.userInfo.role === 'issuer compliance admin' && propertyInfoStatus?.toLowerCase() !== 'approved' && 
                                            <Button
                                                type="primary"
                                                className="custom-danger-button"
                                                disabled={propertyInfoStatus?.toLowerCase() === 'rejected'}
                                                onClick={() => setShowRejectPopup(true)}
                                            >
                                                {propertyInfoStatus?.toLowerCase() === 'rejected' ? 'Rejected' : 'Reject'}
                                            </Button>
                                        }

                                        {props.userInfo.role === 'master company super admin' && props.propertyInfo?.isIssuerComplianceAdminStatus === 'approved' &&
                                            <>
                                                <Button
                                                    type="primary"
                                                    onClick={() => approveOrReject('approved')}
                                                    disabled={propertyInfoStatus?.toLowerCase() === 'approved' || props.propertyInfo?.isMarketplaceSuperAdminStatus === 'approved'}
                                                >
                                                    {props.propertyInfo?.isMarketplaceSuperAdminStatus === 'approved' ? 'Approved' : 'Approve'}
                                                </Button>

                                                <Button
                                                    type="primary"
                                                    className="custom-danger-button"
                                                    onClick={() => setShowRejectPopup(true)}
                                                    disabled={propertyInfoStatus?.toLowerCase() === 'rejected'}
                                                >
                                                    {propertyInfoStatus?.toLowerCase() === 'rejected' ? 'Rejected' : 'Reject'}
                                                </Button>
                                            </>
                                        }
                                    </Space>
                                </>
                            }
                        </>
                    )}
                </Card>
            </Col>
        </Row>
    </div>
}


      {props.documentsDone && !loading && editView && (
        <Row justify="center">
          <Col span={20}>
            <h2 style={{ color: 'darkblue', fontSize: '20px', marginTop: '20px', fontWeight: 'bold' }}>Edit Offering Document</h2>
            <Form {...formItemLayout} labelAlign="left" form={form2} onFinish={updateUploadedDocuments} className="edit-form">
                <Card>
                <Form.Item
                    label="Property Title Search Report"
                    name='propertyTitleSearchReport'
                    rules={[
                      {
                         required: !propertyTitleSearchReport || propertyTitleSearchReport.length === 0,
                        message: 'This field is required'
                      }
                    ]}>
                    <>
                      <Input type='file' onChange={e => { multiUploadFile(e, 'propertyTitleSearchReport') }} />
                      {propertyTitleSearchReport && propertyTitleSearchReport[propertyTitleSearchReport?.length - 1]?.uploadFilePercentage > 0 &&
                        <Progress percent={propertyTitleSearchReport[propertyTitleSearchReport?.length - 1]?.uploadFilePercentage} />
                      }
                      <div style={{}}>
                        <p>Files Uploaded:</p>
                        {propertyTitleSearchReport?.length > 0 && propertyTitleSearchReport?.map((file)=><h4 key={Math.random()}>{file.name}</h4>)}
                      </div>
                    </>
                  </Form.Item>
                  <Form.Item
                    label="Private Placement Memorandum"
                    name='privatePlacementFile'
                    rules={[
                      {
                        required: false,
                        message: 'This field is required'
                      }
                    ]}>
                    <>
                      <Input type='file' onChange={e => { multiUploadFile(e, 'private') }} />
                      {privatePlacementFile[privatePlacementFile.length - 1]?.uploadFilePercentage > 0 &&
                        <Progress percent={privatePlacementFile[privatePlacementFile.length - 1]?.uploadFilePercentage} />
                      }
                      <div style={{}}>
                        <p>Files Uploaded:</p>
                        {privatePlacementFile?.length > 0 && privatePlacementFile.map((file)=><h4 key={Math.random()}>{file.name}</h4>)}
                      </div>
                    </>
                  </Form.Item>
                  <Form.Item
                    label="Subscription Agreement"
                    name='subscriptionAgreementFile'
                    rules={[
                      {
                        required: false,
                        message: 'This field is required'
                      }
                    ]}>
                    <>
                      <Input type='file' onChange={e => { multiUploadFile(e, 'subscription') }} />
                      {subscriptionAgreementFile[subscriptionAgreementFile.length - 1]?.uploadFilePercentage > 0 &&
                        <Progress percent={subscriptionAgreementFile[subscriptionAgreementFile.length - 1]?.uploadFilePercentage} />
                      }
                      <div style={{}}>
                        <p>Files Uploaded:</p>
                        {subscriptionAgreementFile?.length > 0 && subscriptionAgreementFile.map((file)=><h4 key={Math.random()}>{file.name}</h4>)}
                      </div>
                    </>
                  </Form.Item>
                  <Form.Item
                    label="Financial Projection"
                    name='financialProjectionsFile'
                    rules={[
                      {
                        required: false,
                        message: 'This field is required'
                      }
                    ]}>
                    <>
                      <Input type='file' onChange={e => { multiUploadFile(e, 'financial') }} />
                      {financialProjectionsFile[financialProjectionsFile.length - 1]?.uploadFilePercentage > 0 &&
                        <Progress percent={financialProjectionsFile[financialProjectionsFile.length - 1]?.uploadFilePercentage} />
                      }
                      <div style={{}}>
                        <p>Files Uploaded:</p>
                        {financialProjectionsFile?.length > 0 && financialProjectionsFile.map((file)=><h4 key={Math.random()}>{file.name}</h4>)}
                      </div>
                    </>
                  </Form.Item>
                  <Form.Item
                    label="Operating Agreement"
                    name='operatingAgreementFile'
                    rules={[
                      {
                        required: false,
                        message: 'This field is required'
                      }
                    ]}>
                    <>
                      <Input type='file' onChange={e => { multiUploadFile(e, 'operating') }} />
                      {operatingAgreementFile[operatingAgreementFile.length - 1]?.uploadFilePercentage > 0 &&
                        <Progress percent={operatingAgreementFile[operatingAgreementFile.length - 1]?.uploadFilePercentage} />
                      }
                      <div style={{}}>
                        <p>Files Uploaded:</p>
                        {operatingAgreementFile?.length > 0 && operatingAgreementFile.map((file)=><h4 key={Math.random()}>{file.name}</h4>)}
                      </div>
                    </>
                  </Form.Item>
                  <Form.Item
                    label="Property Details (Key Facts)"
                    name='propertyDetailsFile'
                    rules={[
                      {
                        required: false,
                        message: 'This field is required'
                      }
                    ]}>
                    <>
                      <Input type='file' onChange={e => { multiUploadFile(e, 'property') }} />
                      {propertyDetailsFile[propertyDetailsFile.length - 1]?.uploadFilePercentage > 0 &&
                        <Progress percent={propertyDetailsFile[propertyDetailsFile.length - 1]?.uploadFilePercentage} />
                      }
                      <div style={{}}>
                        <p>Files Uploaded:</p>
                        {propertyDetailsFile?.length > 0 && propertyDetailsFile.map((file)=><h4 key={Math.random()}>{file.name}</h4>)}
                      </div>
                    </>
                  </Form.Item>
                  <Form.Item
                    label="Other Documents File"
                    name='otherDocumentsFile'
                    rules={[
                      {
                        required: false,
                        message: 'This field is required'
                      }
                    ]}>
                    <>
                      <Input type='file' onChange={e => { multiUploadFile(e, 'other') }} />
                      {otherDocumentsFile[otherDocumentsFile.length - 1]?.uploadFilePercentage > 0 &&
                        <Progress percent={otherDocumentsFile[otherDocumentsFile.length - 1]?.uploadFilePercentage} />
                      }
                      <div style={{}}>
                        <p>Files Uploaded:</p>
                        {otherDocumentsFile?.length > 0 && otherDocumentsFile.map((file)=><h4 key={Math.random()}>{file.name}</h4>)}
                      </div>
                    </>
                  </Form.Item>
                  
                </Card>

                <br /><br />
                <Form.Item {...tailFormItemLayout}>
                  <Button size='large' disabled={!enableEdit} style={{ marginLeft: '10px' }} type='primary' htmlType="submit" loading={loading}>Submit</Button>
                </Form.Item>
                <br /><br />
            </Form>
          </Col>

          
                
        </Row>
      )}
      
      <Modal
        title="Reject Property Info"
        visible={showRejectPopup}
        onCancel={() => setShowRejectPopup(false)}
        footer={[
          <Button key="back" onClick={() => { setShowRejectPopup(false); }}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={() => approveOrReject('rejected')}>
            Submit
          </Button>,
        ]}
      >
        <Input
          placeholder="Reason for rejection"
          value={rejectReason}
          onChange={(e) => setRejectReason(e.target.value)}
        />
      </Modal>    
  </>

  
)
};