import { MetamaskService } from '../../../Metamask.service';
import { TrustedForwarderService } from '../../../TrustedForwarder.service';
import ERC20Facet from '../ERC20Facet';



const trustedForwarderService = new TrustedForwarderService();
export default class EscrowFacetService {
    contract = {
        ABI: require('./ABI.json')
    };

    private get web3() {
        return MetamaskService.web3;
    }

    async getTotalInvestedAmountsInEscrow(tokenAddress: string, depositAddress : string []) {
        const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
        return (await contractInstance.methods.getTotalInvestedAmounts(depositAddress).call() as []);
    }
    async getListedDepositTokens(tokenAddress: string) {
        const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
        return (await contractInstance.methods.getDepositTokens().call()) as [];
    }
    async withdrawFromContract(tokenAddress: string, owner: string, receiverAccount: string, _amount: string) {
        const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
        return contractInstance.methods.withdrawFromContract(receiverAccount, _amount).send({ from: owner });
    }
    async investToEscrow(tokenAddress: string, owner: string, depositTokenAddress: string, _amount: number) {
        const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
        return contractInstance.methods.investInSecurities(depositTokenAddress, Math.round(_amount)).send({ from: owner });
    }
    async withdrawApproval(tokenAddress: string, owner: string) {
        const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
        return contractInstance.methods.approveWithdrawal().send({ from: owner });
    }
    async addPriceFeed(
        tokenAddress: string,
        owner: string,
        depositTokens: string[],
        aggregators: string[],
        extra: { delegate: boolean } = { delegate: false }
    ) {
        const contractInstance = new this.web3.eth.Contract(
            this.contract.ABI,
            tokenAddress
        );
        const method = contractInstance.methods.addPriceFeeds(
            depositTokens,
            aggregators);

        if (!extra.delegate) return method.send({ from: owner });

        return trustedForwarderService.delegateTransaction({
            encodedFunction: method.encodeABI(),
            contractAddress: tokenAddress,
            signerAddress: owner,
        });
    }
    async removePriceFeed(tokenAddress: string, owner: string, depositTokens : string []) {
        const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
        return contractInstance.methods.removePriceFeeds(depositTokens).send({ from: owner });
    }
    async withdrawCollectedAmountFromContract(tokenAddress: string, owner: string, depositTokenAddress: string) {
        const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
        return contractInstance.methods.withdrawCollectedAmount(depositTokenAddress).send({ from: owner });
     }
     
    async getTokenPricePerUSD(tokenAddress: string,depositTokenAddress : string, usdAmount : number) {
        const contractInstance = new this.web3.eth.Contract(this.contract.ABI, tokenAddress);
        let price = await contractInstance.methods.getDepositTokenPrice(depositTokenAddress).call()
        let depositTokenDecimals = await new ERC20Facet().decimals(depositTokenAddress);
        let amount = (usdAmount * Math.pow(10,Number(price['decimals'])) * Math.pow(10,Number(depositTokenDecimals)))/Number(price['value'])
        return amount;
    }
};
