import React, { useEffect, useState } from 'react'
import { Row, Col, Card, Typography, Form, Input, Button, notification, Spin, Descriptions } from 'antd';
import { AuthService } from '../../../../Shared/Auth.service';
import { PaymentHandlerService } from '../PaymentHandlerService';
import { IssuerSuperAdminService } from '../../../IssuerSuperAdmin.service';
const {Title} = Typography;
const useUserContext = () => new AuthService().useUserContext()

export const MailInputs = [
    {name:'payeeName',label:'Payee Name',type:'default'},
    {name:'mailingAddress',label:'Mailing Address',type:'default'},
    {name:'checkNumber',label:'Check Number',type:'default'},
    {name:'amount',label:'Amount',type:'default'},
    {name:'description',label:'Purpose/Description',type:'textarea'},
    {name:'email',label:'Email',type:'default'},
]

const Switch = (props:any) => {
    const { test, children } = props
    // console.log(children,test)
    // return <></>
    return children.find(child => {
      return child.props.className === test
    })      
  }     

const MailCheque = (props) => {
    const [submitting, setSubmitting] = useState(false);
    const [paymentInfo, setPaymentInfo] = useState<any>();
    const [isEditionMode, setIsEditionMode] = useState(false);
    const [isApproved, setApproved]= useState(false)
    const { userInfo } = useUserContext();
    const [disableEdit, setDisableEdit]= useState(false)
    const [loading, setLoading] = useState(false);
    const [MailChequeForm] = Form.useForm();

    const paymenthandlerservice = new PaymentHandlerService('mailACheck',new IssuerSuperAdminService())
    const companyId = userInfo?.role === 'investor' ? props.token.issuerInfo[0].companyId : userInfo?.company?.id

    useEffect(() => {
      (async () => {
        setLoading(true);
        const paymentnfo = await paymenthandlerservice.loadData(companyId)
        console.log(paymentnfo)
        if (paymentnfo) {
          setPaymentInfo(paymentnfo.data);
          setIsEditionMode(true);

          if (paymentnfo.data?.status == 'approved' || paymentnfo.data?.status == 'pending') {
              setDisableEdit(true);
          } 
        }

        setLoading(false);
      })();
    }, [isApproved]);
      
    const savePaymentInfo = async(formValue) => {
      setSubmitting(true);
      const response = await paymenthandlerservice.savePaymentInfo(formValue,userInfo?.company?.id)
      if(response){
        setPaymentInfo(formValue);
        setIsEditionMode(true);
        setDisableEdit(false);
      }
      setSubmitting(false);
    }
    
    const sendForApproval = async() => {
        const response = await paymenthandlerservice.sendForApproval(userInfo?.company?.id)
        setApproved(prev => !prev)
    }

    return (
        <>
          <br/><br/>
          <Row justify="center">
            <Col span={20}>
              {loading &&  
                <div style={{textAlign:'center'}}>
                  <br/>
                  <Spin size='large'/>
                </div>
              }
              {!loading && 
                <Card>
                  <Title level={2} style={{textAlign:'center'}}>Mail a Cheque</Title>
                  <br/>
                  
                   <Form labelAlign="left" form={MailChequeForm} initialValues={ isEditionMode? paymentInfo:undefined} onFinish={savePaymentInfo}>
                      
                   { MailInputs.map((input,index) => (
                      <Form.Item 
                      key={index}
                        label={input.label}
                        labelCol={{xs: { span: 24 }, sm: {span: 10, push: 2 }}}
                        wrapperCol={{xs: { span: 24 }, sm: {span: 8 }}}
                        rules={[
                          {
                            required: true, 
                            message: 'This field is required', 
                            whitespace: true
                          }
                        ]}
                        name={input.name}>
                        
                        { input.type == 'default'?
                            <Input disabled={isEditionMode}/>
                            : 
                            <Input.TextArea disabled={isEditionMode}/>
                        }
                       

                      </Form.Item>
                   ))
                    }
                
                {userInfo?.role !== "investor" && 
                  <Row justify='end'>
                      {isEditionMode && <Button size='large' type='primary' disabled={disableEdit} onClick={sendForApproval}>
                        {paymentInfo?.status == "approved"
                          ? "APPROVED"
                          : "SEND FOR APPROVAL"}
                      </Button>}
                      &nbsp;&nbsp;
                      {isEditionMode && <Button size='large' type='primary' onClick={()=>setIsEditionMode(false)}>
                        EDIT
                      </Button>}
                      &nbsp;&nbsp;
                      {!isEditionMode && <Button htmlType='submit' size='large' type='primary' loading={false}>
                        SAVE
                      </Button>}
                        </Row>}

                    </Form>

                </Card>
              }
            </Col>
          </Row>
        </>
      );
}

export default MailCheque