import React from 'react'
import { Switch, Route, useRouteMatch } from "react-router-dom";
import AIVerificationRequest from './AIVerificationRequest'
import InvestorPaymentDetails from './InvestorPaymentDetails'
import AllowCountries from '../IssuerTokenAdmin/AllowCountries';
import TokenManagment from './TokenManagment';
import RemoveLegend from './RemoveLegend';
import IssueTokensToShareholders from './issueTokensToShareholders/issueTokensToShareholders';
import IssueTokens from '../IssuerComplianceAdmin/IssueTokensToInvestors';

export default () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/ai-verification-request`}>
      <AIVerificationRequest/>
      </Route>
      <Route path={`${path}/transfer-restriction/select-countries`}>
        <AllowCountries />
      </Route>
      <Route path={`${path}/investor-payment-details`}>
      <InvestorPaymentDetails/>
      </Route>
      <Route path={`${path}/token-managment`}>
        <TokenManagment/>
      </Route>
      <Route path={`${path}/issue-tokens-to-shareholders`}>
        {/* <IssueTokens/> */}
        <IssueTokensToShareholders/>
      </Route>
      <Route path={`${path}/issue-tokens-to-investors`}>
        <IssueTokens/>
      </Route>
      <Route path={`${path}/remove-legend/:tokenConfigurationProcessId`}>
        <RemoveLegend/>
      </Route>
    </Switch>
  )
}
