import React,{ useEffect, useState }  from 'react';
import { Card, Typography, Form, Input, Spin, Button, Space, Row,notification, Col,Tooltip, Layout } from 'antd';
import { useHistory } from 'react-router-dom';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { IssuerSuperAdminService } from '../IssuerSuperAdmin.service';
import { AuthService } from '../../Shared/Auth.service';

const { Title } = Typography;

const issuerSuperAdminService = new IssuerSuperAdminService();
const useUserContext = () => new AuthService().useUserContext();

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
};

const tailLayout = {
  wrapperCol: { xs: { offset: 0, span: 24 }, sm: { offset: 8, span: 16 } },
};


export default function ManageUsers() {
  const [form] = Form.useForm();
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const { userInfo } = useUserContext();
  const [isAlreadyData, setIsAlreadyData] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const response = await issuerSuperAdminService.getIssuerManagementFees(userInfo?.company?.id);
      setLoading(false);
      if (response.success && response.data) {
        form.setFieldsValue(response.data);
        setIsAlreadyData(true); 
      }
    };
    fetchData();
  }, [form, userInfo]);


  const saveManagementFee = async(_form) =>  {
    setLoading(true);
    let response;
    const formData = { ..._form, companyId: userInfo?.company?.id };

    if (isAlreadyData) {
      // Update data
      formData.isUpdate = true; 
      response = await issuerSuperAdminService.saveIssuerManagementFees(userInfo?.company?.id, formData);
    } else {
      // Save new data
      response = await issuerSuperAdminService.saveIssuerManagementFees(userInfo?.company?.id, formData);
    }
    setLoading(false);
    if (response.success) {
      notification.success({
        message: 'Success',
        duration: 0,
      });
      setIsEditMode(false);
      setTimeout(()=>window.location.reload(), 500);

    } else {
      notification.error({
        message: 'Error',
        description: response.error.message,
        duration: 0,
      });
    }
  };


  return (
    <Layout>
      
    <Layout.Content style={{ height: '90vh', overflowY: 'auto' }}>
      <Title level={2} style={{ textAlign: 'left', marginLeft: '25px', marginTop: '10px' }}>Management Fee</Title>
      <Card className="rounded-card">
      {loading &&
          <div style={{textAlign:'center'}}>
            <br/><br/>
            <Spin size='large'/>
          </div>
        }
      {!loading &&
        <>
        <Form
          {...formItemLayout}
          form={form}
          onFinish={saveManagementFee}
          style={{ padding: '10px', margin: '0 auto' }}
          labelAlign='left'
        >
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <Form.Item
               label={
                <span>
                  LLC Maintenance Fee &nbsp;
                  <Tooltip title="Monthly fees for maintaining the LLC.">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
                colon={false}
                name="monthlyFees"
                rules={[{ required: true, message: 'This field is required.' }]}
              >
                <Input size="large" addonAfter="$"  type="number" disabled={isAlreadyData && !isEditMode}/>
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                label={
                  <span>
                    Acquisition Fee &nbsp;
                    <Tooltip title="Fee charged at the time of acquiring a property. ">
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
                colon={false}
                name="acquisitionFee"
                rules={[{ required: true, message: 'This field is required.' }]}
              >
                <Input size="large" addonAfter="$" type="number" disabled={isAlreadyData && !isEditMode}/>
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                label={
                  <span>
                    Deposition Fee &nbsp;
                    <Tooltip title="Fee charged at the time of selling a property.(of the property sale price).">
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
                colon={false}
                name="depositionFee"
                rules={[{ required: true, message: 'This field is required.' }]}
              >
                <Input size="large" addonAfter="$"  type="number"  disabled={isAlreadyData && !isEditMode}/>
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                label={
                  <span>
                    Asset Management Fee &nbsp;
                    <Tooltip title="  Ongoing fees for managing the property assets.(of the asset value annually).">
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
                colon={false}
                name="assetManagementFee"
                rules={[{ required: true, message: 'This field is required.' }]}
              >
                <Input size="large" addonAfter="$"  type="number" disabled={isAlreadyData && !isEditMode} />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                 label={
                  <span>
                      Compliance Training &nbsp;
                    <Tooltip title=" Fee for training asset owners on compliance requirements. ">
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
                colon={false}
                name="complianceTrainingFee"
                rules={[{ required: true, message: 'This field is required.' }]}
              >
                <Input size="large" addonAfter="$"  type="number" disabled={isAlreadyData && !isEditMode}/>
              </Form.Item>
            </Col>
            {/* <Col xs={24}>
              <Form.Item
                 label={
                  <span>
                    Commission on Property Sales &nbsp;
                    <Tooltip title=" A percentage of the sales price when the property is sold. ">
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
                colon={false}
                name="commissionOnPropertySales"
                rules={[{ required: true, message: 'This field is required.' }]}
              >
                <Input size="large" addonAfter="$"  type="number" disabled={isAlreadyData && !isEditMode}/>
              </Form.Item>
            </Col> */}
            <Col xs={24}>
              <Form.Item
                 label={
                  <span>
                    Monthly Marketplace Fee &nbsp;
                    <Tooltip title="  Ongoing fees for accessing the marketplace. ">
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
                colon={false}
                name="monthlyMarketplaceFee"
                rules={[{ required: true, message: 'This field is required.' }]}
              >
                <Input size="large" addonAfter="$"  type="number" disabled={isAlreadyData && !isEditMode}/>
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                 label={
                  <span>
                     Marketplace Management KYC Fee per Person &nbsp;
                    <Tooltip title=" Fee for KYC verification and management. ">
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
                colon={false}
                name="marketplaceManagementKYCFee"
                rules={[{ required: true, message: 'This field is required.' }]}
              >
                <Input size="large" addonAfter="$"  type="number"  disabled={isAlreadyData && !isEditMode}/>
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                 label={
                  <span>
                    User KYC Fee per Person &nbsp;
                    <Tooltip title="Fee for individual KYC verification.">
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
                colon={false}
                name="userKYCFee"
                rules={[{ required: true, message: 'This field is required.' }]}
              >
                <Input size="large" addonAfter="$"  type="number" disabled={isAlreadyData && !isEditMode}/>
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                 label={
                  <span>
                   Yearly Fee to DP (Designated Partner) &nbsp;
                    <Tooltip title="Annual fees charged to the Designated Partner for their role in managing the LLC and ensuring compliance. ">
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
                colon={false}
                name="yearlyFeeDP"
                rules={[{ required: true, message: 'This field is required.' }]}
              >
                <Input size="large" addonAfter="$"  type="number" disabled={isAlreadyData && !isEditMode}/>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item {...tailLayout}>
            <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {!isEditMode && isAlreadyData && (
                      <Button type="primary" onClick={() => setIsEditMode(true)}>
                        Edit
                      </Button>
                    )}
                    {!isAlreadyData && (
                      <Button type="primary" htmlType="submit">
                        Save
                      </Button>
                    )}
                    {isEditMode && (
                      <>
                        <Button type="primary" htmlType="submit">
                          Update
                        </Button>
                        <Button onClick={() => setIsEditMode(false)}>
                          Cancel
                        </Button>
                      </>
                    )}
            </Space>
          </Form.Item>
        </Form>
        </>
      }
      </Card>
   </Layout.Content>
   </Layout>
  );
}