import { Button, Col, Row, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useHistory, Link} from 'react-router-dom';
import { Spin,Tooltip } from "antd";
import { Table } from "antd";
import { Container, TabPane } from "reactstrap";
import queryString from 'query-string';
import sign from "../../assets/images/icons/tick.png";
//import "./site-common.min.css";
import "./index.css";
import "./PropertyDetails.css";
import pimage from "../../assets/images/icons/home2.jpg"
import { Carousel } from "antd";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { ArrowLeftOutlined } from "@ant-design/icons";
import Item from "antd/lib/list/Item";
// import { Link } from "react-feather";
import { VideoPlayer } from "../mainSlider/VideoPlayer";
import { PropertyAdminService } from "./PropertyAdmin.service";
import { AuthService } from '../Shared/Auth.service';
import { SharedService } from "../Shared/Shared.service";
import { InfoCircleOutlined } from '@ant-design/icons';
import { IssuerSuperAdminService } from "../IssuerSuperAdmin/IssuerSuperAdmin.service";

const propertyAdminService = new PropertyAdminService();
const sharedService = new SharedService();
const issuerSuperAdminService = new IssuerSuperAdminService();
const useUserContext = () => new AuthService().useUserContext();

const stopHandler = (e) => { };

const PropertyDetails = () => {
  const { userInfo } = useUserContext();
  const [properties, setProperties] = useState([]);
  const [propertyDetails, setPropertyDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const {propertyId} = queryString.parse(location.search);
  const [data1, setData1] = useState([]);
  const [tabNo, setTabNo] = useState(1);
  const [offeringText, setOfferingText] = useState([]);
  const [offeringImages, setOfferingImages] = useState([]);
  const history = useHistory();
  const [showDashboardOption, setShowDashboardOption] = useState(false);
  const tabNames = ['Summary', 'Sponsor', 'Diligence Report', 'Financials', 'Location', 'Documents', 'FAQs','Due-Diligence','Management Fee'];
  const [feeManagement, setFeeManagement] = useState([{}]);
  
  const renderURL = (object) => {
    const newObject = object[0]?.URL;
    return newObject;
  };
  const renderName = (object) => {
    const newObject = object[0]?.name;
    return newObject;
  };

  const columns = [
    {
      title: "Question",
      dataIndex: "question",
      render: (value) => value,
    },
    {
      title: "Answer",
      dataIndex: "answer",
      render: (value) =>
        typeof value != "object" ? (
          value
        ) : (
          <span>{<a target="_blank" style={{ textDecoration: "underline" }} href={renderURL(value)}>{renderName(value)}</a>}</span>
        ),
    },
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
    (async function loadProperties(){
      setLoading(true);
      if(propertyId && propertyId.length > 0){
        const propertyDetails = await propertyAdminService.getPropertyById(propertyId);
        if(propertyDetails && propertyDetails.data && propertyDetails.data.length > 0){
          setPropertyDetails(propertyDetails.data[0]);
          console.log(propertyDetails.data[0]);
          let ab = propertyDetails.data[0];
          setData1([propertyDetails.data[0].targetedInvestorIrr, propertyDetails.data[0].targetedSumContribution, propertyDetails.data[0].periodOfSale, "Initial","31 Dec, 2021", propertyDetails.data[0]?.nameOfToken, propertyDetails.data[0].tokenSymbol, propertyDetails.data[0].typeOfToken, propertyDetails.data[0].tokenStandard]);
          setOfferingText([ab.distributionStrategy, ab.financials, ab.sources, ab.organizationalChart, ab.fees])
          setOfferingImages([ab.distributionStrategyFile?.URL, ab.financialsFile?.URL, ab.sourcesFile?.URL,
          ab.organizationalChartFile?.URL, ab.feesFile?.URL]);
        }
      }
      setLoading(false);
    })();
  }, []);

  const images = [
    require('../../assets/images/icons/home2.jpg'),
    require('../../assets/images/icons/home2.jpg'),
    require('../../assets/images/icons/home2.jpg'),
  ];
  
  const convertToMillion = (labelValue) => {
      // Nine Zeroes for Billions
      return Math.abs(Number(labelValue)) >= 1.0e+9
  
      ? Math.abs(Number(labelValue)) / 1.0e+9 + "B"
      // Six Zeroes for Millions 
      : Math.abs(Number(labelValue)) >= 1.0e+6
  
      ? Math.abs(Number(labelValue)) / 1.0e+6 + "M"
      // Three Zeroes for Thousands
      : Math.abs(Number(labelValue)) >= 1.0e+3
  
      ? Math.abs(Number(labelValue)) / 1.0e+3 + "K"
  
      : Math.abs(Number(labelValue));
  }

  const onChange = (key) => {
    setTabNo(key);
  };

  useEffect(() => {
    getIssuerDueDiligenceByCompanyId(null);
  }, [propertyDetails]);

  const [dueDiligence, setDueDiligence] = useState([{}]);

  const getIssuerDueDiligenceByCompanyId = async (companyId) => {
    const companyIdDueDiligence =  propertyDetails.companyId;
    const response = await sharedService.getIssuerDueDiligenceByCompanyId(
      companyIdDueDiligence
    );

    if (response.data) {
      //if investor is logged in the get the private duediligence
      let responsePrivate = []
      if (userInfo?._id) {
        responsePrivate = await sharedService.getPrivateIssuerDueDiligenceByCompanyId(companyIdDueDiligence);
        setDueDiligence([...response.data, ...responsePrivate.data]); //merge private data
        setShowDashboardOption(true);
        return;
      }
      setDueDiligence(response.data); //show only public data
    }
  };

  useEffect(()=>{
    (async () => {
      setLoading(true);
      const feeManagementData = await issuerSuperAdminService.getIssuerManagementFees(propertyDetails?.companyId)
      if(feeManagementData.success && feeManagementData.data?._id){
        setFeeManagement(feeManagementData.data);
      }else{
        setFeeManagement({})
      }

      setLoading(false);
    })();
  },[propertyDetails?.companyId])

  return (
    <div className="wd_scroll_wrap overflow-y-hidden">
      {loading && (
        <div style={{textAlign:'center', marginTop: '20px', marginBottom: '200px'}}>
          <Spin size='large'/>
        </div>
      )}
      {!loading && ( <>
        {!propertyDetails && (<h3 className="no-property">No Propery Found! Please try again.</h3>)}
        {propertyDetails && (<>
          <div className="container-fluid col-12 row" style={{padding: '50px 20px'}}>
            {/* // Apply to invest section */}
            <div className="col-12 invest" style={{width: '100%', display:'inline-block'}}>
              <button className="btn btn-block btn-lg btn-success" onClick={()=>history.push('/account/login')}>
                  {propertyDetails.projectedFundingDeadline ? new Date(propertyDetails.projectedFundingDeadline) > new Date() ? 'Invest Now' : 'Offer Closed' : 'Offer Closed'}
              </button>
              <h3>{propertyDetails.propertyTitle}</h3>
              <h6>{propertyDetails.propertyCity}, {propertyDetails.propertyState}</h6>
              <h4>{propertyDetails.companyName}</h4>
            </div>

            {/* Image and Details section */}
            <div className="col-12 row image-detail" style={{margin: '30px 0'}}>
              <div className="col-md-6 image-container">
                <Carousel
                  effect="fade"
                  autoplay
                  className="carosel"
                  nextArrow={sign}
                >
                  {propertyDetails.propertyImages &&
                    propertyDetails.propertyImages.map((img, index) => (
                      <div key={index}>
                        <img
                          id={`propertyimage`}
                          src={img.URL}
                          alt={img.name}
                          style={{width: '100%', minHeight: '370px', maxHeight: '370px'}}
                        />
                      </div>
                    ))}
                </Carousel>
              </div>
              <div className="col-md-6 detail-container">
                <h5 className="col-12">TARGETS</h5>
                <div className="row col-12 target-wrapper">
                  <div className="col-md-6 left-side" style={{paddingRight: '10px'}}>
                    <div className="detail-line">
                      <span className="line-left">IRR</span>
                      <span className="line-right">{propertyDetails.targetedInvestorIRR}%</span>
                    </div>
                    <div className="detail-line">
                      <span className="line-left">Equity Multiple</span>
                      <span className="line-right">{propertyDetails.targetedEquityMultiple}x</span>
                    </div>
                    <div className="detail-line">
                      <span className="line-left">Year 1 Cash on Cash</span>
                      <span className="line-right">{propertyDetails.yearOneCashOnCash}%</span>
                    </div>
                    <div className="detail-line">
                      <span className="line-left">Stabilized Cash on Cash</span>
                      <span className="line-right">{propertyDetails.stabilizedCashOnCash}%</span>
                    </div>
                    <div className="detail-line back-color">
                      <span className="line-left">Co-Investment</span>
                      <span className="line-right">{parseInt((propertyDetails.coInvestment/propertyDetails.totalInvestmentTarget)*100)}% $({(convertToMillion(propertyDetails.coInvestment))})</span>
                    </div>
                    <div className="detail-line back-color">
                      <span className="line-left">Preferred Return</span>
                      <span className="line-right">{propertyDetails.preferredReturn}%</span>
                    </div>
                    <div className="detail-line  back-color">
                      <span className="line-left">Investor Profit Share</span>
                      <span className="line-right">{propertyDetails.investorProfitShare}%</span>
                    </div>
                  </div>
                  <div className="col-md-6 right-side" style={{paddingLeft: '10px'}}>
                    <div className="detail-line">
                      <span className="line-left">Hold Period</span>
                      <span className="line-right">{propertyDetails.holdPeriod}Y</span>
                    </div>
                    <div className="detail-line">
                      <span className="line-left">Minimum Investment</span>
                      <span className="line-right">${propertyDetails.minimumInvestment}</span>
                    </div>
                    <div className="detail-line">
                      <span className="line-left">First Distribution</span>
                      <span className="line-right">{propertyDetails.firstDistribution}</span>
                    </div>
                    <div className="detail-line">
                      <span className="line-left">Distribution Frequency</span>
                      <span className="line-right">{propertyDetails.distributionFrequency}</span>
                    </div>
                    <div className="detail-line back-color">
                      <span className="line-left">Asset Profile</span>
                      <span className="line-right">{propertyDetails.assetProfile}</span>
                    </div>
                    <div className="detail-line back-color">
                      <span className="line-left">Loan-to-Value</span>
                      <span className="line-right">{propertyDetails.loanToValue}%</span>
                    </div>
                    <div className="detail-line back-color">
                      <span className="line-left">Current Occupancy</span>
                      <span className="line-right">{propertyDetails.currentOccupancy}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* multiple tabs section */}
            <Tabs onChange={onChange} type="card" className="col-12" style={{marginBottom: '30px'}}>
              {tabNames.map((name, index)=> (
                <TabPane tab={`${name}`} key={`${(index+1)}`} style={{fontSize: '18px', fontWeight: '500'}}></TabPane>
              ))}
            </Tabs>

            <div className="tabs-details-wrapper" style={{textAlign: 'center'}}>
              {/* Summary Section */}
              {tabNo == 1 && (<>
                <div className="summary" style={{padding: '0 20px'}}>
                  <h3>About This Property</h3>
                  <p>{propertyDetails.descriptionOfProperty}</p>
                  <br/>
                  {propertyDetails?.offeringVideoFile && propertyDetails?.offeringVideoFile[0] && (
                    <div
                      className="video col-12"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        textAlign: "center",
                      }}
                    >
                      <div
                        className="animate__animated animate__fadeInUp animate__slower  animate__delay-1s"
                        style={{ width: "700px" }}
                      >
                        <VideoPlayer
                          style={{height: "700px", width: "500px"}}
                          linkSrc={`${propertyDetails?.offeringVideoFile[0]?.URL}`}
                          playerId="player-1"
                          coverId="cover-2"
                          playButtonId="play-1"
                          subsButtonId="subs-1"
                        />
                      </div>
                    </div>
                  )}
                  <br/>
                  <div className="row-striped-wrapper col-12" style={{marginTop: '30px'}}>
                      <div className="row-striped col-12">
                        <span className="left-sec">Address</span>
                        <span className="right-sec">{propertyDetails.propertyAddress}</span>
                      </div>
                      <div className="row-striped col-12">
                        <span className="left-sec">Square Footage</span>
                        <span className="right-sec">{propertyDetails.squarefootage} sq. ft.</span>
                      </div>
                      <div className="row-striped col-12">
                        <span className="left-sec"># of Units</span>
                        <span className="right-sec">{propertyDetails.numberOfUnits}</span>
                      </div>
                      <div className="row-striped col-12">
                        <span className="left-sec">Year Built</span>
                        <span className="right-sec">{propertyDetails.yearBuilt}</span>
                      </div>
                      <div className="row-striped col-12">
                        <span className="left-sec">Year Renovated</span>
                        <span className="right-sec">{propertyDetails.yearRenovated || "N/A"}</span>
                      </div>
                      <div className="row-striped col-12">
                        <span className="left-sec">Current Occupancy</span>
                        <span className="right-sec">{propertyDetails.currentOccupancy}%</span>
                      </div>
                      <div className="row-striped col-12">
                        <span className="left-sec">Market Occupancy</span>
                        <span className="right-sec">{}{propertyDetails.marketOccupancy}%</span>
                      </div>
                      <div className="row-striped col-12">
                        <span className="left-sec">Current Average Rents</span>
                        <span className="right-sec">${propertyDetails.currentAverageRent}</span>
                      </div>
                      <div className="row-striped col-12">
                        <span className="left-sec">Average Market Rents</span>
                        <span className="right-sec">{propertyDetails.averageMarketRent || "N/A"}</span>
                      </div>
                      <div className="row-striped col-12">
                        <span className="left-sec">Purchase Price</span>
                        <span className="right-sec">${propertyDetails.purchasedPrice}</span>
                      </div>
                      <div className="row-striped col-12">
                        <span className="left-sec">Price/Sq. Ft.</span>
                        <span className="right-sec">${(propertyDetails.purchasedPrice/propertyDetails.squarefootage)?.toFixed(2)}/Sq. Ft.</span>
                      </div>
                      <div className="row-striped col-12">
                        <span className="left-sec">Stabilized Loan to Value</span>
                        <span className="right-sec">{propertyDetails.stabilizedLoanToValue}%</span>
                      </div>
                      <div className="row-striped col-12">
                        <span className="left-sec">Asset Type</span>
                        <span className="right-sec">{propertyDetails.assetType}</span>
                      </div>
                      <div className="row-striped col-12">
                        <span className="left-sec">Primary Property Type</span>
                        <span className="right-sec">{propertyDetails.primaryPropertyType}</span>
                      </div>
                      <div className="row-striped col-12">
                        <span className="left-sec">Primary Sub-Type</span>
                        <span className="right-sec">{propertyDetails.primarySubType}</span>
                      </div>
                      <div className="row-striped col-12">
                        <span className="left-sec">Building Size</span>
                        <span className="right-sec">{propertyDetails.buildingSize}</span>
                      </div>
                      <div className="row-striped col-12">
                        <span className="left-sec">Lot Size (Acres)</span>
                        <span className="right-sec">{propertyDetails.lotSize}</span>
                      </div>
                      <div className="row-striped col-12">
                        <span className="left-sec">Parking Count</span>
                        <span className="right-sec">{propertyDetails.parkingCount}</span>
                      </div>
                  </div>
                </div>
              </>)}

              {/* sponsor or team members section */}
              {tabNo == 2 && (<>
              {console.log(tabNo)}
                <div className="col-12">
                  <p
                    style={{ textAlign: "center" }}
                    id="teamDetails"
                    className="col-md-12 mt-5 propertyDetailsHeader"
                  >
                    Management Team Details
                  </p>
                  <p style={{ textAlign: "center" }}>
                    {propertyDetails?.descriptionOfManagementTeam}
                  </p>
                  {propertyDetails.teamMembers &&
                    propertyDetails.teamMembers.map((img, index) => (
                      <div
                        key={index}
                        style={{ width: "50%", margin: "0 auto" }}
                      >
                        <h5 className="mt-5 membertext">Member {index + 1}</h5>
                        <table className="table member-table">
                          <tbody>
                            {propertyDetails?.teamMembers[index]?.memberName !==
                              null && (
                              <tr>
                                <th>Name</th>
                                <td
                                  className="memberDetailValue"
                                  style={{ textAlign: "left" }}
                                >
                                  {
                                    propertyDetails?.teamMembers[index]
                                      ?.memberName
                                  }
                                </td>
                              </tr>
                            )}

                            {propertyDetails?.teamMembers[index]?.memberPhoto
                              ?.URL !== null && (
                              <tr>
                                <th>Photo</th>
                                <td
                                  className="memberDetailValue"
                                  style={{ textAlign: "left" }}
                                >
                                  <img
                                    style={{ height: "100px" }}
                                    src={
                                      propertyDetails?.teamMembers[index]
                                        ?.memberPhoto?.URL
                                    }
                                    alt="Member Photo"
                                  />
                                </td>
                              </tr>
                            )}

                            {propertyDetails?.teamMembers[index]
                              ?.memberContact !== null && (
                              <>
                                <tr>
                                  <th>Contact</th>
                                  <td
                                    className="memberDetailValue"
                                    style={{ textAlign: "justify" }}
                                  >
                                    {
                                      propertyDetails?.teamMembers[index]
                                        ?.memberContact
                                    }
                                  </td>
                                </tr>
                              </>
                            )}

                            {propertyDetails?.teamMembers[index]?.memberRole !==
                              null && (
                              <>
                                <tr>
                                  <th>Role</th>
                                  <td
                                    className="memberDetailValue"
                                    style={{ textAlign: "justify" }}
                                  >
                                    {
                                      propertyDetails?.teamMembers[index]
                                        ?.memberRole
                                    }
                                  </td>
                                </tr>
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>
                    ))}
                </div>
              </>)}

              {/* diligence report Section */}
              {tabNo == 3 && (<>
                <div className="summary">
                  <h4>{propertyDetails.diligenceReport}</h4>
                  <br/>
                  {['jpg','png','jpeg'].includes(propertyDetails.diligenceReportFile.type) ? 
                    <a href={propertyDetails.diligenceReportFile?.URL} target="_blank" rel="noopener noreferrer">
                      <img 
                        id="diligence-report-image" 
                        src={propertyDetails.diligenceReportFile?.URL} 
                        alt="diligence report" 
                        style={{ height: '400px' }} 
                      />
                    </a>
                    :
                  (<><br /><iframe src={propertyDetails.diligenceReportFile?.URL} width="80%" height="500px"></iframe></>)
                  }
                </div>
              </>)}

              {/* financial Section */}
              {tabNo == 4 && (<>
                <div className="summary">
                  <h4>{propertyDetails.financials}</h4>
                  <br/>
                  {['jpg','png','jpeg'].includes(propertyDetails.financialsFile.type) ? 
                  <a href={propertyDetails.financialsFile?.URL} target="_blank" rel="noopener noreferrer">
                  <img 
                    src={propertyDetails.financialsFile?.URL} 
                    alt="financials report" 
                    style={{ height: '400px' }} 
                  />
                  </a>
                  :
                  (<><br/><iframe src={propertyDetails.financialsFile?.URL} width='80%' height='500px'></iframe></>)
                  }
                </div>
              </>)}

              {/* Location Section */}
              {tabNo == 5 && (<>
                <div className="summary">
                  <h4>{propertyDetails.location}</h4>
                  <br/>
                  {['jpg', 'png', 'jpeg'].includes(propertyDetails.locationFile.type) ?
                  <a href={propertyDetails.locationFile?.URL} target="_blank" rel="noopener noreferrer">
                  <img 
                    src={propertyDetails.locationFile?.URL} 
                    alt="Location report" 
                    style={{ height: '300px' }} 
                  />
                </a>
                :
                (<><br/><iframe src={propertyDetails.locationFile?.URL} width='80%' height='500px'></iframe></>)
                }
                   </div>
                    </>)}

              {/* Document Section */}
              {tabNo == 6 && (<>
                <div className="col-sm-6 offset-sm-3" style={{paddingLeft: '20px'}}>
                <div className="card mb-5" >
                  <div
                    className="card-header fw-bold text-dark text-center h5 p-3"
                    style={{ background: "#E0E0E0" }}
                  >
                    DOCUMENTS
                  </div>
                    <div
                      className="card-body"
                      style={{ background: "#F8F8F8" }}
                    >
                      <table className="table">
                        <tbody>
                          <tr>
                            {propertyDetails.propertyDetailsFile?.length >
                              0 && (
                              <>
                                <th
                                  className="text-start"
                                  style={{ minWidth: "150px" }}
                                >
                                  Property Details
                                  <br />
                                  {/* <small className="text-muted">
                                    File Name:{" "}
                                    { propertyDetails.propertyDetailsFile[
                                        propertyDetails.propertyDetailsFile
                                          .length - 1
                                      ]?.name
                                    }
                                  </small> */}
                                  {/* <br /> */}
                                  {/* <small className="text-muted">
                                    File Type:{" "}
                                    {
                                      propertyDetails.propertyDetailsFile[
                                        propertyDetails.propertyDetailsFile
                                          .length - 1
                                      ]?.type
                                    }
                                  </small> */}
                                </th>
                                {userInfo?._id && (<td style={{ textAlign: "right" }}>
                                  {" "}
                                  {
                                    <a
                                      className="btn btn-outline-info text-white btn-sm px-4 rounded-pill fw-bold"
                                      style={{ background: "#149EC3" }}
                                      target="_blank"
                                      href={
                                        propertyDetails.propertyDetailsFile[
                                          propertyDetails
                                            .propertyDetailsFile.length - 1
                                        ]?.URL
                                      }
                                    >
                                      DOWNLOAD
                                    </a>
                                  }
                                </td>)}
                              </>
                            )}
                          </tr>
                          <tr>
                            {propertyDetails.financialProjectionsFile
                              ?.length > 0 && (
                              <>
                                <th className="text-start">
                                  Financial Projections
                                  <br />
                                  {/* <small className="text-muted">
                                    File Name:{" "}
                                    { propertyDetails.financialProjectionsFile[
                                        propertyDetails.financialProjectionsFile
                                          .length - 1
                                      ]?.name
                                    }
                                  </small> */}
                                  {/* <br /> */}
                                  {/* <small className="text-muted">
                                    File Type:{" "}
                                    {
                                      propertyDetails
                                        .financialProjectionsFile[
                                        propertyDetails
                                          .financialProjectionsFile.length -
                                          1
                                      ]?.type
                                    }
                                  </small> */}
                                </th>
                                {userInfo?._id && (<td style={{ textAlign: "right" }}>
                                  {" "}
                                  {propertyDetails.financialProjectionsFile
                                    ?.length > 0 && (
                                    <a
                                      className="btn btn-outline-info text-white btn-sm px-4 rounded-pill fw-bold"
                                      style={{ background: "#149EC3" }}
                                      target="_blank"
                                      href={
                                        propertyDetails
                                          .financialProjectionsFile[
                                          propertyDetails
                                            .financialProjectionsFile
                                            .length - 1
                                        ]?.URL
                                      }
                                    >
                                      DOWNLOAD
                                    </a>
                                  )}
                                </td>)}
                              </>
                            )}
                          </tr>
                          <tr>
                            {propertyDetails.privatePlacementFile?.length >
                              0 && (
                              <>
                                <th className="text-start">
                                  Private Placement
                                  <br />
                                  {/* <small className="text-muted">
                                    File Name:{" "}
                                    { propertyDetails.privatePlacementFile[
                                        propertyDetails.privatePlacementFile
                                          .length - 1
                                      ]?.name
                                    }
                                  </small> */}
                                  {/* <br /> */}
                                  {/* <small className="text-muted">
                                    File Type:{" "}
                                    {
                                      propertyDetails.privatePlacementFile[
                                        propertyDetails.privatePlacementFile
                                          .length - 1
                                      ]?.type
                                    }
                                  </small> */}
                                </th>
                                {userInfo?._id && (<td style={{ textAlign: "right" }}>
                                  {" "}
                                  {propertyDetails.privatePlacementFile
                                    ?.length > 0 && (
                                    <a
                                      className="btn btn-outline-info text-white btn-sm px-4 rounded-pill fw-bold"
                                      style={{ background: "#149EC3" }}
                                      target="_blank"
                                      href={
                                        propertyDetails
                                          .privatePlacementFile[
                                          propertyDetails
                                            .privatePlacementFile.length - 1
                                        ]?.URL
                                      }
                                    >
                                      DOWNLOAD
                                    </a>
                                  )}
                                </td>)}
                              </>
                            )}
                          </tr>
                          {propertyDetails.subscriptionAgreementFile
                            ?.length > 0 && (
                            <>
                              <tr>
                                <th className="text-start">
                                  Subscription Aggreement
                                  <br />
                                  {/* <small className="text-muted">
                                    File Name:{" "}
                                    { propertyDetails.subscriptionAgreementFile[
                                        propertyDetails.subscriptionAgreementFile
                                          .length - 1
                                      ]?.name
                                    }
                                  </small> */}
                                  {/* <br /> */}
                                  {/* <small className="text-muted">
                                    File Type:{" "}
                                    {
                                      propertyDetails
                                        .subscriptionAgreementFile[Documents
                                        propertyDetails
                                          .subscriptionAgreementFile
                                          .length - 1
                                      ]?.type
                                    }
                                  </small> */}
                                </th>
                                {userInfo?._id && (<td style={{ textAlign: "right" }}>
                                  {" "}
                                  {propertyDetails.subscriptionAgreementFile  
                                    ?.length > 0 && (
                                    <a
                                      className="btn btn-outline-info text-white btn-sm px-4 rounded-pill fw-bold"
                                      style={{ background: "#149EC3" }}
                                      target="_blank"
                                      href={
                                        propertyDetails
                                          .subscriptionAgreementFile[
                                          propertyDetails
                                            .subscriptionAgreementFile
                                            .length - 1
                                        ]?.URL
                                      }
                                    >
                                      DOWNLOAD
                                    </a>
                                  )}
                                </td>)}
                              </tr>
                            </>
                          )}

                          {propertyDetails.operatingAgreementFile?.length >
                            0 && (
                            <>
                              <tr>
                                <th className="text-start">
                                  Operating Agreement
                                  <br />
                                  {/* <small className="text-muted">
                                    File Name:{" "}
                                    { propertyDetails.operatingAgreementFile[
                                        propertyDetails.operatingAgreementFile
                                          .length - 1
                                      ]?.name
                                    }
                                  </small> */}
                                  {/* <br /> */}
                                  {/* <small className="text-muted">
                                    File Type:
                                    {
                                      propertyDetails
                                        .operatingAgreementFile[
                                        propertyDetails
                                          .operatingAgreementFile.length - 1
                                      ]?.type
                                    }
                                  </small> */}
                                </th>
                                {userInfo?._id && (<td style={{ textAlign: "right" }}>
                                  {" "}
                                  {propertyDetails.operatingAgreementFile
                                    ?.length > 0 && (
                                    <a
                                      className="btn btn-outline-info text-white btn-sm px-4 rounded-pill fw-bold"
                                      style={{ background: "#149EC3" }}
                                      target="_blank"
                                      href={
                                        propertyDetails
                                          .operatingAgreementFile[
                                          propertyDetails
                                            .operatingAgreementFile.length -
                                            1
                                        ]?.URL
                                      }
                                    >
                                      DOWNLOAD
                                    </a>
                                  )}
                                </td>)}
                              </tr>
                            </>
                          )}

                          {propertyDetails.propertyTitleSearchReport?.length >
                            0 && (
                            <>
                              <tr>
                                <th className="text-start">
                                  property Title Search
                                  <br />
                                </th>
                                {userInfo?._id && (<td style={{ textAlign: "right" }}>
                                  {" "}
                                  {propertyDetails.propertyTitleSearchReport
                                    ?.length > 0 && (
                                    <a
                                      className="btn btn-outline-info text-white btn-sm px-4 rounded-pill fw-bold"
                                      style={{ background: "#149EC3" }}
                                      target="_blank"
                                      href={
                                        propertyDetails
                                          .propertyTitleSearchReport[
                                          propertyDetails
                                            .propertyTitleSearchReport.length -
                                            1
                                        ]?.URL
                                      }
                                    >
                                      DOWNLOAD
                                    </a>
                                  )}
                                </td>)}
                              </tr>
                            </>
                          )}






                          {propertyDetails.otherDocumentsFile?.length >
                            0 && (
                            <>
                              <tr>
                                <th className="text-start">
                                  Other Documents
                                  <br />
                                  {/* <small className="text-muted">
                                    File Name:{" "}
                                    { propertyDetails.otherDocumentsFile[
                                        propertyDetails.otherDocumentsFile
                                          .length - 1
                                      ]?.name
                                    }
                                  </small> */}
                                  {/* <br /> */}
                                  {/* <small className="text-muted">
                                    File Type:
                                    {
                                      propertyDetails
                                        .otherDocumentsFile[
                                        propertyDetails
                                          .otherDocumentsFile.length - 1
                                      ]?.type
                                    }
                                  </small> */}
                                </th>
                                {userInfo?._id && (<td style={{ textAlign: "right" }}>
                                  {" "}
                                  {propertyDetails.otherDocumentsFile
                                    ?.length > 0 &&
                                    propertyDetails.otherDocumentsFile.map(
                                      (doc, index) => (
                                        <a
                                          key={index}
                                          className="btn btn-outline-info text-white btn-sm px-4 rounded-pill fw-bold"
                                          target="_blank"
                                          style={{
                                            borderRadius: "20px",
                                            marginBottom: "10px",
                                            background: "#149EC3",
                                          }}
                                          href={doc?.URL}
                                        >
                                          {doc.name}
                                        </a>
                                      )
                                    )}
                                </td>)}
                              </tr>
                            </>
                          )}



                        </tbody>
                      </table>
                    </div>
                </div>

              </div>
              </>)}

              {/* FAQ Section */}
              {tabNo == 7 &&   propertyDetails.FAQsFile &&(<>
                <div className="summary">
                  <h4>{propertyDetails.FAQs}</h4>
                  <br/>
                  {['jpg', 'png', 'jpeg'].includes(propertyDetails.FAQsFile.type) ?
                    <a href={propertyDetails.FAQsFile?.URL} target="_blank" rel="noopener noreferrer">
                    <img src={propertyDetails.FAQsFile?.URL} alt="Location report" style={{ height: '300px' }}/>
                    </a>
                    :
                    (<><br/><iframe src={propertyDetails.FAQsFile?.URL} width='80%' height='500px'></iframe></>)
                    }
                </div>
              </>)}
              
               {/* Due-Diligence Section */}
               {tabNo == 8 && (
                    <>
                      <Table
                        columns={columns}
                        dataSource={dueDiligence}
                        rowKey="_id"
                        pagination={false}
                      />
                    </>
                  )}

                  {/*Fee Management Section */}
                  {tabNo == 9 && (
                    <>
                    <div className="col-sm-7 offset-sm-3"  >
                      <div className="card mb-5">
                        <div className="card-header fw-bold text-dark text-center h5 p-3"
                            style={{ background: "#E0E0E0" }}> Management Fee  </div>
                          <div className="card-body" style={{ background: "#F8F8F8" }}>
                            <table className="table">
                              <tbody>
                                <tr>
                                  <th className="text-start" style={{ minWidth: "150px" }}>
                                    <span > 
                                      LLC Maintenance Fee &nbsp;
                                      <Tooltip title="Monthly fees for maintaining the LLC.">
                                        <InfoCircleOutlined />
                                      </Tooltip>
                                    </span>
                                    <br />
                                  </th>
                                 {userInfo?._id && (
                                    <td style={{ textAlign: "right" }}>
                                    {
                                      <span >
                                       $ {feeManagement?.monthlyFees}
                                      </span>
                                    }
                                     </td> 
                                    )}
                                </tr>
                                <tr>
                                  <th className="text-start" style={{ minWidth: "150px" }}>
                                    <span > 
                                    Acquisition Fee &nbsp;
                                      <Tooltip title="Fee charged at the time of acquiring a property.">
                                        <InfoCircleOutlined />
                                      </Tooltip>
                                    </span>
                                    <br />
                                  </th>
                                 {userInfo?._id && (
                                    <td style={{ textAlign: "right" }}>
                                    {
                                      <span >
                                       $ {feeManagement?.acquisitionFee}
                                      </span>
                                    }
                                     </td> )}
                                </tr>
                                <tr>
                                  <th className="text-start" style={{ minWidth: "150px" }}>
                                    <span > 
                                    Deposition Fee &nbsp;
                                      <Tooltip title="Fee charged at the time of selling a property.(of the property sale price).">
                                        <InfoCircleOutlined />
                                      </Tooltip>
                                    </span>
                                    <br />
                                  </th>
                                 {userInfo?._id && (
                                    <td style={{ textAlign: "right" }}>
                                    {
                                      <span >
                                       $ {feeManagement?.depositionFee}
                                      </span>
                                    }
                                     </td> )}
                                </tr>
                                <tr>
                                  <th className="text-start" style={{ minWidth: "150px" }}>
                                    <span > 
                                    Asset Management Fee &nbsp;
                                      <Tooltip title=" Ongoing fees for managing the property assets.(of the asset value annually).">
                                        <InfoCircleOutlined />
                                      </Tooltip>
                                    </span>
                                    <br />
                                  </th>
                                 {userInfo?._id && (
                                    <td style={{ textAlign: "right" }}>
                                    {
                                      <span >
                                       $ {feeManagement?.assetManagementFee}
                                      </span>
                                    }
                                     </td> )}
                                </tr>
                                <tr>
                                  <th className="text-start" style={{ minWidth: "150px" }}>
                                    <span > 
                                    Compliance Training &nbsp;
                                      <Tooltip title="Fee for training asset owners on compliance requirements. ">
                                        <InfoCircleOutlined />
                                      </Tooltip>
                                    </span>
                                    <br />
                                  </th>
                                 {userInfo?._id && (
                                    <td style={{ textAlign: "right" }}>
                                    {
                                      <span >
                                       $ {feeManagement?.complianceTrainingFee}
                                      </span>
                                    }
                                     </td> )}
                                </tr>
                                {/* <tr>
                                  <th className="text-start" style={{ minWidth: "150px" }}>
                                    <span > 
                                    Commission on Property Sales &nbsp;
                                      <Tooltip title="Monthly fees for maintaining the LLC.">
                                        <InfoCircleOutlined />
                                      </Tooltip>
                                    </span>
                                    <br />
                                  </th>
                                 {userInfo?._id && (
                                    <td style={{ textAlign: "right" }}>
                                    {
                                      <span >
                                       $ {feeManagement?.commissionOnPropertySales}
                                      </span>
                                    }
                                     </td> )}
                                </tr> */}
                                <tr>
                                  <th className="text-start" style={{ minWidth: "150px" }}>
                                    <span > 
                                    Monthly Marketplace Fees &nbsp;
                                      <Tooltip title="Ongoing fees for accessing the marketplace.">
                                        <InfoCircleOutlined />
                                      </Tooltip>
                                    </span>
                                    <br />
                                  </th>
                                 {userInfo?._id && (
                                    <td style={{ textAlign: "right" }}>
                                    {
                                      <span >
                                       $ {feeManagement?.monthlyMarketplaceFee}
                                      </span>
                                    }
                                     </td> )}
                                </tr>
                                <tr>
                                  <th className="text-start" style={{ minWidth: "150px" }}>
                                    <span > 
                                    Marketplace Management KYC Fees per Person &nbsp;
                                      <Tooltip title="Fee for KYC verification and management.">
                                        <InfoCircleOutlined />
                                      </Tooltip>
                                    </span>
                                    <br />
                                  </th>
                                 {userInfo?._id && (
                                    <td style={{ textAlign: "right" }}>
                                    {
                                      <span >
                                       $ {feeManagement?.marketplaceManagementKYCFee}
                                      </span>
                                    }
                                     </td> )}
                                </tr>
                                <tr>
                                  <th className="text-start" style={{ minWidth: "150px" }}>
                                    <span > 
                                    User KYC Fees per Person &nbsp;
                                      <Tooltip title="Fee for individual KYC verification.">
                                        <InfoCircleOutlined />
                                      </Tooltip>
                                    </span>
                                    <br />
                                  </th>
                                 {userInfo?._id && (
                                    <td style={{ textAlign: "right" }}>
                                    {
                                      <span >
                                        $ {feeManagement?.userKYCFee}
                                      </span>
                                    }
                                     </td> )}
                                </tr>
                                <tr>
                                  <th className="text-start" style={{ minWidth: "150px" }}>
                                    <span > 
                                    Yearly Fees to DP (Designated Partner) &nbsp;
                                      <Tooltip title="Annual fees charged to the Designated Partner for their role in managing the LLC and ensuring compliance. ">
                                        <InfoCircleOutlined />
                                      </Tooltip>
                                    </span>
                                    <br />
                                  </th>
                                 {userInfo?._id && (
                                    <td style={{ textAlign: "right" }}>
                                    {
                                      <span >
                                       $  {feeManagement?.yearlyFeeDP}
                                      </span>
                                    }
                                     </td> )}
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
            </div>
          </div>
        </>
        )}
      </>
      )}
    </div>
  );
};


export default PropertyDetails;
