import React, { useState, useEffect, useCallback } from "react";
import { Card, Col, Row, Typography, Table, Tabs, Button, notification, Tooltip, Modal } from "antd";
import { InvestorService } from "../Investor.service";
import { useHistory } from "react-router-dom";
import { AuthService } from "../../Shared/Auth.service";
import { LeftCircleOutlined, PlaySquareOutlined, ProjectOutlined, RightCircleOutlined } from "@ant-design/icons";
import moment from 'moment';
import '../Resolutions/Resolutions.scss';

const { TabPane } = Tabs;
const {Title} = Typography
const authService = new AuthService();
const useUserContext = () => authService.useUserContext();
const investorService = new InvestorService();

export const Resolutions = () => {

    const history = useHistory();
    const { userInfo } = useUserContext();
    const [ClosedProposalList, setClosedProposalList] = useState([]);
    const [OpenProposalList, setOpenProposalList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [viewProposal, setViewProposal] = useState(false);
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const [proposalRecord, setProposalRecord] = useState<any>([]);
    const [ifAlreadyVoted, setifAlreadyVoted] = useState<any>([]);
    const [vote, setVote] = useState<any>(false);

    const ClosedProposalCols = [
        {
            title: 'Proposal Id',
            dataIndex: 'proposalId',
            key: 'proposalId',
        },
        {
            title: 'Proposal Name',
            dataIndex: 'proposalName',
            key: 'proposalName',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
    ];
    const OpenProposalCols = [
        {
            title: 'Proposal Id',
            dataIndex: 'proposalId',
            key: 'proposalId',
        },
        {
            title: 'Proposal Name',
            dataIndex: 'proposalName',
            key: 'proposalName',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            render: (value: any) =>  <RichTextRenderer richText={value}/>
        },
        {
            title: 'Action',
            dataIndex: 'action',
            render: (value: any,data) => {
              return <Button type="primary" onClick={()=>displayForm(data)} htmlType="submit">View</Button>
            }
          }
    ];


    const displayForm = (data) => {
        console.log('view proposal',data)
        setProposalRecord(data);
        checkIfVoteAlreadyCasted();
        setViewProposal(true);
    }

    useEffect(() => {
        if (proposalRecord !== null) {
          checkIfVoteAlreadyCasted();
        }
      }, [proposalRecord]);

    useEffect(()=>{
        if(!userInfo) return;

        (async () => {
			const response = await investorService.getAllProposalsWithStatus();
			if(!response.success){
                console.log('something went wrong');
                return;
            }
            let publishedList = response.data?.filter(p => p.status === 'published');
            let other = response.data?.filter(p => p.status !== 'published');
            setOpenProposalList(publishedList);
            setClosedProposalList(other);
		})();

        
    },[userInfo]);

    const casteVote = async(votetype)=>{
        console.log(votetype)
        try {
            setIsLoading(true);
            let payload = {investorEmail:userInfo?.email, "vote": votetype} 
            const response = await investorService.castVoteToAProposalByProposalUniqueId(proposalRecord?._id,payload);
			if(!response.success){
                notification.error({message:response.error.message});
                setIsLoading(false);
                return;
            }
            notification.success({message:`You have voted successfully!`});
            setShowConfirmationPopup(false);
            setIsLoading(false);
            setTimeout(() => {
                window.location.reload()
            }, 500);
        } catch (error) {
            console.log(error)
        }
    }

    const checkIfVoteAlreadyCasted = ()=>{
        console.log('checking if vote casted or not',proposalRecord)
        let record = proposalRecord;
        if(record.hasOwnProperty('votings') && record.votings.length > 0) {
            let votings = record.votings;
            setifAlreadyVoted(votings?.some(v => v.investorEmail === userInfo?.email));
            return;
        }
        setifAlreadyVoted(false);
    }

    const goBack = () =>{
        setProposalRecord([]);
        setViewProposal(false);

    }

    const RichTextRenderer = ({ richText }) => {
        return <div dangerouslySetInnerHTML={{ __html: richText }} />;
      };

    return (
        <>
            <br />

            {!viewProposal && <Row justify="center">
                <Col span={22} style={{ textAlign: "center" }}>
                    <Card>
                        <Title
                            level={2}
                            style={{
                                textAlign: "left",
                                color: "#1890ff",
                                // fontSize: '30px',
                                fontWeight: "bold",
                            }}
                        >
                            Resolutions
                        </Title>

                        <Tabs defaultActiveKey='1' centered style={{textAlign:'center'}} onChange={(key) => console.log(key)}>
                            <TabPane
                                tab={
                                    <span>
                                        <PlaySquareOutlined />
                                        List Of All Open Proposals
                                    </span>
                                }
                                key='1'
                            >
                                <Table dataSource={OpenProposalList} loading={isLoading} columns={OpenProposalCols} />
                            </TabPane>

                            <TabPane
                                tab={
                                    <span>
                                        <ProjectOutlined />
                                        List Of All Closed Proposals
                                    </span>
                                }
                                key='2'
                            >
                                <Table dataSource={ClosedProposalList} loading={isLoading} columns={ClosedProposalCols} />
                            </TabPane>
                        </Tabs>
                    </Card>
                </Col>
            </Row>}

            {viewProposal && 
            <Row justify="center">
            <Col span={22} style={{ textAlign: "center" }}>
                <Card>
                <Tooltip title="Go Back">
                    <LeftCircleOutlined className="u-goback" onClick={()=>goBack()} />
                </Tooltip>

                <div className="u-proposal-record">
                    <div className="record-data">
                        <div className="label">Proposal Id:</div>
                        <div className="value">{proposalRecord?.proposalId}</div>
                    </div>
                    <div className="record-data">
                        <div className="label">Proposal Name:</div>
                        <div className="value">{proposalRecord?.proposalName}</div>
                    </div>
                    <div className="record-data">
                        <div className="label">Proposal Start Date:</div>
                        <div className="value">{moment(proposalRecord?.proposalStartDate).format('Do MMMM YYYY')}</div>
                    </div>
                    <div className="record-data">
                        <div className="label">Proposal End Date:</div>
                        <div className="value">{moment(proposalRecord?.proposalEndDate).format('Do MMMM YYYY')}</div>
                    </div>
                    <div className="record-data">
                        <div className="label">Threshold(%):</div>
                        <div className="value">{proposalRecord?.threshold}</div>
                    </div>
                    <div className="record-data">
                        <div className="label">Min Shreholder Required:</div>
                        <div className="value">{proposalRecord?.minShareHolderRequired}</div>
                    </div>
                    <div className="record-data">
                        <div className="label">Description:</div>
                        <div className="value">
                            <RichTextRenderer richText={proposalRecord?.description} />
                        </div>
                    </div>
                    {!ifAlreadyVoted ? 
                        <div className="record-data mt-5 align-items-end">
                            <div className="label">Cast You Vote:</div>
                            <div className="u-button-grid">
                            <Button type="primary" onClick={()=> {setShowConfirmationPopup(true); setVote(true);}} htmlType="submit">
                                Yes
                            </Button>
                            <Button type="primary" onClick={()=> {setShowConfirmationPopup(true); setVote(false);}} htmlType="submit">
                                No
                            </Button>
                            </div>
                        </div>
                    
                    :
                    <div className="record-data mt-5 align-items-end">
                        <div className="label">Cast You Vote:</div>
                        <div className="u-button-grid value">You have already voted as {(proposalRecord.votings?.find(x =>x.investorEmail === userInfo?.email)).vote ? '(YES)' :'(NO)'}</div>
                </div>
                    }

                </div>
                </Card>
                </Col>
            </Row>
            }

            <Modal
                title="Voting Confirmation"
                visible={showConfirmationPopup}
                onCancel={() => setShowConfirmationPopup(false)}
                footer={[
                    <Button key="back" onClick={() => { setShowConfirmationPopup(false); setVote(false)}}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={() => casteVote(vote)}>
                        Confirm
                    </Button>,
                ]}
            >
              <p>Are you sure you want to vote as {vote ? 'YES' : 'NO'}</p>   
            </Modal>
        </>
    );
};
