import React from "react";
import { Card, Col, Typography } from "antd";
import { AuthService } from '../../Shared/Auth.service'
const { Text } = Typography;

const authService = new AuthService();
const useUserContext = () => authService.useUserContext()

export default ({ uniqueURL }) => {
  const { userInfo, setUserInfo } = useUserContext();
  return (
    <>
      <Card>
        {
          userInfo?.status?.kyc === 'rejected' ? (
            <Col className='text-center col-8 offset-2'>
              <h4>Your KYC has been rejected by marketplace compliance admin for some security reason! You cannot do any investment.</h4>
            </Col>
          ) : userInfo?.status?.kyc === 'pending' ? (
            <Col className='text-center col-8 offset-2'>
              <h4>Your KYC is submitted and pending for approval with the marketplace compliance admin! Once approved, you will get an email notification. Please wait until then!</h4>
            </Col>
          ) : (
            <>
              <div class="col-10 text-center offset-1">
                <Text>
                  we have sent you a link on your registered email. Please open the link
                  in your smart phone to complete KYC. If you haven't recieved the link
                  you can type in the below given URL directly in your browser to
                  complete KYC.
                </Text>
                <br />
                <br />
                <a href={uniqueURL} rel="noopener noreferrer" target="_blank">
                  {uniqueURL}
                </a>
                <br />
                <br />
                <Text>
                  <b>
                    Once you complete KYC please refresh the page to continue your
                    investement process.
                  </b>
                </Text>
              </div>
            </>)
        }
      </Card>
    </>
  );
};
