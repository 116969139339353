import { Button, Card, Col, Form, Row,InputNumber, DatePicker, notification, Input, Spin } from 'antd'
import React, { useState,useRef, useEffect } from 'react'
import { AuthService } from '../../Shared/Auth.service';

import { Editor } from '@tinymce/tinymce-react';
import { IssuerComplianceAdminService } from '../IssuerComplianceAdmin.service';
import moment from 'moment';

const UserContext = AuthService.UserContext;
const useUserInfo = () => new AuthService().useUserInfo();
const issuerService = new IssuerComplianceAdminService();

const ProposalDetails = () => {
    const [form] = Form.useForm();
    const [userInfo, setUserInfo] = useUserInfo();
    const [createNewProposal, setCreateNewProposal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [ifProposalAlreadyExists, setIfProposalAlreadyExists] = useState(false);
    const [richTextvalue, setRichTextvalue] = useState('');
    const [proposalInfo, setProposalInfo] = useState<any>({});
    const [editMode, setEditMode] = useState<any>(false);
    const editorRef = useRef<any>(null);
    const [conditionArray,setConditionArray] = useState<any>(['pending','closed','published']);

    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 10 },
    };

    const validateMessages = {
        required: '${label} is required!',
        types: {
            email: '${label} is not a valid email!',
            number: '${label} is not a valid number!',
        },
        number: {
            range: '${label} must be between ${min} and ${max}',
        },
    };

    useEffect(()=>{
        setLoading(true);
        if(!userInfo) return;
        (async()=> {
			const response = await issuerService.getProposalByCompanyId(userInfo?.company?.id)
			if(!response.success){
                console.log('something went wrong');
                setLoading(false);
                return;
            }
            // console.log(form.getFieldValue(['proposalId']))
            if(response.data.proposal){
                console.log('proposal exists')
                setIfProposalAlreadyExists(true);
                setCreateNewProposal(true);
                setProposalInfo(response.data.proposal);
                
                form.setFieldsValue(
                    {
                        'proposalId':response.data.proposal.proposalId,
                        'proposalName':response.data.proposal.proposalName,
                        'proposalStartDate':  moment(new Date(response.data.proposal.proposalStartDate), 'YYYY-MM-DD'),
                        'proposalEndDate':  moment(new Date(response.data.proposal.proposalEndDate), 'YYYY-MM-DD'),
                        'threshold':response.data.proposal.threshold,
                        'minShareHolderRequired':response.data.proposal.minShareHolderRequired,
                    }
                );
                setRichTextvalue(response.data.proposal.description);
                setLoading(false);
                return;
            }

            getNewProposalId();
        })();  
    },[userInfo])

    const getNewProposalId = async()=>{
        setLoading(true);
        const response = await issuerService.getNewProposalId();
        if(!response.success){
            console.log('something went wrong');
            setLoading(false);
            return;
        }
        form.setFieldsValue({proposalId:response?.data?.proposalId});
        setLoading(false);
    }

    const onFinish = async(values: any) => {
        try {
            setLoading(true);
            values.description = editorRef.current.getContent();
            values.proposalStartDate= new Date(values.proposalStartDate).getTime();
            
            //set end date to next day midnight
            let selectedEndDate = new Date(values.proposalEndDate);
            selectedEndDate.setHours(0, 0, 0, 0);
            let nextDate = new Date(selectedEndDate);
            let newEndDate = nextDate.setDate(selectedEndDate.getDate() + 1);
            values.proposalEndDate= new Date(newEndDate).getTime();

            console.log(values);
            const response = await issuerService.persistProposalDetails(userInfo?.company?.id, values);

            if (!response) {
                notification.error({ message: `Error saving proposal` });
                setLoading(false);
                return;
            }
            notification.success({ message: `Proposal saved successfully` });
            setTimeout(() => {
                window.location.reload()
            }, 500);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    const showProposalForm = () => {
        setCreateNewProposal(true);
    }

    const editProposalDetails = () =>{
        console.log('edit proposal detials')
        if(proposalInfo.status === 'pending'){
            setConditionArray([]);
            setEditMode(true);
        }
            
    }
    const publishProposalDetails = async() =>{
        console.log('publish proposal detials')
        try {
            setLoading(true);

            if(proposalInfo?.status === 'closed' && proposalInfo?.minShareHolderVoted===false){
                publishProposalAgainWithNewEndDateAndStatus();
                return;
            }

            
            let formData = form.getFieldsValue();
            formData.description = editorRef.current.getContent()
            formData.proposalStartDate= new Date(formData.proposalStartDate).getTime();
            //set end date to next day midnight
            let selectedEndDate = new Date(formData.proposalEndDate);
            selectedEndDate.setHours(0, 0, 0, 0);
            let nextDate = new Date(selectedEndDate);
            let newEndDate = nextDate.setDate(selectedEndDate.getDate() + 1);
            formData.proposalEndDate= new Date(newEndDate).getTime();
            
            console.log(formData);
            const response = await issuerService.publishProposalDetails(userInfo?.company?.id, form.getFieldValue(['proposalId']));

            if (!response) {
                notification.error({ message: `Error publishing proposal` });
                setLoading(false);
                return;
            }
            notification.success({ message: `Proposal published successfully` });
            setTimeout(() => {
                window.location.reload()
            }, 500);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    }

    const publishProposalAgainWithNewEndDateAndStatus = async()=>{
    
        //set end date to next day midnight
        let selectedEndDate = new Date(form.getFieldValue(['proposalEndDate']));
        selectedEndDate.setHours(0, 0, 0, 0);
        let nextDate = new Date(selectedEndDate);
        let newEndDate = nextDate.setDate(selectedEndDate.getDate() + 1);

        let payload = {
            proposalId: form.getFieldValue(['proposalId']),
            proposalEndDate: new Date(newEndDate).getTime(),
            status: "published"
        }
        console.log(payload);
        try {
            setLoading(true);
            const response = await issuerService.publishNewProposalByPoposalIdAndCompanyId(userInfo?.company?.id,payload.proposalId, payload);

            if (!response) {
                notification.error({ message: `Error changing proposal end date` });
                setLoading(false);
                return;
            }
            notification.success({ message: `Successfully changed end date` });
            setTimeout(() => {
                window.location.reload()
            }, 500);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    }

    const disablePreviousDates = (current) => {
        return current && current < moment().startOf('day');
      };
    const disablePreviousEndDates = (current) => {
        //disable dates wrt selected startDate
        if(!form.getFieldValue(['proposalStartDate'])) return false;
        return current && current < moment().clone().add(1);
      };

    const ifVotingEndedWithoutMinShareholderVoted = ()=>{
        // console.log('proposal info here ',proposalInfo)
        if(!proposalInfo?.minShareHolderVoted){
            return true;
        }else if(
            proposalInfo?.status == 'closed' && 
            proposalInfo?.votingCalculated===true && 
            proposalInfo?.minShareHolderVoted===false
        ){
            return true;
        }else{
            return false;
        }

    }

    const handleEndDateAction = () =>{
        if(ifVotingEndedWithoutMinShareholderVoted()){
            if(form.getFieldValue(['proposalEndDate'])){
                form.resetFields(['proposalEndDate']);
            }
            let previousEndDate = moment(proposalInfo.proposalEndDate);
            console.log(previousEndDate,proposalInfo.proposalEndDate)
            // form.setFieldsValue({proposalStartDate:previousEndDate.clone().add(1)}); //set startdate as next day wrt previousenddate
        }
    }
    
    const setEndDateDisable = () => (proposalInfo?.status === 'pending' || proposalInfo?.status === 'published' || (proposalInfo?.status === 'closed' && proposalInfo?.minShareHolderVoted === true))

    return (
        <Row justify="center">
            <Col span={23}>
                <Card className='mt-4'>
                    {loading && <Spin size='large' style={{textAlign: 'center', display:'flex', justifyContent:'center'}} />}
                    {!loading && !createNewProposal && 
                    <div className="create-proposal d-flex mt-5 justify-content-center">
                        <Button type="primary" onClick={showProposalForm} htmlType="submit">
                            Create New Proposal
                        </Button>
                    </div>}
                    
                    {!loading && createNewProposal && 
                    <>
                    <h3 className='text-center mb-5'>Create New Proposal</h3>
                    <Form
                        {...layout}
                        name="proposal"
                        onFinish={onFinish}
                        form={form}
                        
                        validateMessages={validateMessages}
                    >
                        <Form.Item name={'proposalId'} label="Proposal Id" rules={[{ required: true }]}>
                            <Input disabled />
                        </Form.Item>
                        <Form.Item name={'proposalName'} label="Proposal Name" rules={[{ required: true }]}>
                            <Input type='text' disabled={conditionArray.includes(proposalInfo?.status) } />
                        </Form.Item>
                        <Form.Item name={'proposalStartDate'} label="Start Date" rules={[{ required: true, type: 'date' }]}>
                            <DatePicker style={{ width:'100%' }} 
                                 disabledDate={disablePreviousDates}
                                 disabled={conditionArray.includes(proposalInfo?.status) }
                            />
                        </Form.Item>
                        <Col className="u-wrap-cust">
                            <Row>
                                <Col  span={12} offset={6}><span style={{color:'gray',fontStyle:'Italic'}}><span>* </span> Proposal will end on next day of selected end date at midnight</span></Col>
                            </Row>
                            <Form.Item name={'proposalEndDate'} label="End Date" rules={[{ required: true, type: 'date' }]} style={{alignItems:'self-end'}}>
                                    <DatePicker style={{ width:'100%' }}
                                    disabled={!editMode &&  setEndDateDisable()}
                                    onClick={()=>handleEndDateAction()}
                                    disabledDate={disablePreviousEndDates}
                                    />
                            </Form.Item>
                        </Col>
                        <Form.Item name={'threshold'} label="Threshold(%)"  rules={[{ type: 'number', required: true,min: 0, max: 100 }]}>
                            <InputNumber style={{ width:'100%' }} 
                            disabled={conditionArray.includes(proposalInfo?.status) }/>
                        </Form.Item>
                        <Form.Item name={'minShareHolderRequired'} label="Min Shareholders required" rules={[{ required: true }]}>
                            <InputNumber style={{ width:'100%' }} 
                            disabled={conditionArray.includes(proposalInfo?.status) }/>
                        </Form.Item>
                        

                        <div className="u-rich-editor">
                            <span style={{color:"red"}}>* </span><label style={{fontSize:'18px'}} htmlFor="">Description:</label><br /><br />
                            <Editor
                                disabled={conditionArray.includes(proposalInfo?.status) }
                                onInit={(evt, editor) => editorRef.current = editor}
                                apiKey='8xxo0tbtw9ubgvosw323dw36uc0gyuihezmncadszthgx7wh'
                                initialValue={richTextvalue}
                                init={{
                                height: 300,
                                menubar: false,
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                ],
                                toolbar: 'undo redo | formatselect | ' +
                                'bold italic backcolor | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                'removeformat | help',
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                }}
                            />
                        </div>
                        
                        

                        <Form.Item wrapperCol={{ span:24 }} style={{ display:'flex', columnGap: '10px', justifyContent:'center !important', alignItems:'center !important' }}>
                           
                            {['published','closed'].includes(proposalInfo.status) &&
                            <div className="u-vote-count d-flex justify-content-center" style={{fontWeight:'bold',paddingTop:'23px',gap:'25px'}}>
                                <span>YES (VOTES) : {proposalInfo.votings ? proposalInfo.votings?.reduce((c,n) =>  n.vote ? ++c : c, 0 ) : 0 }</span>
                                <span>NO  (VOTES) : {proposalInfo.votings ? proposalInfo.votings?.reduce((c,n) =>  !n.vote ? ++c : c, 0 ) : 0 }</span>
                                {proposalInfo.status ==='closed' && <span>Result : {proposalInfo.result && proposalInfo.result}</span>}
                            </div>
                            }


                            <div className="u-button-grid mt-4 d-flex gap-3 justify-content-center">
                                <Button type="primary" htmlType="submit"
                                    disabled={proposalInfo.status === 'published' || proposalInfo.status ==='closed' }>
                                    Save
                                </Button>
                                <Button type="primary"  onClick={editProposalDetails}
                                    disabled={proposalInfo?.status !== 'pending' || proposalInfo?.status ==='published'}>
                                    Edit
                                </Button>
                                <Button type="primary"  onClick={publishProposalDetails}
                                    disabled={!proposalInfo.status || proposalInfo.status === 'published' || proposalInfo?.result ==='passed'}>
                                    Publish
                                </Button>
                            </div>
                        </Form.Item>
                    </Form>
                    </>}
                </Card>
            </Col>
        </Row>
    )
}

export default ProposalDetails