import BigNumber from 'bignumber.js';
import { MetamaskService } from '../../../Metamask.service';

export default class ERC20Facet {

  contract = {
    ABI: require('./ABI.json'),
  };

  private get web3() {
    return MetamaskService.web3;
  }

  async name(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return (await contractInstance.methods.name().call()) as string;
  }

  async symbol(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return (await contractInstance.methods.symbol().call()) as string;
  }

  async decimals(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return (await contractInstance.methods.decimals().call()) as string;
  }
  async getTokenDetails(tokenAddresses: string[], escrowAddress : string) {
    const tokenDetails = await Promise.all(
        tokenAddresses.map(async (address) => {
          if(address !="0x0000000000000000000000000000000000000000"){
            const contractInstance = new this.web3.eth.Contract(this.contract.ABI, address);
            const [name, symbol, decimals,balance] = await Promise.all([
              this.name(address),
              this.symbol(address),
              this.decimals(address),
              this.balanceOf(address,escrowAddress),
            ]);
            return { address, name, symbol, decimals,balance };
          }else{
            const [name, symbol, decimals,balance] = await Promise.all([
              "Matic",
              "Matic",
              18,
              await this.web3.eth.getBalance(escrowAddress),
            ]);
            return { address, name, symbol, decimals,balance };
          }

        })
    );

    return tokenDetails;
  }

  async totalSupply(tokenAddress: string) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return (await contractInstance.methods.totalSupply().call()) as string;
  }

  async balanceOf(tokenAddress: string, account: string) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return (await contractInstance.methods.balanceOf(account).call()) as string;
  }

  async transfer(tokenAddress: string, owner: string, to: string, value: string) {
    const contractInstance = new this.web3.eth.Contract(
      this.contract.ABI,
      tokenAddress
    );
    return contractInstance.methods
      .transfer(to, value)
      .send({ from: owner });
  }
  async approve(tokenAddress: string, owner: string, to: string, value: number) {
    try {
      const roundVal = Math.round(value)
      const amount = new BigNumber(roundVal);
      const contractInstance = new this.web3.eth.Contract(
          this.contract.ABI,
          tokenAddress
      );
      value = Math.pow(10,Number(this.decimals(tokenAddress)));
      return contractInstance.methods
          .approve(to, roundVal)
          .send({ from: owner });
      
    } catch (error) {
      console.log(error,"error")
    }
  }

  async checkAllowance(tokenAddress: string, owner: string, depositTokenAddress : string) {
    const contractInstance = new this.web3.eth.Contract(
        this.contract.ABI,
        depositTokenAddress
    );
    return (await contractInstance.methods.allowance(owner,tokenAddress).call()) as string;
  }

}
