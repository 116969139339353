import axios from 'axios';
import { environment } from '../../environments/environment';
import { APIResponse } from '../Shared/interfaces';
import { SharedService } from '../Shared/Shared.service';

const sharedService = new SharedService();


export class PlatformComplianceAdminService {

  private APIURL = environment.APIURL;
  async getInvestorKYCRequests(page:number,size:number)
  {
    return axios.get<any,APIResponse>(`${this.APIURL}/platformComplianceAdmin/getInvestorKYCRequests`,{params:{page,size},headers:await sharedService.getAuthHeader()});
  }
  async getTeamMemberKYCRequests()
  {
    return axios.get<any,APIResponse>(`${this.APIURL}/platformComplianceAdmin/getTeamMemberKYCRequests`,{headers:await sharedService.getAuthHeader()});
  }
  async getInvestorsList(page:number,size:number)
  {
    return axios.get<any,APIResponse>(`${this.APIURL}/platformComplianceAdmin/getInvestorsList`,{ params:{page,size},headers:await sharedService.getAuthHeader()});
  }
  async getTeamMemberList()
  {
    return axios.get<any,APIResponse>(`${this.APIURL}/platformComplianceAdmin/getTeamMemberList`,{headers:await sharedService.getAuthHeader()});
  }
  async updateKYC(data:any)
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/platformComplianceAdmin/updateKYC`,data,{headers:await sharedService.getAuthHeader()});
  }
  async updateTeamMemberKYC(data:any)
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/platformComplianceAdmin/updateTeamMemberKYC`,data,{headers:await sharedService.getAuthHeader()});
  }
   public uploadFile(file: Blob, progressCb?, errorCb?, completeCb?) {
    const req = new FormData();
    req.append('doc', file);
    sharedService.uploadDocument(`${this.APIURL}/platformComplianceAdmin/uploadDocument`, req, progressCb, errorCb, completeCb)
  }
  async saveKYCResult(data:any)
  {  
    return axios.post<any,APIResponse>(`${this.APIURL}/platformComplianceAdmin/saveKYCResult`,data,{headers:await sharedService.getAuthHeader()});
  }
  async saveTeamMemberKYCResult(data:any)
  {  
    return axios.post<any,APIResponse>(`${this.APIURL}/platformComplianceAdmin/saveTeamMemberKYCResult`,data,{headers:await sharedService.getAuthHeader()});
  }
  async getIssuersList()
  {  
    return axios.post<any,APIResponse>(`${this.APIURL}/platformComplianceAdmin/getIssuersList`,{headers:await sharedService.getAuthHeader()});
  }
  async getPreviouslyAskedIssuerQuestions(data:any)
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/platformComplianceAdmin/getAlreadyAskedQuestionsFromIssuer`, data, {headers: await sharedService.getAuthHeader()});
  }
  async submitDueDiligenceQuestion(data:any)
  {  
    return axios.post<any,APIResponse>(`${this.APIURL}/platformComplianceAdmin/submitDueDiligenceQuestion`,data,{headers:await sharedService.getAuthHeader()});
  }
  async getDueDiligenceQuestionListForIssuer(data:any)
  {  
    return axios.post<any,APIResponse>(`${this.APIURL}/platformComplianceAdmin/getDueDiligenceQuestionListForIssuer`,data,{headers:await sharedService.getAuthHeader()});
  }
  async reviewDueDiligence(data:any)
  {
    return axios.post<any,APIResponse>(`${this.APIURL}/platformComplianceAdmin/reviewDueDiligence`,data,{headers:await sharedService.getAuthHeader()});
  }
}
