import React, { useState, useEffect } from 'react';
import { MetamaskService } from '../../../Shared/Metamask.service';
import { Row, Col, Card, Typography, Table, Result, Spin } from 'antd';
import { AuthService } from '../../../Shared/Auth.service';
import {
  TokenConfigurationProcess,
  SymbolDetailsAndSTData,
  WhitelistedWallet,
  Country, RegLaunched
} from '../../../Shared/interfaces';
import { SecurityTokenRegistryService } from '../../../Shared/SecurityTokenRegistery/SecurityTokenRegistry.service';
import { SharedService } from '../../../Shared/Shared.service';
// import { useHistory } from 'react-router-dom';
import { TokenConfigurationService } from '../../../TokenConfigurations/TokenConfiguration.service';
import { IssuerComplianceAdminService } from '../../IssuerComplianceAdmin.service';
import MainFacet from "../../../Shared/SecurityToken/Facets/MainFacet";

const {Title} = Typography;

const securityTokenRegisteryService = new SecurityTokenRegistryService();
const sharedService = new SharedService();
const tokenConfigurationService = new TokenConfigurationService();
const issuerComplianceAdminService = new IssuerComplianceAdminService();
const mainFacet = new MainFacet();

const useUserContext = () => new AuthService().useUserContext();
const useSelectedWalletContext = () => new MetamaskService().useSelectedWalletContext();

export default () => {
  const {userInfo} = useUserContext();
  const {networkId} = useSelectedWalletContext();
  const [whitelistData, setWhitelistData] = useState<WhitelistedWallet[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [symbolDetailsAndSTData, setSymbolDetailsAndSTData] = useState<SymbolDetailsAndSTData>();
  // const history = useHistory();



  useEffect(() => {
    (async () => {
      if(!userInfo) return;

      const _tokenConfigurationProcess: TokenConfigurationProcess = (await tokenConfigurationService.getLastTokenConfigurationProcess()).data;

      if(!_tokenConfigurationProcess?.tokenSymbol) return setLoading(false);

      const _symbolDetailsAndSTData = await securityTokenRegisteryService.getSymbolDetailsAndSTData(_tokenConfigurationProcess.tokenSymbol);
      setSymbolDetailsAndSTData(_symbolDetailsAndSTData);

      if(!_symbolDetailsAndSTData?.symbolDetails.isDeployed) return setLoading(false);

      const [
        whitelistRes,
        countriesRes,
        // _regulationsLaunched
      ] = await Promise.all([
      issuerComplianceAdminService.getWhitelistedUsersByWallet(_symbolDetailsAndSTData?.securityTokenData.contractAddress),
        sharedService.getCountries(),
        mainFacet.getRegulationsLaunched(_symbolDetailsAndSTData?.securityTokenData.contractAddress)
    ])
      const _whitelistData: WhitelistedWallet[] = whitelistRes.data;
      const _countries: Country[] = countriesRes.data;

      _whitelistData.forEach(whitelistedWallet => {
        // whitelistedWallet['regulationLaunched'] = _regulationsLaunched.find(opt => opt.creationTS === whitelistedWallet.assetCreationTS);
        whitelistedWallet['countryName'] = _countries.find(opt => opt._id === whitelistedWallet.country)?.name.en;
      });

      // _whitelistData.forEach((whitelistedWallet, index) => whitelistedWallet['key'] = index);
      setWhitelistData(_whitelistData);



      setLoading(false)

    })();
  }, [userInfo]);



  const columns = [
    {
      title: 'Name',
      dataIndex: 'firstName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Wallet Address',
      dataIndex: 'wallet',
      render: (value: string, record) => 
      <a target="_blank" rel="noopener noreferrer" href={`${sharedService.etherscanURL[networkId as string]}/address/${value}`}>
        {sharedService.minifyAddress(value)}
      </a> 
    },
    {
      title: 'Affiliate',
      dataIndex: 'isAffiliate',
      render: (value: boolean, record) => {
        return (value? 'YES' : 'NO');
      }
    },
    {
      // title: 'USA Person',
      title: 'Country',
      dataIndex: 'countryName',
      render: (value: string, record) => {
        return value || record.country
        // return (value? 'YES' : 'NO');
      }
    },
    {
      title: 'Accredited Investor',
      dataIndex: 'isAccredetedInvestor',
      render: (value: boolean) => value? 'YES' : 'NO'
    },
    // {
    //   title: 'Whitelisted for',
    //   dataIndex: 'regulationLaunched',
    //   render: (value: RegLaunched) => sharedService.assetName(value)
    // }
  ];


  return (
    <>
      <br/><br/>
      <Row justify="center">
      <Col span={20}>
        {loading && 
          <div style={{textAlign:'center'}}>
            <br/><br/>
            <Spin size='large'/>
          </div>
        }

        {!loading && 
          <Card>
            <Title level={1} style={{textAlign:'center'}}>Whitelisted Wallets</Title>

            {!symbolDetailsAndSTData?.symbolDetails.isDeployed &&
              <Result
                title={`Security Token not deployed`}
              />
            }

            {symbolDetailsAndSTData?.symbolDetails.isDeployed &&
              <>
                <Table
                  scroll={{x: true}}
                  rowKey='index'
                  columns={columns}
                  dataSource={whitelistData}
                  pagination={false}
                />
              </>
            }
          </Card>
        }

      </Col>
      </Row>
    </>
  );
}