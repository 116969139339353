import React, { useEffect, useState } from "react";
import { environment } from "../../../environments/environment";
import { SharedService } from "../../Shared/Shared.service";
import { Card, Col, Row, Table } from "antd";
import Title from "antd/lib/typography/Title";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthService } from "../../Shared/Auth.service";

declare global {
  interface Window {
    ethereum: any;
  }
}

const sharedService = new SharedService();
const useUserContext = () => new AuthService().useUserContext();

function DueDiligence() {
  const APIURL = environment.APIURL;
  const { userInfo } = useUserContext();

  useEffect(() => {
    getIssuerDueDiligenceByCompanyId(null);
  }, []);

  const [dueDiligence, setDueDiligence] = useState([{}]);

  const getIssuerDueDiligenceByCompanyId = async (companyId) => {
    const response = await sharedService.getIssuerDueDiligenceByCompanyId(
      userInfo?.company?.id
    );
    if (response.data) {
      let responsePrivate:any = []
      if (userInfo?._id) {
        responsePrivate = await sharedService.getPrivateIssuerDueDiligenceByCompanyId(userInfo?.company?.id);
        setDueDiligence([...response.data, ...responsePrivate.data]); //merge private data
        return;
      }
      setDueDiligence(response.data); //show only public data
    }
  };

  const renderURL = (object) => {
    const newObject = object[0]?.URL;
    return newObject;
  };
  const renderName = (object) => {
    const newObject = object[0]?.name;
    return newObject;
  };
  const columns = [
    {
      title: "Question",
      dataIndex: "question",
      render: (value) => value,
    },
    {
      title: "Answer",
      dataIndex: "answer",
      render: (value: any) =>
        typeof value != "object" ? (
          value
        ) : (
          <span>{<a target="_blank" href={renderURL(value)}>{renderName(value)}</a>}</span>
        ),
    },
  ];

  return (
    <>
      <br></br>
      <Row justify="center">
        <Col span={22} style={{ textAlign: "center" }}>
          <Card>
            <Title
              level={2}
              style={{
                textAlign: "left",
                color: "#1890ff",
                // fontSize: '30px',
                fontWeight: "bold",
              }}
            >
              Due Diligence
            </Title>
            <div style={{ display: "grid" }}>
              <Table
                columns={columns}
                dataSource={dueDiligence}
                rowKey="_id"
                pagination={false}
              />
            </div>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
    </>
  );
}

export default DueDiligence;
