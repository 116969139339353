import React, { useState, useEffect } from 'react';
import { Menu, Layout, Typography } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { AuthService } from '../../Shared/Auth.service';
import { MetamaskService } from '../../Shared/Metamask.service';
import { useLocation, useHistory } from 'react-router-dom';

import './HeaderNav.scss';
import { SharedService } from '../../Shared/Shared.service';
import { User } from '../../Shared/interfaces';
import { environment } from '../../../environments/environment';

const { Header } = Layout;
const { Title } = Typography;
const { SubMenu } = Menu;

const authService = new AuthService();
const sharedService = new SharedService();
const metamaskService = new MetamaskService();

const useUserContext = () => authService.useUserContext();
const useSelectedWalletContext = () => metamaskService.useSelectedWalletContext();

const menuHeader = sharedService.menuHeader;

export default () => {
  const history = useHistory();
  const { pathname } = useLocation();

  const { userInfo, setUserInfo } = useUserContext();
  const { selectedWallet, networkId } = useSelectedWalletContext();

  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  useEffect(() => {
    setSelectedKeys([pathname]);
  }, [pathname]);

  const logout = async () => {
    setUserInfo(null);
    sharedService.deleteToken();
    window.location.href = '/';
  }

  const onClickChangePassword = async () => {
    history.push("/account/change-password");
  };

  const changeSelectedRole = async (role: User['role']) => {
    if (!userInfo) return;

    setUserInfo({ ...userInfo, role });

    const newToken: string = (await sharedService.changeSelectedRole(role)).data;

    sharedService.setToken(newToken);

    switch (role) {
      case 'master company super admin':
        history.push('/platform-issuer-admin/issuer-property-verif-reqs');
        break;
      case 'master company counsel':
        history.push('/amendments');
        break;
      case 'master company officer':
        history.push('/master-company-super-admin/basic-info-verif');
        break;
      case 'master company series manager':
        history.push('/series');
        break;
      case 'master company compliance admin':
        history.push('/payment-info-request/us-bank');
        break;
      case 'issuer compliance admin':
        history.push('/connect-wallet');
        break;
      case 'employee':
        history.push('/team-member/KYCUpload');
        break;
      case 'issuer super admin':
        history.push('/connect-wallet');
        break;
      case 'issuer token admin':
        history.push('/connect-wallet');
        break;
      case 'issuer company officer':
        history.push('/connect-wallet');
        break;
      case 'issuer counsel':
        history.push('/connect-wallet');
        break;
      case 'issuer company manager':
        history.push('/connect-wallet');
        break;
      case 'companyMember':
        history.push('/connect-wallet');
        break;
      default:
        history.push('/default-path');
        break;
    }
  }

  const goTo = ({ key }) => history.push(key);

  const viewWalletOnEtherscan = () => {
    if (!selectedWallet || !sharedService.allowedNetworks.includes(networkId as string)) return;

    window.open(`${sharedService.etherscanURL[networkId as string]}/address/${selectedWallet}`);
  }

  return (
    <Header style={{ position: 'fixed', zIndex: 2, width: '100%' }} id="components-layout-demo-top-side-2" className="header">
      <div className="logo is-clickable">
        <Title id='title' level={5} onClick={() => { window.location.href = '/marketplace' }}>{environment.headerTitle}</Title>
      </div>
      <Menu style={{ float: 'right' }} theme="dark" mode="horizontal" selectedKeys={selectedKeys}>

        {userInfo &&
          <Menu.Item key="network" onClick={viewWalletOnEtherscan}>
            <div className={`${sharedService.allowedNetworks.includes(networkId as string) ? 'green' : 'red'}-blockchain-dot`}>
            </div>
            ({sharedService.ethereumNetworks[networkId as string] || 'Disconnected'}) {selectedWallet && sharedService.minifyAddress(selectedWallet)}
          </Menu.Item>
        }

        {userInfo &&
          <SubMenu
            title={
              <span className="submenu-title-wrapper">
                <UserOutlined />{userInfo.firstName}
              </span>
            }
          >
            {userInfo.roles?.filter(role => !['contractor_consultant_advisor','employee'].includes(role) ).map((role, index) =>
              <Menu.Item key={index} style={{ fontWeight: userInfo.role === role ? 'bold' : 'unset' }} onClick={() => changeSelectedRole(role)}>
                {role === 'contractor_consultant_advisor' || role === 'employee' ?
                  `PROFILE (${role === 'contractor_consultant_advisor' ? 'CONTRACTOR' : menuHeader[role]})` : menuHeader[role]
                }
              </Menu.Item>
            )}
            <Menu.Item key="setting:1" onClick={onClickChangePassword}>Change password</Menu.Item>
            <Menu.Item key="setting:2" onClick={logout}>Logout</Menu.Item>
          </SubMenu>
        }

        {!userInfo &&
          <Menu.Item key="/account/login" onClick={goTo}>
            <UserOutlined />Login
          </Menu.Item>
        }
      </Menu>
    </Header>
  );
}