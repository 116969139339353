import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, Button, Card, Row, Col, Modal, Alert, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link, useHistory, useParams } from 'react-router-dom';
// import * as classNames from 'classnames';
import { SharedService } from '../../Shared/Shared.service';
import { AccountService } from '../Account.service';
import { AuthService } from '../../Shared/Auth.service';
import { environment } from '../../../environments/environment';

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 11 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

const accountService = new AccountService();
const sharedService = new SharedService();

const useUserContext = () => new AuthService().useUserContext();
export default () => {
    const history = useHistory();

    const [reqErr, setReqErr] = useState<string>();
    const [submitting, setSubmitting] = useState(false);
    const [expired, setExpired] = useState(true);
    const [registered, setRegistered] = useState(false);
    const [openRegisterModal, setOpenRegisterModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [form] = Form.useForm();

    const { userInfo, setUserInfo } = useUserContext();
    const email = userInfo?.email

    const handleSubmit = async (req) => {

        const data = {
          ...req,
          email: userInfo?.email,
          id: userInfo?._id,
        };
        setSubmitting(true);
        const response = await accountService.changePassword(data);
        setSubmitting(false)
        if (response.success) {
            message.success({
              content: "Password changed successfully",
              duration: 7,
              style: { marginTop: "90px" },
            });
            console.log("Password changed successfully, logging out user...");
            setUserInfo(null);
            sharedService.logout(); 
            setUserInfo(null);
            sharedService.logout(); 
            history.push("/");
        } else {
            console.log("Error changing password: ", response.error.message);
            message.error(response.error.message, 5)
        }
        setSubmitting(false);
        
    }

    useEffect(() => {
        if (!userInfo?._id) {
          history.push("/");
        }
      }, [userInfo, history]);

    return (
        <Row justify="center" style={{ marginTop: "60px" }}>
            <Col span={23} style={{ textAlign: 'center' }}>
                <Card style={{
                    // margin: '5% 0 0 15%',
                    // width: '70%'
                }}>
                    <h2 style={{ textAlign: 'center' }}>Change Password</h2>
                    <Form
                        // form = {form}
                        // size='middle'
                        onFinish={handleSubmit}
                        className='login-form'
                    // style={{margin: 'auto', marginTop: '3%', width: '60%',  textAlign: 'center'}}
                    >

                        <Form.Item
                            name="currentPassword"
                            rules={[{ required: true, message: 'Please input your current password!' }]}
                            validateStatus={reqErr && 'error'}
                            help={reqErr}
                            style={{ width: '300px', margin: 'auto', marginBottom: '10px' }}>
                            <Input.Password placeholder="Current Password" prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} />
                        </Form.Item>

                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: 'Please input your password!' }]}
                            validateStatus={reqErr && 'error'}
                            help={reqErr}
                            style={{ width: '300px', margin: 'auto', marginBottom: '10px', marginTop: '20px' }}>
                            <Input.Password placeholder="New Password" prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} />
                        </Form.Item>

                        <Form.Item
                            name="confirmPassword"
                            rules={[{ required: true, message: 'Please confirm your password!' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                },
                            }),]}
                            validateStatus={reqErr && 'error'}
                            help={reqErr}
                            style={{ width: '300px', margin: 'auto', marginBottom: '10px', marginTop: '20px' }}>
                            <Input.Password placeholder="Confirm Password" prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} />
                        </Form.Item>

                        <Form.Item>
                            <Button
                                style={{ marginTop: '20px' }}
                                type='primary'
                                htmlType='submit'
                                className='login-form-button'
                                loading={submitting}
                            >
                                Change Password
                            </Button>
                            <br />
                            <br />
                        </Form.Item>
                    </Form>
                </Card>
            </Col>
        </Row>
    )
}