import React,{useState, useEffect} from 'react';
import { Result, Card , Form, Col, Radio, Input, Button, Spin} from 'antd';
import { AuthService } from '../../Shared/Auth.service'
import { InvestorService } from '../Investor.service';
import eversign from "./Eversign";
import './nda.css';

const authService = new AuthService();
const useUserContext = () => authService.useUserContext()
const investorService = new InvestorService();

export default () => {
  const { userInfo, setUserInfo } = useUserContext();
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [ndaSigned, setNdaSigned] = useState(false);
  const [ndaDetails, setNdaDetails] = useState(false);
  const [openEversign, setOpenEversign] = useState(false);
  const [signURL, setSignURL] = useState(null);
  const [accInvestor, setAccInvestor] = useState('false');

    useEffect(()=>{
      if(userInfo.status.ndaSigned === true){
        setNdaSigned(true);
      }
      if(userInfo.ndaDetails === true){
        setNdaDetails(true);
      }
      (async () => {
        if (userInfo.status.ndaSigned === true) return;
        if(userInfo.accreditedInvestor === 'true') setAccInvestor('true')
        const response = await investorService.getNDASignURL(userInfo);
        await setSignURL(response.data);
      })();
    },[])

    useEffect(() => {
      setOpenEversign(signURL && !ndaSigned)
    }, [signURL, ndaSigned])

  const register = async (formValue) => {
    const req = {...formValue};
    req.userId = userInfo._id;
    req.ndaDetails = true;
    req.accreditedInvestor = accInvestor;
    setSubmitting(true);
    let res = await investorService.submitNdaDetails(req);
    setNdaDetails(true);
    setSubmitting(false);
  }

    //ACCEPT T AND C STARTS
    const acceptTandC = async () => {
      const response = await investorService.setNDASigned({
        email: userInfo.email,
      });
      if (response.success && response.data) {
        userInfo.status.ndaSigned = true;
      }
    };
    //ACCEPT T AND C ENDS

  const onSign = () => {
    acceptTandC();
    setNdaSigned(true);
  };

  return (
    <>
   {submitting && <Spin size='large' style={{textAlign: 'center'}} />}
    {!submitting && (<Card style={{margin: '20px 50px', padding: '20px 40px'}}>
        {ndaSigned && (<h2 style={{color: 'blue', fontSize: '24px'}}>You have successfully signed Investor NDA Agreement. You can continue to investment!</h2>)}
        {!ndaSigned && (<h2 style={{color: 'blue', fontSize: '24px'}}>NDA</h2>)}
        {/* NDA View Starts Here */}
        <div className="col-12 d-flex justify-content-center">
        <div hidden={ndaSigned} id="embedSignDivision">
          {openEversign &&
            eversign.open({
              url: signURL,
              containerID: "embedSignDivision",
              width: 750,
              height: 800,
              events: {
                loaded: () => console.log("loading successful"),
                signed: onSign,
                error: () => console.log("EVERSIGN ERROR"),
                declined: () => console.log("declined"),
              },
            })}
        </div>
        </div>
        {/* NDA View ends here */}
      </Card>)}
    </>
  )
}