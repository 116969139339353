import React from 'react'
import { Switch, Route, useRouteMatch } from "react-router-dom";
import InvestorHiw from './Investor/Investor';
import Agent from './Agent/Agent';
import Builder from './Builder/Builder';
import MarketplaceHeader from '../marketplaceHeader/MarketplaceHeader';
import Footer from '../footer';
import howItWorksBannerr from '../../assets/images/about-us/howItWorksBannerr.png';

const HowItWorks = () => {
  const { path } = useRouteMatch();

  console.log('how it works ', path)
  return (
    <>
      < MarketplaceHeader view='detail' header="Step into the Future:"
        subtitle="Elevate Realty Properties, Sell Smart: Go Digital, Secure Deals!"
        img={`url(${howItWorksBannerr})`}
      />
      <Switch>
        <Route exact path={[path, `${path}/investor-hiw`]}>
          <InvestorHiw />
        </Route>
        <Route path={`${path}/agent`}>
          <Agent />
        </Route>
        <Route path={`${path}/builder`}>
          <Builder />
        </Route>
      </Switch>
      <Footer />
    </>
  );
}

export default HowItWorks