import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  Row,
  Card,
  Col,
  Form,
  Typography,
  Select,
  message,
  Space,
} from "antd";
import { PlatformComplianceAdminService } from "../PlatformComplianceAdmin.service";
import dueDiligenceData from "../dueDiligenceData";
import { AuthService } from '../../Shared/Auth.service';
import { publish } from "rxjs/operators";
const { Title } = Typography;
const { TextArea } = Input;
const { Option } = Select;
const FormItem = Form.Item;

const platformComplianceAdminService = new PlatformComplianceAdminService();
const useUserContext = () => new AuthService().useUserContext();

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

export default () => {
  const {userInfo} = useUserContext();
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [issuersList, setIssuersList] = useState(null);
  const [questionsList, setQuestionsList] = useState([]);
  const [previouslyAskedQuestions, setPreviouslyAskedQuestions] = useState([]);
  const [selectedIssuerId, setSelectedIssuerId] = useState(0);

  useEffect(() => {
    if (userInfo?.company?.id) {
      (async () => {
        const data = {companyId: userInfo?.company?.id}
        const prevAskedQuestionResponse = await platformComplianceAdminService.getPreviouslyAskedIssuerQuestions(data);
        if (prevAskedQuestionResponse.success) {
          setPreviouslyAskedQuestions(prevAskedQuestionResponse.data);
        } else {
          message.error("Error in loading previously asked questions from the issuer");
        }
      })();
    } else {
      setPreviouslyAskedQuestions([]);
    } 
  }, [userInfo?.company?.id]);

  useEffect(() => {
    const filteredQuestions = removePreviouslyAskedQuestions(questionsList); 
    setQuestionsList(filteredQuestions);
  }, [previouslyAskedQuestions]);

  useEffect(() => {
    (async () => {
      const issuersListResponse =
        await platformComplianceAdminService.getIssuersList();
      if (issuersListResponse.success) {
        if (issuersListResponse.data.length > 0) {
          // here setting the list to only msa, so that only msa can answer.
          let msa = issuersListResponse.data[0];
          await setIssuersList([msa]);
        } else {
          message.error("No issuer(s) present on the platform");
        }
      } else {
        message.error("Error in loading issuers list");
      }
    })();
  }, []);

  const removePreviouslyAskedQuestions = questions => {
    const prevAskedQuestionSet = new Set(previouslyAskedQuestions);
    const filteredQuestions = questions.filter(question => !prevAskedQuestionSet.has(question));
    return filteredQuestions;
  }

  const addDueDiligence = async (formData) => {
    setSubmitting(true);
    console.log({ formData });
    formData.answer = "";
    formData.isAnswered = false;
    formData.isReviewed = false;
    formData.isRejected = false;
    formData.isApprovedByIC=false;
    formData.isRejectedByIC=false;
    formData.status="pending";
    formData.companyId = userInfo?.company?.id;
    const dataSubmissionResult =
      await platformComplianceAdminService.submitDueDiligenceQuestion(formData);
    if (dataSubmissionResult.success && dataSubmissionResult.data) {
      await form.resetFields();
      setSelectedIssuerId(0);
      message.success("Due diligence addded succesfully.");
      setTimeout(()=> window.location.reload(), 1000);
    } else {
      message.error("Some problem occured while uploading question.");
    }
    setSubmitting(false);
  };

  const loadQuestion = (selected) => {
    if (selected === "public") {
      const filteredQuestions = removePreviouslyAskedQuestions(dueDiligenceData.public);
      setQuestionsList(filteredQuestions);
    } else if (selected === "private") {
      const filteredQuestions = removePreviouslyAskedQuestions(dueDiligenceData.private);
      setQuestionsList(filteredQuestions);
    } else setQuestionsList([]);
  };

  return (
    <>
      <br />
      <Row justify="center">
        <Col span={22} style={{ textAlign: "center" }}>
          <Card>
            <Title
              level={2}
              style={{
                textAlign: "left",
                color: "#1890ff",
                // fontSize: '30px',
                fontWeight: "bold",
              }}
            >
              Add Due Diligence
            </Title>

            <Form
              {...formItemLayout}
              form={form}
              name="addDueDiligenceForm"
              key="addDueDiligenceForm"
              onFinish={addDueDiligence}
              style={{ width: "60%", display:"inline-block"}}
            >
              <FormItem
                label="Select Issuer"
                name="companyId"
                key="issuerName"
                rules={[
                  {
                    required: true,
                    message: "Please select a issuer!",
                  },
                ]}
                labelAlign="left"
              >
                <Select 
                key="issuerDropDown" 
                disabled={submitting}
                onChange={setSelectedIssuerId}
                >
                  {issuersList &&
                    issuersList.map((data, index) => (
                      <Option key={index} value={data.companyId}>
                        {data.companyName}
                      </Option>
                    ))}
                </Select>
              </FormItem>

              <FormItem
                label="Select Visiblity"
                name="visiblity"
                key="visiblity"
                labelAlign="left"
                rules={[
                  {
                    required: true,
                    message: "Please select the visiblity!",
                  },
                ]}
              >
                <Select
                  key="visiblityDropDown"
                  onChange={loadQuestion}
                  disabled={submitting}
                >
                  <Option key="public" value="public">
                    Public
                  </Option>
                  <Option key="private" value="private">
                    Private
                  </Option>
                </Select>
              </FormItem>

              <FormItem
                label="Enter Question"
                name="question"
                key="question"
                labelAlign="left"
                rules={[
                  {
                    required: true,
                    message: "Question can't be empty!",
                  },
                ]}
              >
                <Select key="questionsDropDown" disabled={submitting}>
                  {questionsList?.map((data) => (
                    <Option key={data} value={data}>
                      {data}
                    </Option>
                  ))}
                </Select>
              </FormItem>
              <FormItem
                label="Select Answer type"
                name="answerType"
                key="answerType"
                labelAlign="left"
                rules={[
                  {
                    required: true,
                    message: "Please select answer type!",
                  },
                ]}
              >
                <Select key="answerTypeDropDown" disabled={submitting}>
                  <Option key="option" value="option">
                    option(yes/no)
                  </Option>
                  <Option key="description" value="textArea">
                    Description(TextArea)
                  </Option>
                  <Option key="attachment" value="attachment">
                    File(s) upload
                  </Option>
                </Select>
              </FormItem>

              <Form.Item {...tailFormItemLayout}>
                <Space>
                  <Button type="primary" htmlType="submit" loading={submitting}>
                    Submit
                  </Button>
                  <Button
                    loading={submitting}
                    onClick={() => {
                      form.resetFields();
                    }}
                  >
                    Cancel
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};
