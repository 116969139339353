import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Typography, Table, Button, Spin, Descriptions,notification, Modal } from 'antd';
import {MasterCompanySuperAdminSer } from '../masterCompanySuperAdmin.service';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import moment from 'moment';
import AddMember from './AddMember.js';
import './memberInfo.css';

const MasterCompanySuperAdminService = new MasterCompanySuperAdminSer();

const ManageCompanies = () => {
   const [loading, setLoading] = useState(false);
   const [record, setRecord] = useState();
   const [refresh, setRefresh] = useState(false);
   const [modal, showModal] = useState(false);
   const [secondModal, showSecondModal] = useState(false);
   const [state,setState]=useState();
   const [zip, setZip] = useState();
   const [city, setCity] = useState();
   const [address1, setAddress1] = useState();
   const [address2, setAddress2] = useState(); 
   const [email, setEmail] = useState();
   const [phone, setPhone] = useState();
   const [touched, setTouched] = useState(false);
   const [initialCompanies, setInitialCompanies] = useState([]);
   const [loadMembers, setloadMembers] = useState(false);
   const [companyMembers, setCompanyMembers] = useState([]);
   const [selectedMember, setSelectedMember] = useState();
   const [memberModal, setMemberModal] = useState(false);
   const [companyId, setCompanyId] = useState();

   useEffect(()=>{
      (async () => {
         setLoading(true);
         let intialCompaniesList = await MasterCompanySuperAdminService.getInitialCompany();
         setInitialCompanies(intialCompaniesList.data);
         showModal(false);
         setLoading(false);
      })();
   },[refresh])

   const hideModal=()=>{
      showModal(false);
   }
   const hideSecondModal=()=>{
      showSecondModal(false);
   }

   const selectCompany=async (company)=>{
      setTouched(true);
      showModal(false);
      setRecord(company);
      setCompanyId(company._id);
      setState(company.state);
      setZip(company.zip);
      setCity(company.city);
      setAddress1(company.address1);
      setAddress2(company.address2);
      setloadMembers(true);
      let intialCompanyMembers = await MasterCompanySuperAdminService.getCompanyMembers(company._id);
      setCompanyMembers(intialCompanyMembers.data);
      setloadMembers(false);
   }

   const selectMember=(member)=>{
      setTouched(true);
      showSecondModal(false);
      setSelectedMember(member);
      setEmail(member.email);
      setPhone(member.phone);
   }

   const updateCompany=async()=>{
      if(!state || !zip|| !city || !address1){
         return false;
      }else{
         setLoading(true);
         let newCompany={};
         newCompany._id = record._id;
         newCompany.state = state;
         newCompany.zip = zip;
         newCompany.city = city;
         newCompany.address1 = address1;
         newCompany.address2 = address2;

         const response = await MasterCompanySuperAdminService.updateMemberCompany(newCompany);

         if (response.success) {
            notification.success({message: 'Success', description: 'Members updated successfully'});
            setRecord();
            setCompanyId();
            setState();
            setZip();
            setCity();
            setAddress1();
            setAddress2();          
         } else {
            notification.error({message: 'Error', description: response.error.message});            
         }
         showModal(false);
         setRefresh(true);
         setLoading(false);
      }
   }

   const updateMember=async()=>{
      if(!email || !phone){
         return false;
      }else{
         setloadMembers(true);
         let newMember={};
         newMember._id = selectedMember._id;
         newMember.email = email;
         newMember.phone = phone;
         showSecondModal(false);

         const response = await MasterCompanySuperAdminService.updateMemberForCompany(newMember);

         if (response.success) {
            notification.success({message: 'Success', description: 'Members updated successfully'});
            setSelectedMember();
            setEmail();
            setPhone(); 
            let intialCompanyMembers = await MasterCompanySuperAdminService.getCompanyMembers(companyId);
            setCompanyMembers(intialCompanyMembers.data);        
         } else {
            notification.error({message: 'Error', description: response.error.message});            
         }
         showSecondModal(false);
         setloadMembers(false);
      }
   }

   return (
      <>
      {loading &&  
         <div style={{textAlign:'center'}}>
           <br/>
           <Spin size='large'/>
         </div>
       }
       {!loading && (
      <div className="col-12 member-details-wrapper">
         <div className="col-12 member-info">
            <div className="col-12 member-box">
               <table className='member-table'>
                  <thead>
                     <tr>
                        <th></th>
                        <th>Company Name</th>
                        <th>Country</th>
                        <th>State</th>
                        <th>Date of Incorporation</th>
                        <th>Type of Business</th>
                     </tr>
                  </thead>
                  <tbody>
                     {initialCompanies && initialCompanies.map((company)=>(
                        <tr key={company._id}>
                           <td><input type="radio" name="member" onClick={()=>{selectCompany(company)}}/></td>
                           <td>{company.companyName}</td>
                           <td>{company.country}</td>
                           <td>{company.state}</td>
                           <td>{moment(company.dateOfIncorporation).format('LLL')}</td>
                           <td>{company.typeOfBusiness}</td>
                        </tr>
                     ))}
                  </tbody>
               </table>
               <div className='col-12 button-group'>
                  <Button size='large' className={`ant-btn-link ${record ? 'add-button':''}`} type='primary' 
                     loading={loading} onClick={()=>{showModal(true)}}>Edit Member Company</Button>
                  <Button size='large' className={`ant-btn-link invite-button`} type='primary' 
                     onClick={()=>{if(record) setMemberModal(true)}}>Add Company Representative</Button>                  
               </div>
            </div>
            {loadMembers && (<div style={{textAlign:'center'}}>
               <br/>
               <Spin size='large'/>
               </div>)}
            {!loadMembers && record &&(
               <div className="col-12 member-box  members-box">
               <table className='member-table'>
                  <thead>
                     <tr>
                        <th></th>
                        <th>Name</th>
                        <th>Email Address</th>
                        <th>Phone No</th>
                        <th>Country</th>
                        <th>Created On</th>
                     </tr>
                  </thead>
                  <tbody>
                     {companyMembers && companyMembers.map((member)=>(
                        <tr key={member._id}>
                           <td><input type="radio" name="person" onClick={()=>{selectMember(member)}}/></td>
                           <td>{member.firstName + ' ' + member.lastName}</td>
                           <td>{member.email}</td>
                           <td>{member.phone}</td>
                           <td>{member.country}</td>
                           <td>{moment(member.creationTS).format('LLL')}</td>
                        </tr>
                     ))}
                  </tbody>
               </table>
               <div className='col-12 button-group'>
                  <Button size='large' className={`ant-btn-link ${selectedMember ? 'add-button':''}`} type='primary' 
                     loading={loadMembers} onClick={()=>{showSecondModal(true)}}>Edit</Button>
                  {/* <Button size='large' className={`ant-btn-link invite-button`} type='primary' 
                     onClick={()=>{}}>Disable</Button> */}
               </div>
            </div>
            )}
            {record && (
            <Modal width={700} 
               visible={!!modal}
               onCancel={hideModal}
               footer={[
               <>
                  <Button key="close" onClick={hideModal}>
                     Back
                  </Button>
                  <Button key="submit" onClick={updateCompany}>
                     Update
                  </Button>
               </>
               ]}>
               <Spin spinning={false} size='large'>
                  <div className='modal-details'>
                     <div style={{backgroundColor:'#142A44',padding:'15px'}}>
                           <sub style={{color:'white',fontWeight:'bold',fontSize:'35px',fontFamily:'Arial, Helvetica, sans-serif'}}>Company Details</sub>
                     </div>
                     <br/>
                     <Row>
                        <Col span={24}>
                           <div className="email-tag">
                              <label style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>
                                 *Company State</label>
                              <input type="text" className="form-control" value={state} onChange={(e)=>{setState(e.target.value)}} valuestyle={{color:'#050505',fontSize:'20px',textAlign:'left'}}/>
                           </div>
                        </Col>
                     </Row>
                     <br/>
                     <Row>
                        <Col span={24}>
                           <div className="email-tag">
                              <label style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>
                                 *Zip Code</label>
                              <input type="text" className="form-control" value={zip} onChange={(e)=>{setZip(e.target.value)}} valuestyle={{color:'#050505',fontSize:'20px',textAlign:'left'}}/>
                           </div>
                        </Col>
                     </Row>
                     <br/>
                     <Row>
                        <Col span={24}>
                           <div className="email-tag">
                              <label style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>
                                 *Company City</label>
                              <input type="text" className="form-control" value={city} onChange={(e)=>{setCity(e.target.value)}} valuestyle={{color:'#050505',fontSize:'20px',textAlign:'left'}}/>
                           </div>
                        </Col>
                     </Row>
                     <br/>
                     <Row>
                        <Col span={24}>
                           <div className="email-tag">
                              <label style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>
                                 *Street Address 1</label>
                              <input type="text" className="form-control" value={address1} onChange={(e)=>{setAddress1(e.target.value)}} valuestyle={{color:'#050505',fontSize:'20px',textAlign:'left'}}/>
                           </div>
                        </Col>
                     </Row>
                     <br/>
                     <Row>
                        <Col span={24}>
                           <div className="email-tag">
                              <label style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>
                                 Street Address 2</label>
                              <input type="text" className="form-control" value={address2} onChange={(e)=>{setAddress2(e.target.value)}} valuestyle={{color:'#050505',fontSize:'20px',textAlign:'left'}}/>
                           </div>
                        </Col>
                     </Row>
                     <br/>
                  </div>
               </Spin> 
            </Modal>)}
            {selectedMember && (
            <Modal width={700} 
               visible={!!secondModal}
               onCancel={hideSecondModal}
               footer={[
               <>
                  <Button key="close" onClick={hideSecondModal}>
                     Back
                  </Button>
                  <Button key="submit" onClick={updateMember}>
                     Update
                  </Button>
               </>
               ]}>
               <Spin spinning={false} size='large'>
                  <div className='modal-details'>
                     <div style={{backgroundColor:'#142A44',padding:'15px'}}>
                           <sub style={{color:'white',fontWeight:'bold',fontSize:'35px',fontFamily:'Arial, Helvetica, sans-serif'}}>Member Details</sub>
                     </div>
                     <br/>
                     <Row>
                        <Col span={24}>
                           <div className="email-tag">
                              <label style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>
                                 Email Address</label>
                              <input type="text" className="form-control" value={email} onChange={(e)=>{setEmail(e.target.value)}} valuestyle={{color:'#050505',fontSize:'20px',textAlign:'left'}}/>
                           </div>
                        </Col>
                     </Row>
                     <br/>
                     <Row>
                        <Col span={24}>
                           <div className="phone-tag">
                              <label style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>
                                 Phone Number</label>
                              <br/>
                              {/* <input type="text"className="form-control" value={phone} onChange={(e)=>{setPhone(e.target.value)}} valuestyle={{color:'#050505',fontSize:'20px',textAlign:'left'}}/> */}
                              <PhoneInput country={"us"} value={phone} onChange={(val)=>{setPhone(val)}}/>
                           </div>
                        </Col>
                     </Row>
                     <br/>
                  </div>
               </Spin> 
            </Modal>)}
            {memberModal && (
               <Modal width={700} 
               visible={!!memberModal}
               onCancel={()=>{setMemberModal(false)}}>
               <AddMember loading={loading} setLoading={setLoading} memberModal={memberModal}
                  setMemberModal={setMemberModal} companyId={companyId} setRecord={setRecord}/>
            </Modal>)}
         </div>
      </div>)}
      </>
   )
}

export default ManageCompanies
