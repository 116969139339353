export const environment = {
  production: process.env.REACT_APP_PRODUCTION,
  apiKey: process.env.REACT_APP_APIKEY,
  captchaKey: process.env.REACT_APP_CAPTCHA_KEY,
  APIURL: process.env.REACT_APP_API_URL,
  headerTitle: process.env.REACT_APP_HEADER_TITLE,
  securityTokenRegistryAddress: process.env.REACT_APP_SECURITY_TOKEN_REGISTRY_ADDRESS,
  ipfsProjectId: process.env.REACT_APP_IPFS_PROJECT_ID,
  ipfsProjectSecret: process.env.REACT_APP_IPFS_SECRET,
  ipfsDomain: process.env.REACT_APP_IPFS_DOMAIN,
  allowedNetworks: JSON.parse(process.env.REACT_APP_ALLOWED_NETWORKS),
  loginCaptha: process.env.REACT_APP_LOGIN_CAPTCHA,
  acuantUrl: process.env.REACT_APP_ACUANT_URL,
  currencySign: process.env.REACT_APP_CURRENCY_SIGN,
  tokens:JSON.parse(process.env.REACT_APP_TOKENS)
};
