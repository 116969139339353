import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Typography, Table, Button, Spin, Descriptions,notification, Modal } from 'antd';
import { MasterCompanySuperAdminSer } from '../masterCompanySuperAdmin.service';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import moment from 'moment';
import './memberInfo.css';

const MasterCompanySuperAdminService = new MasterCompanySuperAdminSer();

const ManageIndividuals = () => {
   const [loading, setLoading] = useState(false);
   const [record, setRecord] = useState();
   const [refresh, setRefresh] = useState(false);
   const [modal, showModal] = useState(false);
   const [email, setEmail] = useState('');
   const [phone, setPhone] = useState();
   const [touched, setTouched] = useState(false);
   const [phonePrefix, setPhonePrefix] = useState();
   const [initialMembers, setInitialMembers] = useState([]);

      useEffect(()=>{
         (async () => {
            setLoading(true);
            let intialMembersList = await MasterCompanySuperAdminService.getInitialMembers();
            setInitialMembers(intialMembersList.data);
            showModal(false);
            setLoading(false);
         })();
      },[refresh])

   const hideModal=()=>{
      showModal(false);
   }

   const selectMember=(member)=>{
      setTouched(true);
      showModal(false);
      setEmail(member.email);
      setPhone(member.phone);
      setRecord(member);
   }

   const updateMember=async()=>{
      if(!email || !phone || !touched){
         return false;
      }else{
         setLoading(true);
         let newMember={};
         newMember._id = record._id;
         newMember.email = email;
         newMember.phone = phone;

         const response = await MasterCompanySuperAdminService.saveIntialMembers(newMember);

         if (response.success) {
            notification.success({message: 'Success', description: 'Members updated successfully'});
            setRecord();
            setEmail('');
            setPhone();          
         } else {
            notification.error({message: 'Error', description: response.error.message});            
         }
         showModal(false);
         setRefresh(true);
         setLoading(false);
      }
   }

   return (
      <>
      {loading &&  
         <div style={{textAlign:'center'}}>
           <br/>
           <Spin size='large'/>
         </div>
       }
      {!loading && (
      <div className="col-12 member-details-wrapper">
         <div className="col-12 member-info">
            <div className="col-12 member-box">
               <table className='member-table'>
                  <thead>
                     <tr>
                        <th></th>
                        <th>Name</th>
                        <th>Email Address</th>
                        <th>Initial Member</th>
                        <th>Department</th>
                        <th>Team Role</th>
                        <th>Affiliate</th>
                        <th>Created On</th>
                     </tr>
                  </thead>
                  <tbody>
                     {initialMembers && initialMembers.map((member)=>(
                        <tr key={member._id}>
                           <td><input type="radio" name="member" onClick={()=>{selectMember(member)}}/></td>
                           <td>{member.firstName+' '+member.lastName}</td>
                           <td>{member.email}</td>
                           <td>Yes</td>
                           <td>{member.department.name}</td>
                           <td>{member.roles[0]}</td>
                           <td>{member.isAffiliate ? member.isAffiliate == true ? "Yes": "No" : "No"}</td>
                           <td>{moment(member.creationTS).format('LLL')}</td>
                        </tr>
                     ))}
                  </tbody>
               </table>
               <div className='col-12 button-group'>
                  <Button size='large' className={`ant-btn-link ${record ? 'add-button':''}`} type='primary' 
                     loading={loading} onClick={()=>{showModal(true)}}>Edit</Button>
                  <button type="button" className="ant-btn ant-btn-link invite-button">
                     <a href='/master-company-super-admin/admin/team-managment/new'>Invite New Member</a>
                  </button>
                  <button type="button" className="ant-btn ant-btn-link back-button">
                     <span onClick={()=>{}}>Back</span>
                  </button>
               </div>
            </div>
            {record && (
            <Modal width={700} 
               visible={!!modal}
               onCancel={hideModal}
               footer={[
               <>
                  <Button key="close" onClick={hideModal}>
                     Back
                  </Button>
                  <Button key="submit" onClick={updateMember}>
                     Update
                  </Button>
               </>
               ]}>
               <Spin spinning={false} size='large'>
                  <div className='modal-details'>
                     <div style={{backgroundColor:'#142A44',padding:'15px'}}>
                           <sub style={{color:'white',fontWeight:'bold',fontSize:'35px',fontFamily:'Arial, Helvetica, sans-serif'}}>Member Details</sub>
                     </div>
                     <br/>
                     <Row>
                        <Col span={24}>
                           <div className="email-tag">
                              <label style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>
                                 Email Address</label>
                              <input type="text" className="form-control" value={email} onChange={(e)=>{setEmail(e.target.value)}} valuestyle={{color:'#050505',fontSize:'20px',textAlign:'left'}}/>
                           </div>
                        </Col>
                     </Row>
                     <br/>
                     <Row>
                        <Col span={24}>
                           <div className="phone-tag">
                              <label style={{color:'#666770',fontFamily:'Arial, Helvetica, sans-serif',textAlign:'left',fontSize:'15px'}}>
                                 Phone Number</label>
                              <br/>
                              {/* <input type="text"className="form-control" value={phone} onChange={(e)=>{setPhone(e.target.value)}} valuestyle={{color:'#050505',fontSize:'20px',textAlign:'left'}}/> */}
                              <PhoneInput id="123" country={"us"} value={phone} onChange={(val)=>{setPhone(val)}}/>
                           </div>
                        </Col>
                     </Row>
                     <br/>
                  </div>
               </Spin> 
            </Modal>)}
         </div>
      </div>)}
   </>
   )
}

export default ManageIndividuals;
