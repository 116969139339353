import React from "react";
import whyTokenize from "../../assets/images/banner/10-things-that-have-changed-since-last-10-years-in-real-estate-from-builders-perspective-840x480.jpg"

function Features() {
    return (
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col-xl-6 col-md-12">
                    <img class="tokenize_image" src={whyTokenize} />
                </div>
                <div class="col-xl-6 col-md-12 p-5">
                    <h1 class="display-6 fw-bold lh-1 mb-3 tokenization">WHY VEEP USA?</h1>
                    <p class="lead text-dark">We envision to provide a platform integrated with <br /> Blockchain technology for anyone to launch security tokens.
                    </p>
                    <ul className="features text-dark">
                        <li>Launch tokens oftype Equity, Bond, Real Estate, Fund</li>
                        <li>Investor Onboarding</li>
                        <li>Investor's KYC/AML and accrediation/qualification status</li>
                        <li>Transfer Restrictions - Peer to Peer Compliant Transfers, Inside and Outside the Exchange</li>
                        <li>Security Recovery</li>
                        <li>Voting</li>
                        <li>Dividend</li>
                        <li>Vesting</li>
                        <li>Documents distribution and e-signatures</li>
                        <li>CAP Table Management</li>
                    </ul>
                    <button type="button" class="btn btn-primary mt-4 btn-demo">EXPLORE FEATURES</button>
                </div>
            </div>
        </div>
    )
}

export default Features