import React, { useEffect, Fragment } from "react";
import Header from "../../containers/templateConfig/header";
import ContentWrapper from "./contentWrapper";

import Footer from "../footer";

/*Tokenize Place footer*/
//import Footer from "../Homepage/Footer";

import '../Homepage/Homepage.css';

const MainLayout = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  return (
    <Fragment>
      <Header />
      <ContentWrapper />
      <Footer />
    </Fragment>
  );
};

export default MainLayout;
